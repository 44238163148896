import TabButtons from '@/components/atoms/TabButtons';
import CustomTable from '@/components/molecules/CustomTable';
import SearchWithDropdownFilter from '@/components/molecules/SearchWithDropdownFilter';
import SidebarItemViewTemplate from '@/components/templates/SidebarItemViewTemplate';
import { MIN_CHARS_TO_DO_SEARCH } from '@/constants/numberVars';
import appRoutes from '@/constants/routes/app';
import { APPOINTMENT_TYPES_DISPLAY_LABELS, DESIGNATIONS_DISPLAY_LABELS } from '@/constants/stringVars';
import useTherapists from '@/hooks/therapists/useTherapists';
import { TTherapistApi, TTherapistFilterOption } from '@/types/Therapist.types';
import { displayCapitalizedUnderscoredString } from '@/utils/stringUtils';
import { HStack } from '@chakra-ui/react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import React, { useMemo, useState } from 'react';

const COLUMNS = [
  'First Name',
  'Last Name',
  'Practice Name',
  'Email',
  'Designation',
  'Scheduling Tools',
  'EHR',
  'Appointment Type',
];

const Therapists = () => {
  const navigate = useNavigate();

  const [filterOption, setFilterOption] = useState<TTherapistFilterOption | undefined>();
  const [filterText, setFilterText] = useState('');

  const [status, setStatus] = useState<'active' | 'inactive'>('active');

  const { isLoading, isRefetching, pagination, data: therapists } = useTherapists({ filterOption, filterText, status });

  const isSearching = useMemo(
    () => filterOption !== undefined && filterText.length >= MIN_CHARS_TO_DO_SEARCH && isRefetching,
    [filterOption, filterText, isRefetching],
  );

  const data = {
    nodes:
      therapists?.map((therapist) => ({
        ...therapist,
        id: therapist._id,
      })) ?? [],
  };

  const tableListItems = useMemo(
    () =>
      data.nodes.map((item: (typeof data.nodes)[number]) => {
        return {
          id: item._id,
          cells: [
            item.first_name,
            item.last_name,
            item.practice_name,
            item?.email,
            item.designation?.map((option) => displayCapitalizedUnderscoredString(option)).join(', '),
            item.schedule_tools?.map((option) => displayCapitalizedUnderscoredString(option)).join(', '),
            item.ehr_software,
            item.appointment_types?.map((option) => displayCapitalizedUnderscoredString(option)).join(', '),
          ],
        };
      }),
    [data.nodes],
  );

  const onTabClick = (status: 'active' | 'inactive') => {
    setStatus(status);
  };

  return (
    <SidebarItemViewTemplate
      title="Therapists"
      buttonLabel="Add Therapist"
      onButtonClick={() => {
        navigate({ to: appRoutes.therapistsCreate });
      }}
    >
      <HStack width={'100%'} marginTop={'20px'} justifyContent={'space-between'}>
        <TabButtons
          options={[
            {
              onClick: () => onTabClick('active'),
              labelValue: 'active',
              labelDisplay: 'Active',
            },
            {
              onClick: () => onTabClick('inactive'),
              labelValue: 'inactive',
              labelDisplay: 'Deactivated',
            },
          ]}
          selectedOptionValue={status}
        />
        <SearchWithDropdownFilter<TTherapistFilterOption | undefined>
          selectedFilterOption={filterOption}
          filterOptions={['Designation', 'Appointment Type']}
          filterOptionsSubOptions={[
            Object.values(DESIGNATIONS_DISPLAY_LABELS),
            Object.values(APPOINTMENT_TYPES_DISPLAY_LABELS),
          ]}
          filterText={filterText}
          setFilterOption={setFilterOption}
          setFilterText={setFilterText}
          isLoadingSearch={isSearching}
        />
      </HStack>
      <CustomTable<TTherapistApi[], string>
        noItemsMessage="No therapists found"
        columns={COLUMNS}
        isLoading={isLoading}
        isRefetching={isRefetching}
        isSearching={isSearching}
        tableListItems={tableListItems}
        pagination={pagination}
        dataWithId={data.nodes}
        fullWidth
        hasRowSelect
      />
    </SidebarItemViewTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_admin/therapists/')({
  component: Therapists,
});
