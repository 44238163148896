import COOKIE_KEYS from '@/constants/cookies';
import { api, TCustomAxiosPaginationResponse } from '..';
import Cookies from 'js-cookie';
import { TUser, TUserFilterOption } from '@/types/User.types';
import { getBeUserFieldNameFromFilterOption } from '@/utils/stringUtils';
import { ITEMS_PER_PAGE } from '@/theme/components/table';
import { MIN_CHARS_TO_DO_SEARCH } from '@/constants/numberVars';

const rootPath = '/v1/user';

const endpoints = {
  user: `${rootPath}`,
  activeUsers: `${rootPath}/active`,
  deactivatedUsers: `${rootPath}/deactivated`,
  activateOrgUser: `/v1/organization/users`,
  signIn: `${rootPath}/login`,
  forgotPassword: `${rootPath}/forgot-password`,
  setInitialPassword: `${rootPath}/set-password`,
  verifyAuthToken: `${rootPath}/verify-token`,
};

export const userGet = async (): Promise<TUser> => {
  const { data } = await api.get(endpoints.user);
  const user = data.data as TUser;

  return user;
};

export const getAllActivatedUsers = async (
  page: number,
  filterOption: TUserFilterOption,
  filterText: string,
): Promise<TCustomAxiosPaginationResponse<TUser[]>> => {
  const filterTypeBE = filterOption ? getBeUserFieldNameFromFilterOption(filterOption) : '';

  const params: any = {
    limit: ITEMS_PER_PAGE,
    page,
  };

  if (filterText && filterText?.length >= MIN_CHARS_TO_DO_SEARCH) {
    params[filterTypeBE] = filterText;
  }

  const { data } = await api.get(endpoints.activeUsers, {
    params,
  });

  return data;
};

export const getAllDeactivatedUsers = async (
  page: number,
  filterOption: TUserFilterOption,
  filterText: string,
): Promise<TCustomAxiosPaginationResponse<TUser[]>> => {
  const filterTypeBE = filterOption ? getBeUserFieldNameFromFilterOption(filterOption) : '';

  const params: any = {
    limit: ITEMS_PER_PAGE,
    page,
  };

  if (filterText && filterText?.length >= MIN_CHARS_TO_DO_SEARCH) {
    params[filterTypeBE] = filterText;
  }

  const { data } = await api.get(endpoints.deactivatedUsers, {
    params,
  });

  return data;
};

export const activateUserPost = async (userId: string) => {
  const data = await api.post(`${endpoints.activateOrgUser}/${userId}/reactivate`);
  return data;
};

export const deactivateUserPost = async (userId: string) => {
  const data = await api.delete(`${endpoints.user}/${userId}`);
  return data;
};

export const signInPost = async (email: string, password: string) => {
  const tokenData = await api.post(endpoints.signIn, {
    email,
    password,
  });

  const token = tokenData.data.data.access_token;
  const tokenType = tokenData.data.data.token_type;

  // First set token in cookies securely
  Cookies.set(COOKIE_KEYS.ACCESS_TOKEN, `${tokenType} ${token}`, {
    expires: +import.meta.env.VITE_TOKEN_EXPIRATION as number,
    secure: true,
    sameSite: 'strict',
  });
  // Add token to api headers
  api.defaults.headers.Authorization = `${tokenType} ${token}`;
};

export const forgotPasswordPost = async (email: string) => {
  await api.post(endpoints.forgotPassword, {
    email,
  });

  return 'ok';
};

export const verifyAuthToken = async (body: { token: string; email: string }): Promise<{ success: boolean }> => {
  const { data } = await api.post(endpoints.verifyAuthToken, body);
  return data.data;
};

export const setInitialUserPassword = async (body: {
  email: string;
  token: string;
  newPassword: string;
}): Promise<{ success: boolean }> => {
  try {
    const { data } = await api.post(endpoints.setInitialPassword, body);
    return data.data;
  } catch (e) {
    console.log('e', e);
  } finally {
    return { success: true };
  }
};
