import { CONTRACT_DOCUMENT_TYPES_DISPLAY_LABELS } from '@/constants/stringVars';
import { TContractDocument } from '@/types/Organization.types';
import { Center, HStack, Text, VStack } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import SvgIcon from '@/components/atoms/SvgIcon';
import { svgIconPaths } from '@/assets/svg/icons';
import colors from '@/theme/colors';
import { formatDate } from 'date-fns';
import CustomTable from '@/components/molecules/CustomTable';
import ConfirmationModal from '@/components/molecules/ConfirmationModal';
import useContractDocuments from '@/hooks/contracts/useGetContractDocuments';
import LoadingLogo from '@/components/atoms/LoadingLogo';
import CustomButton from '@/components/atoms/CustomButton';
import ContractDocumentUploadModal from './ContractDocumentUploadModal';
import useDeleteContractDocument from '@/hooks/contracts/useDeleteContractDocument';

const COLUMNS = ['NAME', 'TYPE', 'UPLOADED AT', 'DELETE'] as const;

interface Props {
  contractId: string;
}

const ContractDocumentsTable = ({ contractId }: Props) => {
  const { data: documents, isLoading, refetch: refetchContractDocuments } = useContractDocuments(contractId);

  const {
    mutate: deleteContract,
    error: deleteContractError,
    isPending: isDeleteContractPending,
  } = useDeleteContractDocument(() => {
    setDocumentToDelete(undefined);
    refetchContractDocuments();
  });

  const [documentToDelete, setDocumentToDelete] = useState<TContractDocument>();

  const [isAddDocumentModalVisible, setIsAddDocumentModalVisible] = useState(false);

  const tableListItems = useMemo(
    () =>
      documents
        ? documents.map((document: TContractDocument) => {
            return {
              id: document.key,
              cells: [
                document.name,
                CONTRACT_DOCUMENT_TYPES_DISPLAY_LABELS[document.type],
                formatDate(new Date(document.uploadedAt), 'MM/dd/yyyy'),
                <SvgIcon
                  iconPath={svgIconPaths.trashCan}
                  color={colors.extra.red}
                  onClick={() => setDocumentToDelete(document)}
                />,
              ],
            };
          })
        : [],
    [documents],
  );

  const dataWithId = useMemo(
    () => (documents ? documents.map((document) => ({ id: document.key, ...document })) : []),
    [documents],
  );

  if (isLoading) {
    return (
      <Center width={'100%'} height={'100px'}>
        <LoadingLogo />
      </Center>
    );
  }

  return (
    <VStack width={'100%'}>
      <HStack width={'90%'} justifyContent={'space-between'}>
        <Text variant="loraSmallTitle">Documents</Text>
        <CustomButton
          label="Add a Document"
          onClick={() => {
            setIsAddDocumentModalVisible(true);
          }}
        />
      </HStack>

      <CustomTable<TContractDocument[], string>
        dataWithId={dataWithId}
        tableListItems={tableListItems}
        noItemsMessage="No Documents for this Contract"
        columns={COLUMNS}
        marginBottom="20px"
        customColumnGridPercentages={[30, 25, 25, 20]}
        navigateOnRowSelect={false}
        isSearching={false}
        isLoading={false}
        isRefetching={false}
      />

      <ConfirmationModal
        isOpen={!!documentToDelete}
        onCancel={() => setDocumentToDelete(undefined)}
        confirmButtonText="Delete"
        confirmButtonColor="extra.red"
        onConfirm={() => deleteContract({ contractId, documentKey: documentToDelete!.key })}
        description={`Deleting this document is not reversible.`}
        title={`Are you sure you want to delete document ${documentToDelete?.name}?`}
        error={deleteContractError?.errors[0].message}
        confirmLoading={isDeleteContractPending}
      />

      <ContractDocumentUploadModal
        contractId={contractId}
        isOpen={isAddDocumentModalVisible}
        onClose={() => setIsAddDocumentModalVisible(false)}
        onUploadSuccessful={refetchContractDocuments}
      />
    </VStack>
  );
};

export default ContractDocumentsTable;
