import { svgIconPaths } from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import ConfirmationModal from '@/components/molecules/ConfirmationModal';
import CustomTable from '@/components/molecules/CustomTable';
import ModalContainer from '@/components/molecules/ModalContainer/ModalContainer';
import queryKeys from '@/constants/queryKeys';
import { useAssignOrgToMemberCare, useMemberCareUnassignedOrganizations } from '@/hooks/member-care/memberCare';
import colors from '@/theme/colors';
import { TOrganization } from '@/types/Organization.types';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';

interface Props {
  isOpen: boolean;
  setIsOpen: (_: boolean) => void;
  memberCareId: string;
  memberCareName: string;
}

const COLUMNS: string[] = ['Name', 'Website', 'Assign'];
type TSortingColumn = (typeof COLUMNS)[number];

const MemberCareAssignOrgModal = ({ isOpen, setIsOpen, memberCareId, memberCareName }: Props) => {
  const queryClient = useQueryClient();

  const [orgToAssignTo, setOrgToAssignTo] = useState<TOrganization>();
  const [isOrgAssignConfirmationOpened, setIsOrgAssignConfirmationOpened] = useState(false);

  const {
    data: unassignedOrgs,
    isLoading: isLoadingUnassigned,
    isRefetching: isRefetchingUnassigned,
    pagination,
  } = useMemberCareUnassignedOrganizations(memberCareId);

  const { mutate, isPending } = useAssignOrgToMemberCare(() => {
    setIsOrgAssignConfirmationOpened(false);

    queryClient.invalidateQueries({
      queryKey: [queryKeys.memberCare.memberCareSpecialistById, memberCareId],
    });

    queryClient.invalidateQueries({
      queryKey: [queryKeys.memberCare.unassignedOrgs],
    });
  });

  const assignOrgToMC = () => {
    if (orgToAssignTo) {
      mutate({ memberCareId, orgId: orgToAssignTo._id });
    }
  };

  const organizationsTableNodes = unassignedOrgs
    ? unassignedOrgs.map((organization) => ({
        ...organization,
        id: organization._id,
      }))
    : [];

  const unassignedOrgsTableItems = useMemo(
    () =>
      organizationsTableNodes.map((item: (typeof organizationsTableNodes)[number]) => {
        return {
          id: item._id,
          cells: [
            item.name,
            item.website ?? '',
            <CustomButton
              isTransparent
              label={'Assign'}
              hoverBackgroundColor="primary.500"
              labelColor={colors.text.mediumBlue}
              labelHoverColor={colors.extra.white}
              iconBeforeLabelSvgPath={svgIconPaths.userActivate}
              onClick={() => {
                setIsOrgAssignConfirmationOpened(true);
                setOrgToAssignTo(item);
              }}
              width={'110px'}
              height={'35px'}
              noShadow
            />,
          ],
        };
      }),
    [organizationsTableNodes],
  );

  return (
    <ModalContainer
      width="80vw"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Assign Organization(s) to Member Support Specialist"
    >
      <VStack width={'100%'} alignItems={'center'}>
        <VStack width={'100%'} alignItems={'center'}>
          <Text variant={'urbanistMedium'} textAlign={'left'}>
            Select an organization to assign {memberCareName} to:
          </Text>
          <CustomTable<TOrganization[], TSortingColumn>
            isLoading={isLoadingUnassigned}
            dataWithId={organizationsTableNodes}
            isRefetching={isRefetchingUnassigned}
            pagination={pagination}
            tableListItems={unassignedOrgsTableItems}
            isSearching={false}
            noItemsMessage="No organizations left to assign"
            columns={COLUMNS}
          />
        </VStack>
        <HStack justifyContent={'center'} width={'100%'}>
          <CustomButton
            borderHoverColor="transparent"
            label="Cancel"
            onClick={() => setIsOpen(false)}
            labelStyle={{ fontSize: '16px' }}
            height={'54px'}
            width={'200px'}
          />
        </HStack>
      </VStack>
      <ConfirmationModal
        isOpen={isOrgAssignConfirmationOpened}
        onCancel={() => {
          setIsOrgAssignConfirmationOpened(false);
          setOrgToAssignTo(undefined);
        }}
        title="Confirm Assignment"
        confirmButtonText="Assign"
        description={`This will assign ${memberCareName} to ${orgToAssignTo?.name}.`}
        onConfirm={assignOrgToMC}
        confirmLoading={isPending}
      />
    </ModalContainer>
  );
};

export default MemberCareAssignOrgModal;
