import CheckBoxTile from '@/components/atoms/CheckBoxTile';
import {
  CONTRACT_SERVICE_WITH_SUB_SERVICES,
  ORGANIZATION_CONTRACT_SERVICE_COST_BASIS_UNITS_DISPLAY_LABELS,
  ORGANIZATION_CONTRACT_SERVICE_TYPES_DISPLAY_LABELS,
  ORGANIZATION_CONTRACT_SERVICES,
  PEER_SUPPORT_SERVICE_START_INDEX,
} from '@/constants/stringVars';
import colors from '@/theme/colors';
import { TOrganizationContractServiceSubService, TOrganizationContractServiceType } from '@/types/Organization.types';
import { HStack, Input, Text, VStack } from '@chakra-ui/react';
import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import ContractServiceSubServicesCheckboxes from './ContractServiceSubServicesCheckboxes';

const CHECKBOX_WIDTH = 630;
const CHECKBOX_SUB_PADDING = 20;

interface Props {
  checkedServices: TOrganizationContractServiceType[];
  setCheckedServices: (newValue: TOrganizationContractServiceType[]) => void;
  checkedSubServices: TOrganizationContractServiceSubService[];
  setCheckedSubServices: (newValue: TOrganizationContractServiceSubService[]) => void;
  servicesAmounts: Record<string, string>;
  setServicesAmounts: (newValue: Record<string, string>) => void;
  shouldDisplayPeerSupportCheckboxes: boolean;
  setShouldDisplayPeerSupportCheckboxes: Dispatch<SetStateAction<boolean>>;
  usedServicesAmounts?: Record<string, number>;
  setSomeNewTotalLessThanCurrentlyUsed?: (_: boolean) => void;
}

const ContractServicesCheckboxes = ({
  checkedServices,
  setCheckedServices,
  checkedSubServices,
  setCheckedSubServices,
  servicesAmounts,
  setServicesAmounts,
  shouldDisplayPeerSupportCheckboxes,
  setShouldDisplayPeerSupportCheckboxes,
  usedServicesAmounts,
  setSomeNewTotalLessThanCurrentlyUsed,
}: Props) => {
  const updateCheckedServices = useCallback(
    (newlyCheckedService: TOrganizationContractServiceType) => {
      let newCheckedServices = [...checkedServices];

      if (newCheckedServices.includes(newlyCheckedService)) {
        newCheckedServices = newCheckedServices.filter(
          (previouslyCheckedConcern) => previouslyCheckedConcern !== newlyCheckedService,
        );
      } else {
        newCheckedServices = [...newCheckedServices, newlyCheckedService];
      }

      setCheckedServices(newCheckedServices);
    },
    [checkedServices],
  );

  useEffect(() => {
    const servicesAmountsKeys = Object.keys(servicesAmounts);
    if (checkedServices.length <= servicesAmountsKeys.length) {
      servicesAmountsKeys.forEach((servicesAmountsKey) => {
        if (!checkedServices.includes(servicesAmountsKey as TOrganizationContractServiceType)) {
          const newServicesAmounts = { ...servicesAmounts };
          delete newServicesAmounts[servicesAmountsKey];
          setServicesAmounts(newServicesAmounts);
        }
      });
    }
  }, [checkedServices, servicesAmounts]);

  const updateServicesAmounts = useCallback(
    (service: string, amount: string) => {
      let newServicesAmounts = { ...servicesAmounts };

      newServicesAmounts[service] = amount;

      setServicesAmounts(newServicesAmounts);
    },
    [servicesAmounts],
  );

  useEffect(() => {
    let shouldSetSomeNewTotalLessThanCurrentlyUsed = false;

    for (const service of ORGANIZATION_CONTRACT_SERVICES) {
      const newTotalLessThanCurrentlyUsed =
        parseInt(servicesAmounts[service.type]) < (usedServicesAmounts?.[service.type] ?? 0);

      if (newTotalLessThanCurrentlyUsed) {
        shouldSetSomeNewTotalLessThanCurrentlyUsed = true;
        break;
      }
    }

    setSomeNewTotalLessThanCurrentlyUsed?.(shouldSetSomeNewTotalLessThanCurrentlyUsed);
  }, [servicesAmounts]);

  return (
    <VStack marginTop={'40px'} gap={'20px'} marginX={0}>
      <Text variant={'urbanistSemiBold'} fontWeight={500} color={'text.mediumGray'} marginBottom={'10px'}>
        Choose services that are included in the contract.
      </Text>
      {ORGANIZATION_CONTRACT_SERVICES.map((service, i) => {
        const isChecked = checkedServices.includes(service.type);

        const hasSubServices = isChecked && service.type === CONTRACT_SERVICE_WITH_SUB_SERVICES;
        const hasAmountInput = isChecked && !hasSubServices;

        const isPeerSupportRelated = service.type.includes('PEER_SUPPORT');

        const isPeerSupportSubCheckbox = isPeerSupportRelated && i >= PEER_SUPPORT_SERVICE_START_INDEX;

        const newTotalLessThanCurrentlyUsed =
          parseInt(servicesAmounts[service.type]) < (usedServicesAmounts?.[service.type] ?? 0);

        return (
          <VStack key={service.type}>
            {isPeerSupportRelated && i === PEER_SUPPORT_SERVICE_START_INDEX && (
              <VStack paddingBottom={'20px'} gap={'20px'}>
                <CheckBoxTile
                  width={`${CHECKBOX_WIDTH}px`}
                  maxTextWidth="550px"
                  fontSize="18px"
                  isChecked={shouldDisplayPeerSupportCheckboxes}
                  checkboxOption={{ label: 'Peer Support' }}
                  onClick={() => setShouldDisplayPeerSupportCheckboxes((prev) => !prev)}
                />
                {shouldDisplayPeerSupportCheckboxes && (
                  <Text variant={'urbanistMediumRegular'} fontSize={'16px'} color={'text.mediumGray'}>
                    If any additional Peer Support related services are included selected them below.
                  </Text>
                )}
              </VStack>
            )}
            <VStack display={isPeerSupportSubCheckbox && !shouldDisplayPeerSupportCheckboxes ? 'none' : 'flex'}>
              <CheckBoxTile
                width={isPeerSupportSubCheckbox ? `${CHECKBOX_WIDTH - 2 * CHECKBOX_SUB_PADDING}px` : '630px'}
                maxTextWidth="550px"
                fontSize="18px"
                isChecked={isChecked}
                checkboxOption={{ label: ORGANIZATION_CONTRACT_SERVICE_TYPES_DISPLAY_LABELS[service.type] }}
                onClick={() => updateCheckedServices(service.type)}
              />
              {hasSubServices && (
                <ContractServiceSubServicesCheckboxes
                  checkedSubServices={checkedSubServices}
                  setCheckedSubServices={setCheckedSubServices}
                />
              )}

              {hasAmountInput && (
                <HStack
                  justifyContent={'space-between'}
                  width={'100%'}
                  marginTop={'10px'}
                  paddingLeft={'20px'}
                  align={'start'}
                >
                  <VStack align={'start'} marginTop={'5px'}>
                    <Text variant={'urbanistExtraBold'} fontSize={'12px'} color={'text.mediumGray'}>
                      COST UNIT BASIS
                    </Text>
                    <Text variant={'urbanistBold'} fontSize={'16px'}>
                      {ORGANIZATION_CONTRACT_SERVICE_COST_BASIS_UNITS_DISPLAY_LABELS[service.costBasisUnit]}
                    </Text>
                  </VStack>
                  <VStack>
                    <Input
                      className="custom-input"
                      value={servicesAmounts[service.type]}
                      onChange={(event) => updateServicesAmounts(service.type, event.target.value)}
                      placeholder="Initial Amount"
                      _placeholder={{ color: colors.text.mediumGray }}
                      data-state={!servicesAmounts[service.type] || newTotalLessThanCurrentlyUsed ? 'invalid' : 'valid'}
                      required
                    />
                    {newTotalLessThanCurrentlyUsed ? (
                      <Text variant={'error'} width={'100%'} align={'start'} paddingLeft={'12px'} maxWidth={'224px'}>
                        {usedServicesAmounts?.[service.type]
                          ? `Amount must be ${usedServicesAmounts?.[service.type]} or more (already Used Amount).`
                          : 'Amount must be greater than 0.'}
                      </Text>
                    ) : null}
                    {!servicesAmounts[service.type] && <Text variant={'error'}>Amount is required.</Text>}
                  </VStack>
                </HStack>
              )}
            </VStack>
          </VStack>
        );
      })}
    </VStack>
  );
};

export default ContractServicesCheckboxes;
