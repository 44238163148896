import iconsPng from '@/assets/img/png/icons';
import CustomButton from '@/components/atoms/CustomButton';
import GoBackLeftArrow from '@/components/atoms/GoBackLeftArrow';
import TopRightBackgroundCircle from '@/components/atoms/TopRightBackgroundCircle';
import AppPageTemplate from '@/components/templates/AppPageTemplate';
import { FORM_FIELD_WIDTH } from '@/constants/dimensions';
import { MAX_CHARS_IN_PHONE, MIN_CHARS_IN_PHONE } from '@/constants/numberVars';
import { CALENDLY_LINK_PLACEHOLDER } from '@/constants/stringVars';
import { useUpdateMemberCareContactDetails } from '@/hooks/member-care/memberCare';
import appMessages from '@/messages';
import { useAppStore } from '@/store/useAppStore';
import colors from '@/theme/colors';
import { TMemberCareContactDetailsWithoutEmail } from '@/types/MemberCare.types';
import { Image, Input, InputGroup, InputRightElement, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { createFileRoute, useRouter } from '@tanstack/react-router';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

const schema = yupResolver(
  yup.object().shape({
    email: yup.string().nullable(),
    firstName: yup.string().required('First name is required.'),
    lastName: yup.string().required('Last name is required.'),
    phone: yup
      .string()
      .required('Phone is required.')
      .min(MIN_CHARS_IN_PHONE, 'Minimum 8 characters are required in the Phone')
      .max(MAX_CHARS_IN_PHONE, 'Phone too long'),
    calendly_link: yup
      .string()
      .required('Calendly Link is required')
      .url('Invalid URL format')
      .max(100, 'Maximum 100 characters allowed in the Calendly Link')
      .test('calendly', 'Must be a valid Calendly Link', (val) => val?.toLowerCase().includes('calendly.com/')),
  }),
);

const ProfileContactDetails = () => {
  const router = useRouter();

  const { memberCare } = useAppStore();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      email: memberCare?.email ?? '',
      firstName: memberCare?.user_metadata?.firstName ?? '',
      lastName: memberCare?.user_metadata?.lastName ?? '',
      phone: memberCare?.user_metadata?.phone ?? '',
      calendly_link: (memberCare?.user_metadata?.calendly_link || memberCare?.calendly_link) ?? '',
    },
    resolver: schema,
  });

  const { handleSubmit, getValues, register, formState, watch } = methods;

  const firstNameInput = watch('firstName');
  const lastNameInput = watch('lastName');
  const phoneInput = watch('phone');
  const calendlyLink = watch('calendly_link');

  const changesMade = useMemo(() => {
    return (
      firstNameInput !== memberCare?.user_metadata?.firstName ||
      lastNameInput !== memberCare?.user_metadata.lastName ||
      phoneInput !== memberCare?.user_metadata.phone ||
      calendlyLink !== memberCare?.user_metadata.calendly_link
    );
  }, [firstNameInput, lastNameInput, phoneInput, calendlyLink]);

  const { errors, isValid, touchedFields } = formState;

  const submitDisabled = !isValid || !changesMade;

  const { mutate: updateMemberCareContactDetails, isPending } = useUpdateMemberCareContactDetails(() =>
    router.history.back(),
  );

  const onSubmit = () => {
    const memberCareContactDetails: TMemberCareContactDetailsWithoutEmail & { email?: string | null } = getValues();
    delete memberCareContactDetails.email;

    updateMemberCareContactDetails(memberCareContactDetails);
  };

  return (
    <AppPageTemplate>
      <TopRightBackgroundCircle />
      <GoBackLeftArrow />
      <Text variant={'loraSmallTitle'}>Edit Profile Details</Text>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack marginTop={'40px'} gap={'20px'} maxWidth={FORM_FIELD_WIDTH}>
            <VStack
              gap={0}
              backgroundColor={'white'}
              borderRadius={'8px'}
              paddingX={'16px'}
              cursor={'not-allowed'}
              minWidth={FORM_FIELD_WIDTH}
              border={`1px solid ${colors.background.cloudGray}`}
            >
              <Text
                color={'text.mediumGray'}
                fontWeight={800}
                lineHeight={'12px'}
                fontSize={'10px'}
                paddingTop={'12px'}
                width={'100%'}
                textAlign={'start'}
              >
                {appMessages.yourEmailAddress.toUpperCase()}
              </Text>
              <InputGroup>
                <Input
                  {...register('email', { required: true })}
                  disabled={true} // disabling this always; because we are only coming to this page with an existing email
                  _disabled={{ opacity: 1, cursor: 'not-allowed' }}
                  maxHeight={'22px'}
                  lineHeight={'18px'}
                  borderWidth={'0'}
                  fontSize={'14px'}
                  padding={'0px'}
                  marginBottom={'8px'}
                  fontWeight={600}
                  color={'text.mediumGray'}
                  data-state={touchedFields.email ? (errors.email?.message ? 'invalid' : 'valid') : ''}
                />
                <InputRightElement width="2rem" height={'100%'} marginTop={'-12px'}>
                  <Image alt="lock" src={iconsPng.lock} width={25} height={25} />
                </InputRightElement>
              </InputGroup>
            </VStack>
            <Input
              {...register('firstName', { required: true })}
              className="custom-input"
              placeholder={'Your First Name'}
              fontSize={'16px'}
              _placeholder={{
                fontWeight: '600',
                color: 'background.blueGray',
              }}
              data-state={touchedFields.firstName ? (errors.firstName?.message ? 'invalid' : 'valid') : ''}
            />
            <Input
              {...register('lastName', { required: true })}
              className="custom-input"
              placeholder={'Your Last Name'}
              fontSize={'16px'}
              _placeholder={{
                fontWeight: '600',
                color: 'background.blueGray',
              }}
              data-state={touchedFields.lastName ? (errors.lastName?.message ? 'invalid' : 'valid') : ''}
            />
            <Input
              {...register('phone', { required: true })}
              className="custom-input"
              placeholder={'Your Phone'}
              fontSize={'16px'}
              _placeholder={{
                fontWeight: '600',
                color: 'background.blueGray',
              }}
              data-state={touchedFields.phone ? (errors.phone?.message ? 'invalid' : 'valid') : ''}
            />
            <Input
              {...register('calendly_link', { required: true })}
              className="custom-input"
              placeholder={CALENDLY_LINK_PLACEHOLDER}
              fontSize={'16px'}
              _placeholder={{
                fontWeight: '600',
                color: 'background.blueGray',
              }}
              data-state={touchedFields.calendly_link ? (errors.calendly_link?.message ? 'invalid' : 'valid') : ''}
            />

            <Text color={'extra.red'} marginBottom="10px" fontWeight={600}>
              {errors.email?.message ||
                errors.phone?.message ||
                errors.firstName?.message ||
                errors.lastName?.message ||
                errors.calendly_link?.message}
            </Text>

            <CustomButton
              backgroundColor="secondary.500"
              label="Update Profile"
              width={FORM_FIELD_WIDTH}
              disabled={submitDisabled}
              isLoading={isPending}
              buttonType={submitDisabled ? 'button' : 'submit'}
            />
          </VStack>
        </form>
      </FormProvider>
    </AppPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_member-care/member-care/profile/contact/')({
  component: ProfileContactDetails,
});
