import { svgIconPaths, svgIcons } from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import TabButtons, { TTabButtonOption } from '@/components/atoms/TabButtons';
import ModalContainer from '@/components/molecules/ModalContainer/ModalContainer';
import WellnessStateCircle from '@/components/molecules/WellnessStateCircle';
import AppPageTemplate from '@/components/templates/AppPageTemplate';
import LoadingPage from '@/components/templates/LoadingPage';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import { MEMBER_PROFILE_SECTIONS } from '@/constants/stringVars';
import { useMemberCareMemberProfile, useMemberCareMemberTimeline } from '@/hooks/member-care/memberCare';
import MemberCareMemberNotes from '@/modules/memberCare/MemberCareMemberNotes';
import MemberCareMemberObservations from '@/modules/memberCare/MemberCareMemberObservations';
import MemberCareMemberWellnessTimeline from '@/modules/memberCare/MemberCareMemberWellnessTimeline';
import colors from '@/theme/colors';
import { TMemberProfileSection } from '@/types/MemberCare.types';
import { getAgeFromBirthDay } from '@/utils/stringUtils';
import { Center, HStack, Image, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';

const IMAGE_SIZE_PX = '80px';

const MemberProfileView = () => {
  const { memberCareMemberId } = Route.useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedProfileSection, setSelectedProfileSection] = useState<TMemberProfileSection>('member');

  const { data: member, isLoading } = useMemberCareMemberProfile(memberCareMemberId);

  const {
    data: memberTimelineItems,
    isLoading: isLoadingTimeline,
    refetch: refetchTimelineItems,
  } = useMemberCareMemberTimeline(memberCareMemberId);

  const memberProfileSectionTabs: TTabButtonOption[] = MEMBER_PROFILE_SECTIONS.map((section) => ({
    labelDisplay: section.display,
    labelValue: section.value,
    onClick: () => setSelectedProfileSection(section.value),
  }));

  if (isLoading || isLoadingTimeline || !member || !memberTimelineItems) return <LoadingPage />;

  const { ...wellnessData } = member?.healthDomainScores;
  delete wellnessData.totalScore;

  return (
    <AppPageTemplate>
      <VStack width={MAX_CONTAINER_WIDTH} paddingBottom={'100px'}>
        {/* Actual page content */}
        <VStack width={'100%'} marginBottom={'50px'}>
          <HStack justify={'space-between'} width={'100%'} marginBottom={'50px'}>
            {/* User photo and info */}
            <HStack gap={'20px'}>
              <Image
                src={member.profilePicture || svgIcons.genericUser}
                width={IMAGE_SIZE_PX}
                height={IMAGE_SIZE_PX}
                alt="member-profile-photo"
                borderRadius={'50%'}
                objectFit={'cover'}
              />
              <VStack gap={'2px'} alignItems={'flex-start'}>
                <Text variant="loraSmallTitle" fontWeight={'500'}>
                  {member.firstName} {member.lastName}
                </Text>
                <Text variant={'urbanistSemiBold'} color={'text.mediumGray'}>
                  {member.orgName || member.organizationName}
                </Text>
                <Text variant={'urbanistSemiBold'} color={'text.mediumGray'}>
                  {member.dateOfBirth &&
                    getAgeFromBirthDay(member.dateOfBirth) > 0 &&
                    `${getAgeFromBirthDay(member.dateOfBirth)} years old`}{' '}
                  {member.sex}
                </Text>
              </VStack>
            </HStack>

            {/* Chat and Phone buttons */}
            <HStack gap={8}>
              {/* <CustomButton
                onClick={() => {}} // TODO: Example nav  ->  navigate({ to: '/something/$somethingId', params: { somethingId: 'id-of-something' } })
                isTransparent
                label="Chat"
                labelColor={colors.text.darkBlue}
                iconBeforeLabelSvgPath={svgIconPaths.message}
                iconBeforeLabelSvgColor={colors.text.darkBlue}
                hoverBackgroundColor="extra.white"
                disabled
              /> */}
              <CustomButton
                backgroundColor="primary.500"
                onClick={onOpen}
                label="Phone"
                labelColor={colors.extra.white}
                iconBeforeLabelSvgPath={svgIconPaths.phone}
                iconBeforeLabelSvgColor={colors.extra.white}
              />
            </HStack>
          </HStack>

          <VStack marginY={'30px'}>
            <TabButtons options={memberProfileSectionTabs} selectedOptionValue={selectedProfileSection} />
          </VStack>

          <VStack display={selectedProfileSection === 'member' ? 'flex' : 'none'}>
            <Text variant="loraSmallTitle" color="text.mediumGray" fontWeight={'500'}>
              {member.firstName}'s Wellness Report
            </Text>
            <WellnessStateCircle
              wellnessData={wellnessData}
              userAvatar={member.profilePicture}
              averageScore={member.averageScore}
            />
            <MemberCareMemberWellnessTimeline firstName={member.firstName} memberTimelineItems={memberTimelineItems} />
          </VStack>

          <HStack
            marginTop={'40px'}
            gap={'60px'}
            display={selectedProfileSection === 'notes' ? 'flex' : 'none'}
            alignItems={'start'}
          >
            <MemberCareMemberNotes
              memberId={memberCareMemberId}
              memberFirstName={member.firstName}
              onNotesUpdate={refetchTimelineItems}
            />
            <MemberCareMemberObservations memberId={memberCareMemberId} onObservationsUpdate={refetchTimelineItems} />
          </HStack>
        </VStack>

        <ModalContainer isOpen={isOpen} onClose={onClose}>
          <Center marginBottom={'20px'}>
            <Image
              src={member.profilePicture || svgIcons.genericUser}
              width={'150px'}
              height={'150px'}
              alt="member-profile-photo"
              borderRadius={'50%'}
              objectFit={'cover'}
            />
          </Center>
          <VStack marginBottom={'30px'}>
            {member.phone ? (
              <VStack gap={0} marginY={'10px'}>
                <Text variant={'loraSmallTitle'} color={'primary.blueGray'}>
                  Phone Number:{' '}
                  <a style={{ color: colors.primary[500], fontWeight: 500 }} href={`tel:member.phone`}>
                    {member.phone}
                  </a>
                </Text>
                <Text variant={'finePrint'}>Click the number to call</Text>
              </VStack>
            ) : (
              <Text variant={'loraSmallTitle'}>This Member hasn't entered their Phone Number.</Text>
            )}

            <CustomButton
              style={{ margin: '10px 0' }}
              width={'295px'}
              backgroundColor="primary.500"
              onClick={onClose}
              label="Close"
              labelColor={colors.extra.white}
            />
          </VStack>
        </ModalContainer>
      </VStack>
    </AppPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_member-care/member-care/members/$memberCareMemberId')({
  component: MemberProfileView,
});
