import { MAX_CHARS_IN_BIO, MIN_CHARS_IN_BIO } from '@/constants/numberVars';
import { APPOINTMENT_TYPES, DESIGNATIONS, SCHEDULE_TOOLS } from '@/types/Therapist.types';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .required('First Name is required.')
    .min(2, 'First name needs to be at least 2 characters long.'),
  last_name: yup
    .string()
    .trim()
    .required('Last Name is required.')
    .min(2, 'Last name needs to be at least 2 characters long.'),
  email: yup
    .string()
    .email('Invalid email address')
    .notRequired()
    .trim()
    .min(2, 'Email needs to be at least 2 characters long.'),
  practice_name: yup.string().notRequired().trim().min(2, 'Practice name needs to be at least 2 characters long.'),
  designation: yup.array().of(yup.mixed().oneOf(DESIGNATIONS)).required('Designations are required.'),
  bio: yup
    .string()
    .required('Bio is required')
    .min(MIN_CHARS_IN_BIO, `Minimum ${MIN_CHARS_IN_BIO} characters are required in the Bio`)
    .max(MAX_CHARS_IN_BIO, `Maximum ${MAX_CHARS_IN_BIO} characters allowed in the Bio`),
  schedule_tools: yup.array().of(yup.mixed().oneOf(SCHEDULE_TOOLS)).required('Schedule tools are required.'),
  ehr_software: yup.string().notRequired().trim().min(2, 'EHR software name needs to be at least 2 characters long.'),
  sync_schedule_interest: yup.boolean().required('Sync schedule interest is required.'),
  appointment_types: yup.array().of(yup.mixed().oneOf(APPOINTMENT_TYPES)).required('Appointment types are required.'),
  booking_link: yup
    .string()
    .required('Booking link is required')
    .url('Invalid URL format')
    .max(100, 'Maximum 100 characters allowed in the booking link'),
  custom_designation: yup.string().notRequired().trim(),
  custom_schedule_tool: yup.string().notRequired().trim(),
  custom_appointment_type: yup.string().notRequired().trim(),
});

export { validationSchema as therapistValidationSchema };
