import Dropdown from '@/components/atoms/Dropdown';
import { Button, HStack, Input, InputGroup, InputRightElement, Spinner, VStack } from '@chakra-ui/react';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { CloseIcon } from '@chakra-ui/icons';

interface Props<TFilterOption> {
  filterOptions: readonly string[];
  filterOptionsSubOptions?: readonly (null | string[])[];
  selectedFilterOption: string | undefined;
  setFilterOption: Dispatch<SetStateAction<TFilterOption>>;
  filterText: string;
  setFilterText: Dispatch<SetStateAction<string>>;
  isLoadingSearch?: boolean;
}

const SearchWithDropdownFilter = <TFilterOption,>({
  filterOptions,
  filterOptionsSubOptions,
  selectedFilterOption,
  setFilterOption,
  filterText,
  setFilterText,
  isLoadingSearch,
}: Props<TFilterOption>) => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const debouncedSetFilterText = debounce((value) => setFilterText(value), 500);

  const selectedFilterOptionIndex = selectedFilterOption
    ? filterOptions.findIndex((filterOption) => filterOption === selectedFilterOption)
    : null;

  const selectedFilterOptionSubOptions =
    selectedFilterOptionIndex !== null ? filterOptionsSubOptions?.[selectedFilterOptionIndex] : null;

  const [selectedFilterSubOption, setSelectedFilterSubOption] = useState<any>();

  useEffect(() => {
    if (selectedFilterSubOption) {
      setFilterText(selectedFilterSubOption);
    }
  }, [selectedFilterSubOption]);

  const resetFilters = () => {
    setFilterText('');
    setSelectedFilterSubOption(undefined);

    if (searchInputRef.current) {
      searchInputRef.current.value = '';
    }
  };

  useEffect(() => {
    if (!selectedFilterOptionSubOptions) {
      resetFilters();
    }
  }, [selectedFilterOptionSubOptions]);

  useEffect(() => {
    if (selectedFilterOptionIndex !== null) {
      resetFilters();
    }
  }, [selectedFilterOptionIndex]);

  return (
    <HStack gap={'15px'}>
      <Dropdown<TFilterOption>
        title="Select filter"
        selectedOption={selectedFilterOption}
        onOptionClick={(option) => setFilterOption(option as TFilterOption)}
        options={filterOptions as TFilterOption[]}
      />
      {selectedFilterOptionSubOptions ? (
        <HStack>
          <Dropdown<TFilterOption>
            title={`Select ${selectedFilterOption}`}
            selectedOption={selectedFilterSubOption}
            onOptionClick={(subOption) => setSelectedFilterSubOption(subOption as TFilterOption)}
            options={selectedFilterOptionSubOptions as TFilterOption[]}
          />
          {selectedFilterSubOption && (
            <Button
              height={'40px'}
              width={'30px'}
              paddingX={1}
              _hover={{ border: '1px solid #004179', paddingX: 0 }}
              onClick={() => {
                setFilterText('');
                setSelectedFilterSubOption('');
                if (searchInputRef.current) searchInputRef.current.value = '';
              }}
            >
              <CloseIcon />
            </Button>
          )}
        </HStack>
      ) : (
        <InputGroup>
          <Input
            ref={searchInputRef}
            width={'300px'}
            backgroundColor={'white'}
            placeholder={
              selectedFilterOption === undefined
                ? 'Please select a filter'
                : `Search by ${selectedFilterOption !== undefined ? selectedFilterOption : '...'}`
            }
            _placeholder={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
            defaultValue={filterText}
            onChange={(e) => debouncedSetFilterText(e.target.value)}
            isDisabled={selectedFilterOption === undefined}
            _disabled={{ opacity: 0.8, cursor: 'not-allowed' }}
          />

          <InputRightElement width="55px" height={'100%'}>
            <VStack backgroundColor={'white'}>
              {isLoadingSearch ? (
                <Spinner />
              ) : (
                <Button
                  height={'30px'}
                  onClick={() => {
                    setFilterText('');
                    if (searchInputRef.current) searchInputRef.current.value = '';
                  }}
                >
                  <CloseIcon />
                </Button>
              )}
            </VStack>
          </InputRightElement>
        </InputGroup>
      )}
    </HStack>
  );
};

export default SearchWithDropdownFilter;
