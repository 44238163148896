import React from 'react';
import { Text, VStack } from '@chakra-ui/react';
import ModalContainer from '../ModalContainer/ModalContainer';
import ConfirmCancelButtons, { ConfirmCancelButtonProps } from './ConfirmCancelButtons';

export interface ConfirmationModalProps extends ConfirmCancelButtonProps {
  title: string;
  description: string;
  isOpen: boolean;
  error?: string;
}

const ConfirmationModal = ({
  title,
  description,
  isOpen,
  onCancel: onClose,
  onConfirm,
  confirmButtonText,
  confirmButtonColor = 'primary.500',
  confirmLoading = false,
  error,
}: ConfirmationModalProps) => {
  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      modalBodyPadding={0}
      footerContent={
        <ConfirmCancelButtons
          confirmButtonText={confirmButtonText}
          confirmButtonColor={confirmButtonColor}
          onCancel={onClose}
          onConfirm={onConfirm}
          confirmLoading={confirmLoading}
          marginBottom="0"
        />
      }
    >
      <VStack>
        <Text fontSize={'20px'} marginTop={'20px'} textAlign={'center'} whiteSpace={'pre-wrap'} width={'100%'}>
          {description}
        </Text>
        {error && <Text variant={'error'}>{error}</Text>}
      </VStack>
    </ModalContainer>
  );
};

export default ConfirmationModal;
