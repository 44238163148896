import logoVariants from '@/assets/img/logo';
import { svgIconPaths } from '@/assets/svg/icons';
import SvgIcon from '@/components/atoms/SvgIcon';
import CustomTable from '@/components/molecules/CustomTable';
import DoughnutChart, { TChartColorItem, TChartDataItem } from '@/components/molecules/DoughnutChart';
import FilterSelectPopover from '@/components/molecules/FilterSelectPopover';
import StatisticsItemWithCircleText from '@/components/molecules/StatisticsItemWithCircleText';
import WellnessStateCircle from '@/components/molecules/WellnessStateCircle';
import CircleFillOutWithText from '@/components/molecules/WellnessStateCircle/CircleFillOutWithText';
import AppPageTemplate from '@/components/templates/AppPageTemplate';
import LoadingPage from '@/components/templates/LoadingPage';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import { EMPTY_WELLNESS_DOMAINS, MIN_CHARS_TO_DO_SEARCH } from '@/constants/numberVars';
import { useMemberCareOrganizationRoster } from '@/hooks/member-care/organization';
import colors from '@/theme/colors';
import { useMemberCareOrganizationDashboard } from '@/hooks/member-care/organization';
import { TMemberCareOrganizationRosterItem } from '@/types/MemberCare.types';
import { getColorFromPercentage } from '@/utils/getColorFromPercentage';
import { Center, HStack, Image, Input, InputGroup, InputLeftElement, Text, VStack } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';
import { useMemo, useState } from 'react';
import { generateConcernLevelChangeDisplay } from '@/utils/stringUtils';
import {
  HIGH_CONCERN_LABEL,
  LOW_CONCERN_LABEL,
  NO_CHANGE_CONCERN_LABEL,
  SOME_CONCERN_LABEL,
} from '@/constants/stringVars';
import getCircleArrowTextDisplayFromTrend from '@/utils/getCircleArrowTextDisplayFromTrend';
import { formatDate } from 'date-fns';
import appRoutes from '@/constants/routes/app';

const COLUMNS = ['First Name', 'Last Name', 'Last Assessment/Screener Score'] as const;
export type TMemberCareOrganizationRosterItemSortingColumn = (typeof COLUMNS)[number];

const ORG_LOGO_SIZE = 240;

// const CONCERN_LEVELS_MOCK = {
//   highConcern: { change: '14', percentage: 40 },
//   lowConcern: { change: '4', percentage: 30 },
//   someConcern: { change: '-6', percentage: 30 },
// };

const CHART_COLORS: TChartColorItem[] = [
  { name: LOW_CONCERN_LABEL, value: colors.extra.teal },
  { name: SOME_CONCERN_LABEL, value: colors.extra.yellow },
  { name: HIGH_CONCERN_LABEL, value: colors.extra.red },
];

const SEARCH_TEXT_INIT = '';

const MemberProfileView = () => {
  const { memberCareOrganizationId } = Route.useParams();
  const { data: organizationDashboard, isLoading: isLoadingDashboard } =
    useMemberCareOrganizationDashboard(memberCareOrganizationId);

  const concernLevelsChartData = useMemo(() => {
    const concernLevels = organizationDashboard?.concernLevels;
    const concernLevelsArray: TChartDataItem[] = [];

    concernLevelsArray.push({
      name: HIGH_CONCERN_LABEL,
      value: concernLevels?.highConcern.percentage ?? 0,
      description: concernLevels?.highConcern.change
        ? generateConcernLevelChangeDisplay(concernLevels.highConcern.change)
        : NO_CHANGE_CONCERN_LABEL,
    });
    concernLevelsArray.push({
      name: SOME_CONCERN_LABEL,
      value: concernLevels?.someConcern.percentage ?? 0,
      description: concernLevels?.someConcern.change
        ? generateConcernLevelChangeDisplay(concernLevels.someConcern.change)
        : NO_CHANGE_CONCERN_LABEL,
    });
    concernLevelsArray.push({
      name: LOW_CONCERN_LABEL,
      value: concernLevels?.lowConcern.percentage ?? 0,
      description: concernLevels?.lowConcern.change
        ? generateConcernLevelChangeDisplay(concernLevels.lowConcern.change)
        : NO_CHANGE_CONCERN_LABEL,
    });

    return concernLevelsArray;
  }, [organizationDashboard?.concernLevels]);

  const { totalScore, ...wellnessDataWithoutTotalScore } = organizationDashboard
    ? organizationDashboard.score
    : { totalScore: 0, ...EMPTY_WELLNESS_DOMAINS };

  const [localSearchText, setLocalSearchText] = useState(SEARCH_TEXT_INIT);

  const [committedSearchText, setCommittedSearchText] = useState(SEARCH_TEXT_INIT);

  // Updates all the 'committed' states which are sent to the data-fetching hook `useMemberCareMembers`
  const submitLocalSearchForm = () => {
    setCommittedSearchText(localSearchText);

    paginationRoster.getFirstPage();
  };

  const resetSearchForm = () => {
    setLocalSearchText(SEARCH_TEXT_INIT);

    setCommittedSearchText(SEARCH_TEXT_INIT);

    paginationRoster.getFirstPage();
  };

  const searchIsActive = committedSearchText.length > 0;

  const searchSubmitButtonDisabled =
    localSearchText.length < MIN_CHARS_TO_DO_SEARCH || localSearchText === committedSearchText;

  // TODO: could be needed later
  // const {
  //   sortingColumn: sortingColumnRoster,
  //   sortingDirection: sortingDirectionRoster,
  //   updateSortingColumn: updateSortingColumnRoster,
  // } = useTableColumnSort<TMemberCareOrganizationRosterItemSortingColumn>({
  //   defaultSortingColumn: 'Last Assessment/Screener Score',
  // });
  const {
    data: organizationRoster,
    isLoading: isLoadingRoster,
    isRefetching: isRefetchingRoster,
    pagination: paginationRoster,
  } = useMemberCareOrganizationRoster({
    searchText: committedSearchText,
    organizationId: memberCareOrganizationId,
    // sortingColumn: sortingColumnRoster,
    // sortingDirection: sortingDirectionRoster,
  });

  const rosterDataWithId = organizationRoster ?? [];

  const rosterTableListItems = useMemo(
    () =>
      rosterDataWithId.map((memberCareOrgRosterItem: TMemberCareOrganizationRosterItem) => {
        // in case old/deprecated data score is larger then 10, we need to multiply it by 10
        // Should not show up on production, but just in case
        const totalScoreX10 =
          memberCareOrgRosterItem.lastScore > 10
            ? memberCareOrgRosterItem.lastScore
            : memberCareOrgRosterItem.lastScore * 10;

        return {
          id: memberCareOrgRosterItem.id,
          cells: [
            memberCareOrgRosterItem.firstName,
            memberCareOrgRosterItem.lastName,

            !memberCareOrgRosterItem?.lastScore ? (
              <Text variant={'urbanistSmallBold'} color="text.mediumGray">
                NO SCORE
              </Text>
            ) : (
              <HStack gap={'14px'} marginLeft={'5px'}>
                <CircleFillOutWithText
                  circleDiameter={25}
                  svgId={memberCareOrgRosterItem.id}
                  thickness={4}
                  percentageFilled={totalScoreX10}
                  circleColor={getColorFromPercentage(totalScoreX10)}
                  showPercentageText={false}
                  boxShadow={'0px 3px 6px 0px #0E006224'}
                />
                <Text variant={'urbanistSemiBold'}>{totalScoreX10.toFixed()}</Text>
              </HStack>
            ),
          ],
        };
      }),
    [rosterDataWithId],
  );

  if (isLoadingDashboard || !organizationDashboard || isLoadingRoster) return <LoadingPage />;

  return (
    <AppPageTemplate>
      <VStack width={MAX_CONTAINER_WIDTH} paddingBottom={'100px'}>
        {/* Actual page content */}
        <VStack width={'100%'} marginBottom={'50px'}>
          {organizationDashboard?.logoUrl ? (
            <Image
              alt="user-avatar-wellness"
              width={`${ORG_LOGO_SIZE}px`}
              height={`${ORG_LOGO_SIZE}px`}
              src={organizationDashboard?.logoUrl}
              style={{ borderRadius: '50%', objectFit: 'cover' }}
            />
          ) : (
            <Center
              borderRadius={'50%'}
              border={'5px solid white'}
              boxShadow={'0px 4px 8px 0px #0E00621A'}
              width={`${ORG_LOGO_SIZE}px`}
              height={`${ORG_LOGO_SIZE}px`}
              backgroundColor={'primary.100'}
            >
              <Image src={logoVariants.purpleNoText112x112} width={'30px'} height={'30px'} alt="my-omnia-logo" />
            </Center>
          )}
          <Text variant={'urbanistTitleMedium'} marginTop={'14px'}>
            {organizationDashboard.name}
          </Text>
          {/* TODO: space-between kad se vrati 'Peer Support' dugme */}
          {/* <HStack width={'100%'} justifyContent={'center'} marginTop={'70px'}>
            <Text variant={'urbanistSemiBoldTitle'} fontWeight={700}>
              Organization Wholeness Report
            </Text>
            <CustomButton
              backgroundColor="secondary.500"
              labelColor="extra.white"
              label="Peer Support"
              width={'170px'}
              onClick={() => {}}
            />
          </HStack> */}

          {/* Concern Levels and Engagement */}
          <HStack gap={'50px'} marginTop={'80px'} justifyContent={'space-between'} width={'100%'}>
            {/* Concern Levels */}
            <VStack>
              <DoughnutChart
                data={concernLevelsChartData}
                colors={CHART_COLORS}
                chartSvgWidth={260}
                titleElement={
                  <Text variant={'loraMediumTitle'} marginBottom={'40px'}>
                    Concern Levels
                  </Text>
                }
                centeredElement={
                  <CircleFillOutWithText
                    percentageFilled={organizationDashboard.score.totalScore * 10}
                    thickness={7}
                    svgId="doughnut-center-element"
                    percentageTextStyle={{ fontSize: 18, fontWeight: 800 }}
                    circleDiameter={90}
                  />
                }
                // TODO: will maybe need later
                // footerElement={
                //   <VStack marginY={'10px'} gap={'10px'}>
                //     <Text variant={'urbanistRegular'} fontWeight={600} color="text.mediumGray">
                //       Up 6% since last quarter
                //     </Text>
                //     <Text variant={'urbanistRegular16'} textAlign={'center'}>
                //       Check out the
                //       <span style={{ color: colors.primary[500], fontWeight: 700 }}> resources section </span> below for
                //       tips and ideas on how to respond to these concerns.
                //     </Text>
                //   </VStack>
                // }
              />
            </VStack>

            {/* Engagement */}
            <VStack height={'100%'}>
              <Text variant={'loraMediumTitle'} marginBottom={'40px'}>
                Engagement
              </Text>
              <VStack
                backgroundColor={'extra.white'}
                boxShadow="0px 0px 20px 0px #00417926"
                width={'530px'}
                borderRadius={'12px'}
                paddingX={'30px'}
                paddingY={'20px'}
                gap={'20px'}
              >
                <StatisticsItemWithCircleText
                  textTitle="# of Users Signed Up vs Invited"
                  textSubtitle="Total"
                  circlePercentageFilled={
                    (organizationDashboard.engagement.signedUp / organizationDashboard.engagement.invited) * 100
                  }
                  circleTitle={organizationDashboard.engagement.signedUp}
                  circleSubtitle={
                    <Text variant={'urbanistSmallBold'} color={'text.mediumGray'}>
                      /{organizationDashboard.engagement.invited}
                    </Text>
                  }
                />
                <StatisticsItemWithCircleText
                  textTitle="# of Users who Took the Screener"
                  textSubtitle="Past 30 days"
                  circlePercentageFilled={
                    (organizationDashboard.engagement.screenerParticipants.last30Days /
                      organizationDashboard.engagement.signedUp) *
                    100
                  }
                  circleTitle={organizationDashboard.engagement.screenerParticipants.last30Days}
                  circleSubtitle={
                    <Text variant={'urbanistSmallBold'} color={'text.mediumGray'}>
                      /{organizationDashboard.engagement.signedUp}
                    </Text>
                  }
                />
                <StatisticsItemWithCircleText
                  circlePercentageFilled={
                    (organizationDashboard.engagement.screenersTaken.last30Days /
                      organizationDashboard.engagement.signedUp) *
                    100
                  }
                  circleTitle={organizationDashboard.engagement.screenersTaken.last30Days}
                  circleSubtitle={getCircleArrowTextDisplayFromTrend(
                    organizationDashboard.engagement.screenersTaken.trend,
                  )}
                  textTitle="# of Screeners Taken"
                  textSubtitle="Past 30 days"
                />
              </VStack>
            </VStack>
          </HStack>

          <Text variant={'loraSectionTitle'} marginTop="80px" marginBottom="40px">
            Organization Wholeness Report
          </Text>
          <WellnessStateCircle
            wellnessData={wellnessDataWithoutTotalScore}
            averageScore={totalScore}
            userAvatar={organizationDashboard?.logoUrl}
            showCenteredTextOverlay={false}
            avatarTooltipJSX={
              <>
                <Text color={'text.darkBlue'} variant={'urbanistRegular'} fontWeight={600} width={'100%'}>
                  Your Team's Progress
                </Text>
                <HStack gap={'20px'}>
                  {organizationDashboard.progress.map((progressMonthData) => (
                    <VStack key={progressMonthData.month} paddingTop={'10px'}>
                      <CircleFillOutWithText
                        showPercentageText={false}
                        percentageFilled={progressMonthData.averageScore * 10}
                        svgId={`team-hover-element-${progressMonthData.month}`}
                        centeredElement={
                          <Text fontSize={'12px'} fontWeight={800}>
                            {progressMonthData.screenerCount}
                          </Text>
                        }
                      />
                      <Text variant={'urbanistSmallBold'} textAlign={'center'}>
                        {formatDate(new Date(progressMonthData.year, progressMonthData.month - 1, 1), 'MMM')}
                      </Text>
                    </VStack>
                  ))}
                </HStack>
              </>
            }
          />

          {/* Roster with Search */}
          <HStack justifyContent={'space-between'} width={'100%'}>
            <Text variant="loraTitle">Roster</Text>
            {/* Search by Member name */}
            <FilterSelectPopover
              filterIconPath={svgIconPaths.magnifyingGlass}
              filterIconText="Search"
              contentWidth="260px"
              onFilterSubmit={submitLocalSearchForm}
              onFilterReset={resetSearchForm}
              submitButtonDisabled={searchSubmitButtonDisabled}
              isFilterActive={searchIsActive}
              popoverContent={
                <VStack marginBottom={'24px'} alignItems={'start'} width={'100%'}>
                  <Text variant={'urbanistBold'} marginTop={'10px'}>
                    Search By First or Last Name
                  </Text>

                  <InputGroup marginTop={'10px'} flexDir={'column'}>
                    <InputLeftElement>
                      <Center height={'100%'} marginTop={'18px'}>
                        <SvgIcon
                          iconPath={svgIconPaths.magnifyingGlass}
                          color={localSearchText.length > 0 ? colors.text.darkBlue : colors.text.mediumGray}
                        />
                      </Center>
                    </InputLeftElement>
                    <Input
                      className="custom-input"
                      backgroundColor={'white'}
                      placeholder="Search..."
                      _placeholder={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                      value={localSearchText}
                      onChange={(e) => setLocalSearchText(e.target.value)}
                      _disabled={{ opacity: 0.8, cursor: 'not-allowed' }}
                    />
                    <Text variant={'urbanistSmallBold'} color={'text.mediumGray'} padding={'5px'}>
                      Minimum 3 characters
                    </Text>
                  </InputGroup>
                </VStack>
              }
            />
          </HStack>

          <CustomTable<TMemberCareOrganizationRosterItem[], TMemberCareOrganizationRosterItemSortingColumn>
            isLoading={isLoadingRoster}
            dataWithId={rosterDataWithId}
            isRefetching={isRefetchingRoster}
            pagination={paginationRoster}
            tableListItems={rosterTableListItems}
            noItemsMessage="No roster items"
            columns={COLUMNS}
            // TODO: could be needed later
            // sortingColumn={sortingColumnRoster}
            // updateSortingColumn={updateSortingColumnRoster}
            // sortingDirection={sortingDirectionRoster}
            tableWidthPercentage="100%"
            customColumnGridPercentages={[35, 35, 30]}
            isSearching={false}
            hasRowSelect={true}
            customBaseUrl={appRoutes.memberCareMembers}
          />
        </VStack>
      </VStack>
    </AppPageTemplate>
  );
};

export const Route = createFileRoute(
  '/_authenticated/_member-care/member-care/organizations/$memberCareOrganizationId',
)({
  component: MemberProfileView,
});
