const rootPath = '/img/png/icons';

const iconsPng = {
  eyeClosed: `${rootPath}/eye-closed.png`,
  eyeOpened: `${rootPath}/eye-opened.png`,
  lock: `${rootPath}/lock.png`,
  info: `${rootPath}/info.png`,
  redX: `${rootPath}/red-x.png`,
  addMember: `${rootPath}/add-member.png`,
  orangeExclamationMark: `${rootPath}/orange-exclamation.png`,
  screener: `${rootPath}/screener.png`,
  topRightBackground: `${rootPath}/signup-top-right-background-circle.png`,
  observations: `${rootPath}/observations.png`,
  personReading: `${rootPath}/person-reading.png`,
  addTherapist: `${rootPath}/add-therapist.png`,
};

export default iconsPng;
