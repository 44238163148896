import { useMemo, useState } from 'react';
import type { CSSProperties } from 'react';

import SvgIcon from '../SvgIcon';
import colors from '@/theme/colors';
import { Button, Image, Text, ButtonProps } from '@chakra-ui/react';

type CustomButtonProps = {
  label?: string;
  iconBeforeLabelUrl?: string;
  iconBeforeLabelSvgPath?: string;
  iconBeforeLabelSvgColor?: string;
  iconBeforeLabelSvgSize?: number | string;
  buttonType?: ButtonProps['type'];
  onClick?: () => void;
  disabled?: boolean;
  disabledColor?: string;
  icon?: string;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  labelColor?: string;
  labelHoverColor?: string;
  isTransparent?: boolean;
  width?: string | number;
  height?: string | number;
  borderHoverColor?: string;
  isLoading?: boolean;
  noShadow?: boolean;
  style?: CSSProperties;
  labelStyle?: CSSProperties;
};

const colorToHoverColor: Record<string, string> = {
  'secondary.500': 'secondary.600',
  'primary.500': 'primary.600',
  'primary.400': 'primary.600',
  'extra.white': 'extra.white',
  white: 'background.lightGray',
  'extra.red': 'extra.darkRed',
  red: 'extra.darkRed',
};

const backgroundColorToDisabledColor: Record<string, string> = {
  'primary.500': 'primary.300',
  'secondary.500': 'secondary.300',
  '': 'background.gray',
};

const boxShadowColor = (backgroundColor: string | undefined) => {
  switch (backgroundColor) {
    case 'secondary.500':
    case 'secondary.600':
      return 'secondary.200';

    case 'primary.500':
    case 'primary.600':
      return '#1A00B980';

    case 'extra.red':
      return '#7D3F004D';

    default:
      return '#7C9EBC80';
  }
};

const CustomButton = ({
  label,
  iconBeforeLabelUrl,
  iconBeforeLabelSvgPath,
  buttonType = 'button',
  onClick,
  disabled,
  icon,
  isTransparent,
  backgroundColor = 'primary.500',
  disabledColor = backgroundColorToDisabledColor[backgroundColor ?? ''],
  hoverBackgroundColor = isTransparent ? 'transparent' : colorToHoverColor[backgroundColor],
  labelColor = 'extra.white',
  iconBeforeLabelSvgColor = labelColor ?? colors.background.blueGray,
  iconBeforeLabelSvgSize,
  labelHoverColor,
  width,
  height = '54px',
  borderHoverColor = 'white',
  isLoading = false,
  noShadow = false,
  style,
  labelStyle,
}: CustomButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const background = useMemo(() => {
    if (disabled) return disabledColor;
    if (isHovered) return hoverBackgroundColor;
    if (isTransparent) return 'transparent';
    return backgroundColor;
  }, [disabled, disabledColor, isHovered, hoverBackgroundColor, isTransparent, backgroundColor]);

  return (
    <Button
      background={background}
      onMouseEnter={() => (disabled ? null : setIsHovered(true))}
      onMouseLeave={() => setIsHovered(false)}
      onClick={disabled ? () => {} : onClick}
      disabled={disabled || isLoading}
      isDisabled={disabled || isLoading}
      width={width}
      height={height}
      isLoading={isLoading}
      boxShadow={isTransparent || disabled || noShadow ? '' : `0px 2px 4px 0px ${boxShadowColor(backgroundColor)}`}
      type={buttonType}
      border={isTransparent && isHovered ? `2px solid ${borderHoverColor}` : '2px solid transparent'}
      cursor={disabled ? 'not-allowed' : 'pointer'}
      style={style}
      _hover={{}}
    >
      {iconBeforeLabelUrl ? <Image src={iconBeforeLabelUrl} width={16} height={16} alt="button-icon" /> : null}

      {iconBeforeLabelSvgPath ? (
        <SvgIcon
          iconPath={iconBeforeLabelSvgPath}
          size={iconBeforeLabelSvgSize}
          color={(isHovered ? labelHoverColor : iconBeforeLabelSvgColor) ?? iconBeforeLabelSvgColor}
        />
      ) : null}

      <Text
        color={isHovered ? (labelHoverColor ?? labelColor) : (labelColor ?? 'background.blueGray')}
        marginLeft={icon || iconBeforeLabelUrl || iconBeforeLabelSvgPath ? '10px' : 0}
        variant={'urbanistBold'}
        style={labelStyle}
      >
        {label}
      </Text>
    </Button>
  );
};

export default CustomButton;
