import CustomButton from '@/components/atoms/CustomButton';
import ModalContainer from '@/components/molecules/ModalContainer/ModalContainer';
import { MAX_CHARS_IN_OBSERVATION_CONTENT, MIN_CHARS_IN_OBSERVATION_CONTENT } from '@/constants/numberVars';
import { Input, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface Props {
  title: string;
  description: string;
  inputPlaceholder: string;
  memberId: string;
  addObservationModalOpened: boolean;
  setAddObservationModalOpened: Dispatch<SetStateAction<boolean>>;
  createMutation: ({ content, memberId }: { content: string; memberId: string }) => void;
  isCreationPending: boolean;
}

const observationSchema = yupResolver(
  yup.object().shape({
    content: yup
      .string()
      .required('Content is required.')
      .min(
        MIN_CHARS_IN_OBSERVATION_CONTENT,
        `Content needs to be at least ${MIN_CHARS_IN_OBSERVATION_CONTENT} characters long.`,
      )
      .max(
        MAX_CHARS_IN_OBSERVATION_CONTENT,
        `Content length cannot exceed ${MAX_CHARS_IN_OBSERVATION_CONTENT} characters.`,
      ),
  }),
);

const AddMemberObservationNoteModal = ({
  title,
  description,
  inputPlaceholder,
  memberId,
  addObservationModalOpened,
  setAddObservationModalOpened,
  createMutation,
  isCreationPending,
}: Props) => {
  const { register, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    resolver: observationSchema,
  });

  const { isValid: isAddObservationValid, errors: addObservationErrors, touchedFields } = formState;

  const onSubmit = async ({ content }: { content: string }) => {
    await createMutation({ content, memberId });

    setAddObservationModalOpened(false);
    reset();
  };

  return (
    <ModalContainer
      isOpen={addObservationModalOpened}
      onClose={() => setAddObservationModalOpened(false)}
      title={title}
    >
      <VStack width={'100%'}>
        <Text variant={'urbanistSemiBold'} color={'text.mediumGray'} fontWeight={500}>
          {description}
        </Text>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <VStack spacing={'20px'} width={'100%'}>
            <VStack width={'100%'} alignItems={'flex-start'} spacing={0}>
              <Input
                placeholder={inputPlaceholder}
                as={'textarea'}
                className="custom-input"
                style={{ minHeight: '150px', maxHeight: '350px' }}
                {...register('content')}
                data-state={touchedFields.content ? (addObservationErrors.content?.message ? 'invalid' : 'valid') : ''}
              />
              <Text color={'red.500'} fontSize={'14px'}>
                {addObservationErrors.content?.message ?? ''}
              </Text>
            </VStack>
            <CustomButton
              width={'200px'}
              label="Save"
              buttonType="submit"
              disabled={!isAddObservationValid}
              disabledColor="primary.500"
              isLoading={isCreationPending}
            />
          </VStack>
        </form>
      </VStack>
    </ModalContainer>
  );
};

export default AddMemberObservationNoteModal;
