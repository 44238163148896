import queryKeys from '@/constants/queryKeys';
import { getTherapistById } from '@/services/api/requests/therapists';
import { useQuery } from '@tanstack/react-query';

const useTherapistById = (id: string) => {
  const { isPending, isLoading, isSuccess, data, isRefetching, refetch } = useQuery({
    queryKey: [queryKeys.therapists.byId, id],
    queryFn: async () => {
      const response = await getTherapistById(id);
      return response;
    },
  });

  return { isPending, isLoading, isSuccess, data, isRefetching, refetch };
};

export default useTherapistById;
