import { TCustomAxiosError } from '@/services/api';
import { TOrganizationContract } from '@/types/Organization.types';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import useToast from '../useToast';
import { editContractTotal } from '@/services/api/requests/contracts';
import { TEditContractService } from '@/types/Contracts.types';

const useEditContractServiceTotal = (
  onSuccess?: (newContract: TOrganizationContract) => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<unknown, TCustomAxiosError, TEditContractService, unknown>;
} => {
  const toast = useToast();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({ contractId, editedContractServiceType, changeAmount }: TEditContractService) => {
      const response = await editContractTotal(contractId, editedContractServiceType, changeAmount);
      return response.data;
    },
    onSuccess: (editedContract) => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Service Total Amount successfully edited.',
      });

      onSuccess?.(editedContract);
    },
    onError: (error: TCustomAxiosError) => {
      toast(
        {
          status: 'error',
          title: 'Contract editing error',
          description: error?.errors[0]?.message,
        },
        error,
      );
    },
  });

  return { mutate, isPending, isError };
};

export default useEditContractServiceTotal;
