import ModalContainer from '@/components/molecules/ModalContainer/ModalContainer';
import { Box, HStack, Image, Input, Select, Text, VStack } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { CONTRACT_DOCUMENT_TYPES, CONTRACT_DOCUMENT_TYPES_DISPLAY_LABELS } from '@/constants/stringVars';
import { TContractDocumentUpload } from '@/types/Organization.types';
import CustomButton from '@/components/atoms/CustomButton';
import iconsPng from '@/assets/img/png/icons';
import ConfirmCancelButtons from '@/components/molecules/ConfirmationModal/ConfirmCancelButtons';
import useUploadContractDocument from '@/hooks/contracts/useUploadContractDocument';

interface Props {
  contractId: string;
  isOpen: boolean;
  onClose: () => void;
  onUploadSuccessful: () => void;
}

const DESCRIPTION_MAX_CHARS = 200;

const yupSchema = yup.object().shape({
  type: yup
    .string()
    .required('Document type is required.')
    .oneOf(CONTRACT_DOCUMENT_TYPES, 'Document type is required.'),
  description: yup
    .string()
    .max(DESCRIPTION_MAX_CHARS, `Description can not contain more than ${DESCRIPTION_MAX_CHARS} characters.`),
});

const ContractDocumentUploadModal = ({ contractId, isOpen, onClose, onUploadSuccessful }: Props) => {
  const { mutate, isPending, isError, error } = useUploadContractDocument(() => {
    onClose();
    reset();
    onUploadSuccessful();
  });

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(yupSchema),
    defaultValues: { type: 'SIGNED_CONTRACT' },
  });

  const { handleSubmit, register, formState, reset } = methods;

  const { isValid, errors, touchedFields } = formState;

  const inputFileRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<FileList | null>(null);

  const onSubmit = (data: TContractDocumentUpload) => {
    mutate({ contractId, file: selectedFile![0], type: data.type!, description: data.description });
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={onClose}
      title="Create a Contract"
      titleFontSize="24px"
      backgroundColor={'background.lightBlue'}
      width={'820px'}
      contentStyle={{ padding: '20px', paddingBottom: 0 }}
      modalBodyPadding={'30px'}
    >
      <VStack paddingX={'50px'} align={'center'} maxHeight={'70vh'} overflow={'scroll'}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <VStack gap="40px" paddingY={'20px'}>
              <VStack>
                <Text variant={'urbanistMedium16'}>Document Type</Text>
                <Select
                  placeholder="Select Document Type"
                  disabled
                  {...register('type')}
                  data-state={touchedFields.type ? (errors.type?.message ? 'invalid' : 'valid') : ''}
                  className="custom-input custom-select-padding"
                  width={'400px'}
                >
                  {CONTRACT_DOCUMENT_TYPES.map((documentType) => (
                    <option value={documentType} key={documentType}>
                      {CONTRACT_DOCUMENT_TYPES_DISPLAY_LABELS[documentType]}
                    </option>
                  ))}
                </Select>
                {errors.type && <Text variant={'error'}>{errors.type.message}</Text>}
              </VStack>

              <Input
                hidden
                type="file"
                accept={'application/pdf'}
                ref={inputFileRef}
                onChange={(event) => setSelectedFile(event.target.files)}
              />

              <VStack gap={'20px'}>
                <CustomButton
                  onClick={() => inputFileRef.current?.click()}
                  label={`Upload ${!!selectedFile ? 'different' : ''} Contract`}
                  labelHoverColor="white"
                  labelColor="white"
                />

                {selectedFile && (
                  <HStack
                    width={'295px'}
                    borderRadius={'8px'}
                    background={'white'}
                    padding={'10px'}
                    justify={'space-between'}
                    height={'54px'}
                  >
                    <Text
                      color={'text.darkBlue'}
                      fontSize={'14px'}
                      fontWeight={'500'}
                      noOfLines={1}
                      marginRight={'18px'}
                    >
                      {selectedFile[0]?.name}
                    </Text>
                    <Box
                      onClick={() => {
                        setSelectedFile(null);
                        if (inputFileRef.current) inputFileRef.current.value = '';
                      }}
                      cursor={'pointer'}
                    >
                      <Image alt="delete-file-x" src={iconsPng.redX} width={'20px'} height={'20px'} />
                    </Box>
                  </HStack>
                )}
              </VStack>

              <Input
                width={'400px'}
                className="custom-input"
                {...register('description')}
                placeholder="Description (optional)"
              />
              {errors.description && <Text variant={'error'}>{errors.description.message}</Text>}

              <VStack marginY={'20px'}>
                <ConfirmCancelButtons
                  confirmButtonText={'Confirm Upload'}
                  onCancel={onClose}
                  confirmLoading={isPending}
                  confirmButtonType="submit"
                  confirmButtonDisabled={!isValid || !selectedFile}
                  minButtonWidth="200px"
                />
                {isError && (
                  <Text variant={'error'} paddingBottom={'20px'} marginLeft={'30px'} width={'450px'} align={'center'}>
                    {error?.errors[0].message}
                  </Text>
                )}
              </VStack>
            </VStack>
          </form>
        </FormProvider>
      </VStack>
    </ModalContainer>
  );
};

export default ContractDocumentUploadModal;
