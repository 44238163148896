import logoVariants from '@/assets/img/logo';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import authRoutes from '@/constants/routes/auth';
import { useAuthContext } from '@/context/auth/useAuthContext';
import { Container, HStack, Image } from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-router';
import React, { useEffect, useState } from 'react';
import AuthHeader from './variants/AuthHeader';
import DefaultHeader from './variants/DefaultHeader';

type Props = {
  variant?: 'transparent' | 'default';
  backgroundColor?: string;
  isSticky?: boolean;
  hideButtons?: boolean;
  centeredLogo?: boolean;
};

const Header = ({
  variant = 'default',
  backgroundColor = 'transparent',
  isSticky = true,
  hideButtons = false,
  centeredLogo = false,
}: Props) => {
  const { isAuthenticated, isMemberCare, isOnboarded } = useAuthContext();

  const isTransparent = variant === 'transparent';
  const defaultLogoPath = isTransparent ? logoVariants.white252x80 : logoVariants.purple252x80;
  const logoPath = isMemberCare && isOnboarded ? logoVariants.purpleMemberCare229x40 : defaultLogoPath;

  const navigate = useNavigate();

  const [scrollPosition, setScrollPosition] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    // Check if scrolling down or up
    if (currentScrollPos > scrollPosition) {
      setIsVisible(false); // Hide on scroll down
    } else {
      setIsVisible(true); // Show on scroll up
    }

    setScrollPosition(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollPosition]);

  return (
    <Container
      as={'header'}
      transition="transform 0.3s ease-in-out"
      transform={isVisible ? 'translateY(0)' : 'translateY(-100%)'}
      maxWidth={MAX_CONTAINER_WIDTH}
      paddingX={0}
      top={0}
      zIndex={3}
      position={isSticky ? 'relative' : 'fixed'}
      paddingTop={centeredLogo ? '40px' : '20px'}
      backgroundColor={backgroundColor}
    >
      <HStack justifyContent={centeredLogo ? 'center' : 'space-between'} alignItems={'center'}>
        <Image
          src={logoPath}
          alt="my-omnia"
          _hover={{ cursor: 'pointer' }}
          // width={'126px'}
          height={'40px'}
          onClick={() => {
            navigate({ to: isAuthenticated ? '/' : authRoutes.signIn });
          }}
        />
        {!hideButtons && (
          <HStack alignItems={'center'}>
            {!isAuthenticated && <AuthHeader />}
            {isAuthenticated && <DefaultHeader isTransparent={isTransparent} />}
          </HStack>
        )}
      </HStack>
    </Container>
  );
};

export default Header;
