import { usePaginatedSearch } from '../usePaginatedSearch';
import { TContractFilterOption } from '@/types/Contracts.types';
import { getContracts } from '@/services/api/requests/contracts';
import queryKeys from '@/constants/queryKeys';
import { TContractAllApiResponse } from '@/types/api/Contract.types';
import { TSortingParams } from '@/types';

const useContracts = ({
  filterOption,
  filterText,
  sortingColumn,
  sortingDirection,
}: {
  filterOption?: TContractFilterOption;
  filterText: string;
} & TSortingParams) => {
  return usePaginatedSearch<TContractAllApiResponse[], TContractFilterOption, string>({
    query: getContracts,
    queryKey: queryKeys.contracts.all,
    searchFilterType: filterOption ?? 'Name',
    searchFilterText: filterText,
    sortingColumn,
    sortingDirection,
  });
};

export default useContracts;
