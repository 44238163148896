import LoadingLogo from '@/components/atoms/LoadingLogo';
import UserProfileBoxInfoPiece from '@/components/atoms/UserProfileBoxInfoPiece';
import ProfileBox from '@/components/molecules/ProfileBox';
import { TOrganizationSuperAdminExtended } from '@/types/Organization.types';
import { Box, Center, HStack, Text, VStack } from '@chakra-ui/react';
import { isUndefined } from 'lodash';
import React from 'react';

const IMAGE_SIZE_PX = '200px';

type Props = {
  organizationData: TOrganizationSuperAdminExtended | undefined;
  isLoading: boolean;
};

const OrganizationInfoTab = ({ isLoading, organizationData }: Props) => {
  return (
    <VStack flex={1} width={'100%'} paddingTop={'30px'}>
      <Text variant={'loraMediumTitle'}>Organization</Text>
      {isLoading && (
        <VStack flex={1} justifyContent={'center'} alignItems={'center'}>
          <LoadingLogo />
        </VStack>
      )}
      {!isLoading && organizationData && (
        <HStack justifyContent={'space-between'} alignItems={'flex-start'} marginTop={'15px'}>
          <ProfileBox style={{ minWidth: '600px' }} title="Contact Details">
            <HStack justifyContent={'space-between'} alignItems={'center'}>
              <VStack alignItems={'start'} gap={'15px'} marginTop={'10px'}>
                <Box minWidth={'160px'} marginBottom={'30px'}>
                  <Text variant={'urbanistSemiBoldTitle'} textAlign={'start'}>
                    {organizationData?.details.name}
                  </Text>
                </Box>
                <UserProfileBoxInfoPiece
                  title="Representative Name"
                  text={organizationData?.details.representativeFullName ?? 'N/A'}
                />
                <UserProfileBoxInfoPiece
                  title="Representative Email"
                  text={organizationData?.details.representativeEmail ?? 'N/A'}
                />
                <UserProfileBoxInfoPiece
                  title="Representative Phone"
                  text={organizationData?.details.representativePhoneNumber ?? 'N/A'}
                />
                <UserProfileBoxInfoPiece
                  title="Signed Up / Invited"
                  text={`${organizationData?.users?.signedUp} / ${organizationData?.users.invited}`}
                />
                <UserProfileBoxInfoPiece
                  title="Website"
                  text={
                    isUndefined(organizationData?.details.website)
                      ? '/'
                      : `https://${organizationData?.details.website}`
                  }
                  isLink={!isUndefined(organizationData?.details.website)}
                />
              </VStack>
              <Center
                borderRadius={'50%'}
                backgroundImage={organizationData?.logoUrl ?? ''}
                height={IMAGE_SIZE_PX}
                width={IMAGE_SIZE_PX}
                backgroundPosition={'center'}
                backgroundRepeat={'no-repeat'}
                backgroundSize={'cover'}
                borderWidth={'5px'}
                borderColor={'white'}
                boxShadow={'0px 6px 12px 0px #0E006224'}
                position={'relative'}
                overflow={'hidden'}
              ></Center>
            </HStack>
          </ProfileBox>
        </HStack>
      )}
    </VStack>
  );
};

export default OrganizationInfoTab;
