const label4BEMapper = (label?: string): string => {
  switch (label) {
    case 'Organization':
      return 'organizationName';
    case 'Status':
      return 'status';
    case 'Start Date':
      return 'startDate';
    case 'End Date':
      return 'endDate';
    case 'Total':
      return 'total';
    case 'Used':
      return 'used';
    case 'Designation':
      return 'designation';
    case 'Appointment Type':
      return 'appointmentType';
    case 'Name':
      return 'search';
    case 'Practice Name':
      return 'search';
    default:
      return '';
  }
};

export { label4BEMapper };
