import CustomTable from '@/components/molecules/CustomTable';
import SearchWithDropdownFilter from '@/components/molecules/SearchWithDropdownFilter';
import SidebarItemViewTemplate from '@/components/templates/SidebarItemViewTemplate';
import { MIN_CHARS_TO_DO_SEARCH } from '@/constants/numberVars';
import appRoutes from '@/constants/routes/app';
import { CONTRACT_STATUS_DISPLAY_LABELS } from '@/constants/stringVars';
import useContracts from '@/hooks/contracts/useContracts';
import { useTableColumnSort } from '@/hooks/useTableColumnSort';
import { TContractAllApiResponse } from '@/types/api/Contract.types';
import { TContractFilterOption } from '@/types/Contracts.types';
import { contractStatus4UIMapper } from '@/utils/mappers/contractStatus';
import { HStack } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';

import React, { useMemo, useState } from 'react';

const COLUMNS = ['Organization', 'Name', 'Status', 'Start Date', 'End Date', 'Total', 'Used'];
const COLUMNS_TO_SORT_CONTRACTS_BY = ['Organization', 'Start Date', 'End Date', 'Total', 'Used'] as const;
export type TContractsSortingColumn = (typeof COLUMNS_TO_SORT_CONTRACTS_BY)[number];

const Contracts = () => {
  const [filterOption, setFilterOption] = useState<TContractFilterOption | undefined>();
  const [filterText, setFilterText] = useState('');

  const { sortingColumn, sortingDirection, updateSortingColumn } = useTableColumnSort<string>({
    defaultSortingColumn: 'Organization',
  });

  const {
    isLoading,
    isRefetching,
    data: contracts,
    pagination,
  } = useContracts({ filterText, filterOption: filterOption, sortingColumn, sortingDirection });

  const isSearching = useMemo(
    () => filterOption !== undefined && filterText.length >= MIN_CHARS_TO_DO_SEARCH && isRefetching,
    [filterOption, filterText, isRefetching],
  );

  const data = {
    nodes:
      contracts?.map((contract) => ({
        ...contract,
        id: contract.contractId,
      })) ?? [],
  };

  const tableListItems = useMemo(
    () =>
      data.nodes.map((item: (typeof data.nodes)[number]) => {
        return {
          id: item.contractId,
          cells: [
            item.organizationName,
            item?.name ?? 'N/A',
            contractStatus4UIMapper(item.status),
            String(item.startDate),
            String(item.endDate),
            String(item.total),
            String(item.used),
          ],
        };
      }),
    [data.nodes],
  );

  return (
    <SidebarItemViewTemplate title="Contracts">
      <HStack width={'90%'} marginTop={'20px'} justifyContent={'flex-end'}>
        <SearchWithDropdownFilter
          selectedFilterOption={filterOption}
          filterOptions={['Organization', 'Status']}
          filterOptionsSubOptions={[null, Object.values(CONTRACT_STATUS_DISPLAY_LABELS)]}
          filterText={filterText}
          setFilterOption={setFilterOption}
          setFilterText={setFilterText}
          isLoadingSearch={isSearching}
        />
      </HStack>
      <CustomTable<TContractAllApiResponse[], string>
        noItemsMessage="No contracts found"
        columns={COLUMNS}
        columnsToSortBy={COLUMNS_TO_SORT_CONTRACTS_BY}
        isLoading={isLoading}
        isRefetching={isRefetching}
        isSearching={isSearching}
        dataWithId={data.nodes}
        sortingColumn={sortingColumn}
        updateSortingColumn={updateSortingColumn}
        sortingDirection={sortingDirection}
        tableListItems={tableListItems}
        pagination={pagination}
        hasRowSelect
        customBaseUrl={appRoutes.organizations}
      />
    </SidebarItemViewTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_admin/contracts/')({
  component: Contracts,
});
