import SidebarItemViewTemplate from '@/components/templates/SidebarItemViewTemplate';
import { HStack } from '@chakra-ui/react';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { useState } from 'react';
import ActivatedUsersTable from '@/modules/user/active/ActivatedUsersTable';
import DeactivatedUsersTable from '@/modules/user/DeactivatedUsersTable';
import { TUserFilterOption } from '@/types/User.types';
import { USER_FILTER_BY_OPTIONS } from '@/constants/stringVars';
import { MIN_CHARS_TO_DO_SEARCH } from '@/constants/numberVars';
import SearchWithDropdownFilter from '@/components/molecules/SearchWithDropdownFilter';
import SidebarContentWrapper from '@/modules/sidebar/SidebarContentWrapper';
import TabButtons from '@/components/atoms/TabButtons';

const Users = () => {
  const [usersSelection, setUsersSelection] = useState<'activated' | 'deactivated'>('activated');

  const [filterOption, setFilterOption] = useState<TUserFilterOption | undefined>();
  const [filterText, setFilterText] = useState('');

  const filterTextToSearch = filterText.length < MIN_CHARS_TO_DO_SEARCH ? '' : filterText;

  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  return (
    <SidebarContentWrapper>
      <SidebarItemViewTemplate title={`${usersSelection === 'activated' ? 'Active' : 'Deactivated'} Users`}>
        <HStack width={'100%'} marginTop={'20px'} justifyContent={'space-between'}>
          <TabButtons
            options={[
              {
                onClick: () => setUsersSelection('activated'),
                labelValue: 'activated',
                labelDisplay: 'Active',
              },
              {
                onClick: () => setUsersSelection('deactivated'),
                labelValue: 'deactivated',
                labelDisplay: 'Deactivated',
              },
            ]}
            selectedOptionValue={usersSelection}
          />

          <SearchWithDropdownFilter
            selectedFilterOption={filterOption}
            filterOptions={USER_FILTER_BY_OPTIONS}
            filterText={filterText}
            setFilterOption={setFilterOption}
            setFilterText={setFilterText}
            isLoadingSearch={isLoadingSearch}
          />
        </HStack>
        {usersSelection === 'activated' ? (
          <ActivatedUsersTable
            filterOption={filterOption}
            filterText={filterTextToSearch}
            setIsLoadingSearch={setIsLoadingSearch}
          />
        ) : (
          <DeactivatedUsersTable
            filterOption={filterOption}
            filterText={filterTextToSearch}
            setIsLoadingSearch={setIsLoadingSearch}
          />
        )}
      </SidebarItemViewTemplate>
    </SidebarContentWrapper>
  );
};

export const Route = createFileRoute('/_authenticated/')({
  component: Users,
  beforeLoad: async ({ context: { auth } }) => {
    const isMemberCare = auth?.isMemberCare;
    const isOnboarded = auth?.isOnboarded;

    if (isMemberCare && !isOnboarded) {
      throw redirect({
        to: '/onboarding/member-care',
      });
    }

    if (isMemberCare && isOnboarded) {
      throw redirect({
        to: '/member-care/members',
      });
    }
  },
});
