import { HStack, Text, useDisclosure, VStack } from '@chakra-ui/react';
import React from 'react';
import OrganizationRoster from '../OrganizationRoster';
import InviteOrganizationAdminModal from '../InviteOrganizationAdminModal';
import CustomButton from '@/components/atoms/CustomButton';

type Props = {
  organizationId: string;
};

const OrganizationRosterTab = ({ organizationId }: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <VStack flex={1} width={'100%'} paddingTop={'30px'}>
      <HStack width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <HStack flex={1} />
        <Text variant={'loraMediumTitle'}>Members</Text>
        <HStack flex={1} justifyContent={'flex-end'}>
          <CustomButton labelHoverColor="extra.black" label="Invite Organization Admin" onClick={onOpen} />
        </HStack>
      </HStack>
      <VStack marginTop={'15px'} width={'100%'}>
        <OrganizationRoster organizationId={organizationId} />
      </VStack>
      <InviteOrganizationAdminModal isOpen={isOpen} onClose={onClose} organizationId={organizationId} />
    </VStack>
  );
};

export default OrganizationRosterTab;
