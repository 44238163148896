import { TContractStatus } from '@/types/Contracts.types';

const contractStatus4UIMapper = (status: TContractStatus): string => {
  switch (status) {
    case 'ACTIVE':
      return 'Active';
    case 'EXPIRING_SOON':
      return 'Expiring Soon';
    case 'EXPIRED':
      return 'Expired';
    case 'PENDING':
      return 'Pending';
    default:
      return '';
  }
};

const contractStatus4BEMapper = (status: string): TContractStatus => {
  const statusUpperCase = status.toUpperCase();

  switch (statusUpperCase) {
    case 'ACTIVE':
      return 'ACTIVE';
    case 'EXPIRING SOON':
      return 'EXPIRING_SOON';
    case 'EXPIRED':
      return 'EXPIRED';
    case 'PENDING':
      return 'PENDING';
    default:
      return 'ALL';
  }
};

export { contractStatus4UIMapper, contractStatus4BEMapper };
