import queryKeys from '@/constants/queryKeys';
import { getOrganizationActiveContract } from '@/services/api/requests/contracts';
import { TOrganizationContract } from '@/types/Organization.types';
import { QueryObserverResult, RefetchOptions, useQuery } from '@tanstack/react-query';

const useOrganizationActiveContract = (
  organizationId: string,
): {
  isLoading: boolean;
  isRefetching: boolean;
  isSuccess: boolean;
  data: Awaited<ReturnType<typeof getOrganizationActiveContract>> | undefined;
  isError: boolean;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<TOrganizationContract, Error>>;
} => {
  const { isLoading, isSuccess, data, isError, refetch, isRefetching } = useQuery({
    queryKey: [queryKeys.organization.organizationActiveContract, organizationId],
    queryFn: async () => {
      const response = await getOrganizationActiveContract(organizationId);
      return response;
    },
  });

  return { isLoading, isSuccess, data, isError, refetch, isRefetching };
};

export default useOrganizationActiveContract;
