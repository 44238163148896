import {
  TMemberCareScoreFilterBE,
  TMemberCareScoreFilterFE,
  TMemberCareScoreFilterLabelFE,
} from '@/types/MemberCare.types';
import { WELLNESS_STATE_LIMIT_DANGER, WELLNESS_STATE_LIMIT_OK } from './numberVars';

export const APP_NAME = 'MyOmnia Backoffice';
export const APP_DESCRIPTION = 'Empowering Your Wholeness';
export const NONE_OF_THE_ABOVE_LABEL = 'None of the above';
export const MODAL_QUESTION_FORM_KEY = 'modal_form_key';

export const CLIENT_APP_SIGN_IN_URL = 'https://app.myomnia.health/sign-in';

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const USER_FILTER_BY_OPTIONS = ['Email', 'Organization', 'First name', 'Last name'] as const;
export const MEMBER_CARE_FILTER_BY_OPTIONS = ['Email', 'First name', 'Last name'] as const;
export const MEMBER_CARE_MEMBER_FILTER_BY_OPTIONS = ['First name', 'Last name', 'Organization'] as const;

export const OPTION_OTHER = 'Other (please specify)';
export const OPTION_OTHER_BE_VALUE = 'OTHER';

export const INCONCLUSIVE_LAST_LOGIN_TEXT = 'A long time ago...';

export const SexBeFe = {
  MALE: 'Male',
  FEMALE: 'Female',
  NOT_TO_SAY: 'Prefer not to say',
};

export const SexFeBe = {
  Male: 'MALE',
  Female: 'FEMALE',
  'Prefer not to say': 'NOT_TO_SAY',
};

export const SEX_OPTIONS = Object.keys(SexFeBe);

export const MEMBER_CARE_SCORE_FILTER_VALUES = ['all', 'red', 'yellow', 'green'] as const;
export const MEMBER_CARE_SCORE_FILTER_LABELS = ['All Scores', 'Red Zone', 'Yellow Zone', 'Green Zone'] as const;

export const MEMBER_CARE_SEARCH_BY_LABELS = ['Individual name', 'Organization'] as const;

export const MEMBER_CARE_SCORE_FILTER_LABEL_TO_VALUE: Record<TMemberCareScoreFilterFE, TMemberCareScoreFilterLabelFE> =
  {
    all: 'All Scores',
    red: 'Red Zone',
    yellow: 'Yellow Zone',
    green: 'Green Zone',
  };

export const MEMBER_CARE_SCORE_FILTER_VALUE_TO_LABEL: Record<TMemberCareScoreFilterLabelFE, TMemberCareScoreFilterFE> =
  {
    'All Scores': 'all',
    'Red Zone': 'red',
    'Yellow Zone': 'yellow',
    'Green Zone': 'green',
  };

export const MEMBER_CARE_SCORE_FILTER_FE_BE: Record<TMemberCareScoreFilterFE, TMemberCareScoreFilterBE | undefined> = {
  all: undefined,
  green: { fromScore: WELLNESS_STATE_LIMIT_OK / 10, toScore: 100 / 10 },
  yellow: { fromScore: WELLNESS_STATE_LIMIT_DANGER / 10, toScore: WELLNESS_STATE_LIMIT_OK / 10 },
  red: { fromScore: 1, toScore: WELLNESS_STATE_LIMIT_DANGER / 10 },
};

export const CALENDLY_LINK_PLACEHOLDER = 'https://calendly.com/john-doe';

export const MEMBER_NOTE_TYPES = ['PERSONAL', 'MEMBERCARE_TO_USER', 'USER_TO_MEMBERCARE'] as const;
export const TIMELINE_TYPES = ['assessment', 'screener', 'memberCareNote', 'selfNote', 'observation'] as const;

export const MEMBER_PROFILE_SECTION_VALUES = ['member', 'notes'] as const;
const MEMBER_PROFILE_SECTION_DISPLAY = ['MEMBER', 'NOTES'] as const;

export const MEMBER_PROFILE_SECTIONS = MEMBER_PROFILE_SECTION_VALUES.map((sectionValue, i) => ({
  value: sectionValue,
  display: MEMBER_PROFILE_SECTION_DISPLAY[i],
}));

export const HIGH_CONCERN_LABEL = 'High Concern';
export const SOME_CONCERN_LABEL = 'Some Concern';
export const LOW_CONCERN_LABEL = 'Low Concern';

export const NO_CHANGE_CONCERN_LABEL = 'No change';

export const MEMBERS_TAB_VALUE = 'members';
export const ORGANIZATIONS_TAB_VALUE = 'organizations';

// PREPAID_THERAPY_SESSIONS (total, used)
// WELLNESS_TRAINING_SESSIONS (total, used)
// PEER_SUPPORT_WORKSHOP (total, used)
// PEER_SUPPORT_CERTIFICATIONS (total, used)
// PEER_SUPPORT_CLINICAL_CONSULTATIONS (total, used)
// PEER_SUPPORT_IMPLEMENTATION_PROGRAM (sub-services)

export const ORGANIZATION_CONTRACT_SERVICE_TYPES = [
  'PREPAID_THERAPY_SESSIONS',
  'WELLNESS_TRAINING_SESSIONS',
  'PEER_SUPPORT_IMPLEMENTATION_PROGRAM', // make sure to hold all Peer Support services adjacent (e.g. after index 2)
  'PEER_SUPPORT_WORKSHOP',
  'PEER_SUPPORT_CERTIFICATIONS',
  'PEER_SUPPORT_CLINICAL_CONSULTATIONS',
] as const;

export const PEER_SUPPORT_SERVICE_START_INDEX = ORGANIZATION_CONTRACT_SERVICE_TYPES.findIndex((service) =>
  service.includes('PEER_SUPPORT'),
);

export const ORGANIZATION_CONTRACT_SERVICE_TYPES_DISPLAY_LABELS = {
  PREPAID_THERAPY_SESSIONS: 'Prepaid Therapy Sessions',
  WELLNESS_TRAINING_SESSIONS: 'Custom Wellness Topic Training Session(s)',
  PEER_SUPPORT_IMPLEMENTATION_PROGRAM: 'Peer Support Program Implementation Support',
  PEER_SUPPORT_WORKSHOP: 'Peer Support Workshop',
  PEER_SUPPORT_CERTIFICATIONS: 'Peer Support Certifications',
  PEER_SUPPORT_CLINICAL_CONSULTATIONS: 'Peer Support Clinical Consulting',
};

export const ORGANIZATION_CONTRACT_SERVICE_COST_BASIS_UNITS = [
  'PER_SESSION',
  'PER_MEMBER',
  'PER_ATTENDEE',
  'PER_HOUR',
] as const;

export const CONTRACT_SERVICE_WITH_SUB_SERVICES = 'PEER_SUPPORT_IMPLEMENTATION_PROGRAM';

export const PEER_SUPPORT_IMPLEMENTATION_PROGRAM_SUB_SERVICES = [
  'SECURING_BUY_IN_FROM_LEADERSHIP',
  'SELECTING_CANDIDATES_FOR_CERTIFICATION',
  'SELECTING_TEAM_LEAD_OR_ASSISTANT_OR_SECRETARY',
  'DRAFTING_STANDARD_OPERATING_PROCEDURES',
  'INTRODUCE_TO_CLINICAL_CONSULTANT',
] as const;

export const PEER_SUPPORT_IMPLEMENTATION_PROGRAM_SUB_SERVICES_DISPLAY_LABELS = {
  SECURING_BUY_IN_FROM_LEADERSHIP: 'Securing Buy-In From Leadership',
  SELECTING_CANDIDATES_FOR_CERTIFICATION: 'Selecting Candidates for Certification',
  SELECTING_TEAM_LEAD_OR_ASSISTANT_OR_SECRETARY: 'Selecting a Team Lead, an Assistant, and Secretary',
  DRAFTING_STANDARD_OPERATING_PROCEDURES: 'Drafting Standard Operating Procedures',
  INTRODUCE_TO_CLINICAL_CONSULTANT: 'Introduction to Clinical Consultant',
};

export const ORGANIZATION_CONTRACT_HISTORY_ACTION_TYPES = ['ADD_USED'] as const;

export const ORGANIZATION_CONTRACT_SERVICES: {
  type: (typeof ORGANIZATION_CONTRACT_SERVICE_TYPES)[number];
  costBasisUnit: (typeof ORGANIZATION_CONTRACT_SERVICE_COST_BASIS_UNITS)[number];
}[] = [
  { type: 'PREPAID_THERAPY_SESSIONS', costBasisUnit: 'PER_SESSION' },
  { type: 'WELLNESS_TRAINING_SESSIONS', costBasisUnit: 'PER_SESSION' },
  { type: 'PEER_SUPPORT_IMPLEMENTATION_PROGRAM', costBasisUnit: 'PER_ATTENDEE' },
  { type: 'PEER_SUPPORT_WORKSHOP', costBasisUnit: 'PER_ATTENDEE' },
  { type: 'PEER_SUPPORT_CERTIFICATIONS', costBasisUnit: 'PER_ATTENDEE' },
  { type: 'PEER_SUPPORT_CLINICAL_CONSULTATIONS', costBasisUnit: 'PER_SESSION' },
] as const;

export const ORGANIZATION_CONTRACT_SERVICE_COST_BASIS_UNITS_DISPLAY_LABELS = {
  PER_SESSION: 'Per Session',
  PER_MEMBER: 'Per Member',
  PER_ATTENDEE: 'Per Attendee',
  PER_HOUR: 'Per Hour',
};

export const CONTRACT_COLUMNS = [
  'CONTRACT',
  'START DATE',
  'END DATE',
  'LAST UPDATE',
  // 'SIGNED FILE',
  'IT ADMIN',
] as const;

export const CONTRACT_DOCUMENT_TYPES = [
  'AGREEMENT',
  'AMENDMENT',
  'SIGNED_CONTRACT',
  'SCANNED_ORIGINAL',
  'SUPPORTING_DOCUMENT',
  'INVOICE',
  'RECEIPT',
  'OTHER',
] as const;

export const CONTRACT_DOCUMENT_TYPES_DISPLAY_LABELS = {
  AGREEMENT: 'Agreement',
  AMENDMENT: 'Amendment',
  SIGNED_CONTRACT: 'Signed Contract',
  SCANNED_ORIGINAL: 'Scanned Original',
  SUPPORTING_DOCUMENT: 'Supporting Document',
  INVOICE: 'Invoice',
  RECEIPT: 'Receipt',
  OTHER: 'Other',
};
export const DESIGNATIONS = ['LICENSED_THERAPIST', 'PRACTICE_OWNER', 'ADMIN_STAFF', 'OTHER'];
export const SCHEDULE_TOOLS = ['PAPER_BASED', 'GOOGLE_CALENDAR', 'OUTLOOK_CALENDAR', 'OTHER'];
export const APPOINTMENT_TYPES = ['IN_PERSON', 'TELEHEALTH', 'GROUP_THERAPY', 'OTHER'];

export const DESIGNATIONS_DISPLAY_LABELS = {
  LICENSED_THERAPIST: 'Licensed Therapist',
  PRACTICE_OWNER: 'Practice Owner',
  ADMIN_STAFF: 'Admin Staff',
  OTHER: 'Other',
};

export const APPOINTMENT_TYPES_DISPLAY_LABELS = {
  IN_PERSON: 'In Person',
  TELEHEALTH: 'Telehealth',
  GROUP_THERAPY: 'Group Therapy',
  OTHER: 'Other',
};

export const CONTRACT_STATUS_DISPLAY_LABELS = {
  ACTIVE: 'Active',
  EXPIRING_SOON: 'Expiring Soon',
  EXPIRED: 'Expired',
  PENDING: 'Pending',
  ALL: 'All',
};
