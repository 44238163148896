import queryKeys from '@/constants/queryKeys';
import { getContractDocuments } from '@/services/api/requests/contracts';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

const useGetContractDocuments = (contractId: string) => {
  const { isLoading, isSuccess, data, isError, error, isRefetching, refetch } = useQuery({
    queryKey: [queryKeys.contracts.documents],
    queryFn: async () => {
      const response = await getContractDocuments(contractId);
      return response;
    },
    placeholderData: keepPreviousData,
  });

  return { isLoading, isSuccess, data, isError, error, isRefetching, refetch };
};

export default useGetContractDocuments;
