import menuMessages from '@/messages/menu.ts';
import { FileRoutesByTo } from '../../routeTree.gen.ts';

export type TRoutes = keyof FileRoutesByTo;

export type TRoutesWithLabels = { title: string; href: TRoutes }[];

// Admin
export type TAdminRoutes = {
  assessments: TRoutes;
  eula: TRoutes;
  organizations: TRoutes;
  // users: TRoutes;
  memberCare: TRoutes;
  therapists: TRoutes;
  therapistsCreate: TRoutes;
  contracts: TRoutes;
};

export const adminRoutes: TAdminRoutes = {
  assessments: '/assessments',
  eula: '/eula',
  organizations: '/organizations',
  // users: '/',
  memberCare: '/member-care',
  therapists: '/therapists',
  therapistsCreate: '/therapists/create',
  contracts: '/contracts',
};

export const adminRoutesWithLabels: TRoutesWithLabels = [
  // { title: 'Users', href: adminRoutes.users },
  { title: 'Organizations', href: adminRoutes.organizations },
  { title: 'Member Coordinators', href: adminRoutes.memberCare },
  { title: 'Therapists', href: adminRoutes.therapists },
  { title: 'Contracts', href: adminRoutes.contracts },
  // TODO: Uncomment to enable assessments are ready
  { title: 'Assessments', href: adminRoutes.assessments },
  { title: 'EULA', href: adminRoutes.eula },
];

// Member Support
export type TMemberCareRoutes = {
  memberCareMembers: TRoutes;
  memberCareProfile: TRoutes;
  memberCareProfileContact: TRoutes;
  memberCareProfileBio: TRoutes;
}; // memberCareDashboard: TRoutes;

export const memberCareRoutes: TMemberCareRoutes = {
  // memberCareDashboard: '/member-care/dashboard',
  memberCareMembers: '/member-care/members',
  memberCareProfile: '/member-care/profile',
  memberCareProfileContact: '/member-care/profile/contact',
  memberCareProfileBio: '/member-care/profile/bio',
};

export const memberCareRoutesWithLabels: TRoutesWithLabels = [
  // { title: 'M. Care Dashboard', href: memberCareRoutes.memberCareDashboard },
  { title: menuMessages.myMembers, href: memberCareRoutes.memberCareMembers },
];

export const nonAuthRoutes: { signIn: TRoutes } = {
  signIn: '/sign-in',
};

const appRoutes = {
  ...adminRoutes,
  ...memberCareRoutes,
  ...nonAuthRoutes,
};

export default appRoutes;
