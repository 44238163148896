import svgFiles from '@/assets/svg/svgFiles';
import colors from '@/theme/colors';
import { Image, Input, InputGroup, InputLeftElement, InputRightElement, Text, VStack } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { UseFormRegister } from 'react-hook-form';

interface Props {
  title?: string;
  value?: string;
  minValue?: string;
  errorMessage?: string;
  registerFormField: UseFormRegister<any>;
  registerFormFieldName: string;
  width?: string;
  disabled?: boolean;
}

const DateInputRegistered = ({
  title,
  value,
  minValue,
  registerFormField,
  registerFormFieldName,
  errorMessage,
  width = '300px',
  disabled = false,
}: Props) => {
  const dateInputRef = useRef<HTMLInputElement | null>(null);
  const { ref: refCallback, ...registerFormResult } = registerFormField(registerFormFieldName);

  return (
    <VStack width={width} justifyContent={'center'}>
      <InputGroup
        cursor={'pointer'}
        borderColor={!!value ? colors.text.mediumBlue : undefined}
        _hover={{ borderColor: !!value ? colors.text.mediumBlue : undefined }}
        onClick={() => {
          try {
            dateInputRef.current?.showPicker();
          } catch (e) {}
        }}
      >
        <InputLeftElement
          width={'155px'} // to cover the dd.mm.yyyy
          height={'90%'}
          backgroundColor={'white'}
          justifyContent={'flex-start'}
          top={'2px'}
          left={'2px'}
          borderRadius={'5px'}
          display={value ? 'none' : 'flex'}
          onClick={() => {
            try {
              dateInputRef.current?.showPicker();
            } catch (e) {}
          }}
        >
          <Text paddingLeft={'18px'} variant={'urbanistSemiBoldRegular'} color={'text.mediumGray'}>
            {title}
          </Text>
        </InputLeftElement>
        <Input
          type="date"
          disabled={disabled}
          cursor={'pointer'}
          onKeyDown={(e) => e.preventDefault()}
          paddingLeft={value ? '18px' : '0'}
          {...registerFormResult}
          ref={(e) => {
            refCallback(e);
            dateInputRef.current = e;
          }}
          min={minValue}
          className="custom-input"
        />
        <InputRightElement
          position={'absolute'}
          top={'6px'}
          right={'6px'}
          backgroundColor={disabled ? 'background.lightGray' : 'white'}
          width={'55px'}
          paddingLeft={'10px'}
          cursor={disabled ? 'not-allowed' : 'pointer'}
        >
          <Image src={svgFiles.calendarGray} />
        </InputRightElement>
      </InputGroup>

      {errorMessage && <Text variant={'error'}>{errorMessage}</Text>}
    </VStack>
  );
};

export default DateInputRegistered;
