import { svgIconPaths } from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import TabButtons from '@/components/atoms/TabButtons';
import SidebarItemViewTemplate from '@/components/templates/SidebarItemViewTemplate';
import appRoutes from '@/constants/routes/app';
import { useOrganizationById } from '@/hooks/organization/organization';
import OrganizationContractsTab from '@/modules/organization/tabs/contracts/Contracts';
import OrganizationCoordinatorsTab from '@/modules/organization/tabs/Coordinators';
import OrganizationDataTab from '@/modules/organization/tabs/Data';
import OrganizationInfoTab from '@/modules/organization/tabs/Info';
import OrganizationRosterTab from '@/modules/organization/tabs/Roster';
import colors from '@/theme/colors';
import { HStack, VStack } from '@chakra-ui/react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useState } from 'react';

type TAB_OPTIONS = 'info' | 'data' | 'roster' | 'coordinators' | 'contracts';

const OrganizationPage = () => {
  const { organizationId } = Route.useParams();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<TAB_OPTIONS>('info');

  const { isLoading, data: organizationData } = useOrganizationById(organizationId);

  const onTabClick = (tab: TAB_OPTIONS) => {
    setActiveTab(tab);
  };

  return (
    <SidebarItemViewTemplate title={'Organization'}>
      <HStack justify={'flex-start'} width={'100%'} marginTop={'20px'}>
        <CustomButton
          isTransparent
          iconBeforeLabelSvgPath={svgIconPaths.leftArrow}
          labelColor={colors.background.blueGray}
          labelHoverColor="extra.black"
          label="Back to Organizations"
          hoverBackgroundColor="transparent"
          borderHoverColor="transparent"
          onClick={() => navigate({ to: appRoutes.organizations })}
          labelStyle={{ marginBottom: '5px', fontSize: '16px' }}
        />
      </HStack>
      <VStack flex={1} width={'100%'}>
        <TabButtons
          options={[
            {
              onClick: () => onTabClick('info'),
              labelValue: 'info',
              labelDisplay: 'Info',
            },
            {
              onClick: () => onTabClick('data'),
              labelValue: 'data',
              labelDisplay: 'Data',
            },
            {
              onClick: () => onTabClick('roster'),
              labelValue: 'roster',
              labelDisplay: 'Roster',
            },
            {
              onClick: () => onTabClick('coordinators'),
              labelValue: 'coordinators',
              labelDisplay: 'Coordinators',
            },
            {
              onClick: () => onTabClick('contracts'),
              labelValue: 'contracts',
              labelDisplay: 'Contracts',
            },
          ]}
          selectedOptionValue={activeTab}
        />
        <>
          {activeTab === 'info' && <OrganizationInfoTab isLoading={isLoading} organizationData={organizationData} />}
          {activeTab === 'data' && <OrganizationDataTab isLoading={isLoading} organizationData={organizationData} />}
          {activeTab === 'roster' && <OrganizationRosterTab organizationId={organizationId} />}
          {activeTab === 'coordinators' && <OrganizationCoordinatorsTab organizationId={organizationId} />}
          {activeTab === 'contracts' && <OrganizationContractsTab organizationId={organizationId} />}
        </>
      </VStack>
    </SidebarItemViewTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_admin/organizations/$organizationId')({
  component: OrganizationPage,
});
