import CustomButton from '@/components/atoms/CustomButton';
import LoadingLogo from '@/components/atoms/LoadingLogo';
import useOrganizationActiveContract from '@/hooks/contracts/useOrganizationActiveContract';
import { Center, HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import CreateContractModal from './CreateContractModal';
import ContractsTable from '../../ContractsTable';
import CustomTable from '@/components/molecules/CustomTable';
import { CONTRACT_COLUMNS } from '@/constants/stringVars';
import ContractServicesTable from './ContractServicesTable';
import { formatDate } from 'date-fns';
import SvgIcon from '@/components/atoms/SvgIcon';
import { svgIconPaths } from '@/assets/svg/icons';
import colors from '@/theme/colors';
import EditContractModal from './EditContractModal';
import ContractFilesTable from './ContractFilesTable';

type Props = {
  organizationId: string;
};

const OrganizationContractsTab = ({ organizationId }: Props) => {
  const {
    data: activeContract,
    isLoading,
    isError,
    refetch: refetchActiveContract,
    isRefetching,
  } = useOrganizationActiveContract(organizationId);

  const [isCreateContractModalVisible, setIsCreateContractModalVisible] = useState(false);

  const [editContractModalVisible, setEditContractModalVisible] = useState(false);

  const activeContractSingleRowListItems = useMemo(
    () =>
      activeContract
        ? [
            {
              id: activeContract.id,
              cells: [
                activeContract.name ?? '',
                formatDate(new Date(activeContract.startDate), 'MM/dd/yyyy'),
                formatDate(new Date(activeContract.endDate), 'MM/dd/yyyy'),
                formatDate(new Date(activeContract.updatedAt), 'MM/dd/yyyy'),
                // <Text
                //   variant={'urbanistMedium16'}
                //   cursor={'pointer'}
                //   paddingLeft={'1px'}
                //   _hover={{ fontWeight: 700, paddingLeft: '0' }}
                //   onClick={() => setContractToViewSignedFileFor(data)}
                // >
                //   View File <span style={{ fontSize: '12px', marginLeft: '4px' }}> ➔</span>
                // </Text>,
                <HStack>
                  <Text variant={'urbanistMedium16'}>{activeContract.adminName}</Text>

                  <Tooltip
                    label={
                      <VStack>
                        <HStack justify={'start'} width={'100%'} gap={'4px'}>
                          <Text variant={'urbanistBoldRegular'} color={'text.mediumBlue'}>
                            Phone Number:
                          </Text>
                          <Text variant={'urbanistMediumRegular'} color={'text.mediumBlue'}>
                            {activeContract.phone}
                          </Text>
                        </HStack>
                        <HStack justify={'start'} width={'100%'} gap={'4px'}>
                          <Text variant={'urbanistBoldRegular'} color={'text.mediumBlue'}>
                            Email:
                          </Text>
                          <Text variant={'urbanistMediumRegular'} color={'text.mediumBlue'}>
                            {activeContract.adminEmail}
                          </Text>
                        </HStack>
                      </VStack>
                    }
                    padding={'14px'}
                    borderRadius={'6px'}
                    color={'text.darkBlue'}
                    backgroundColor={'extra.white'}
                    boxShadow="0px 7px 35px 0px #00417930"
                    placement="bottom-start"
                  >
                    <VStack>
                      <SvgIcon
                        iconPath={svgIconPaths.infoCircle}
                        color={colors.background.gray}
                        hoveredColor={colors.text.mediumGray}
                      />
                    </VStack>
                  </Tooltip>
                </HStack>,
              ],
            },
          ]
        : [],
    [activeContract],
  );

  if (isLoading || isRefetching) {
    return (
      <Center marginY={'100px'}>
        <LoadingLogo />
      </Center>
    );
  }

  return (
    <VStack width={'100%'}>
      {isError && (
        <VStack
          width={'400px'}
          backgroundColor={'white'}
          borderRadius={'10px'}
          boxShadow={'0px 10px 80px 0px #00417926'}
          padding={'40px'}
          gap={'40px'}
          marginY={'100px'}
        >
          <Text variant={'urbanistSemiBold'} color={'text.mediumBlue'}>
            This organization doesn’t have an active contract yet. Click below to add one.
          </Text>

          <CustomButton
            label={'Create a Contract'}
            width={'200px'}
            labelColor="extra.white"
            labelHoverColor="extra.white"
            labelStyle={{ fontWeight: 900 }}
            onClick={() => setIsCreateContractModalVisible(true)}
          />
        </VStack>
      )}

      {activeContract && (
        <VStack marginY={'20px'} width={'100%'} gap={'20px'}>
          <Text variant={'urbanistMedium18'}>Active Contract Info</Text>
          <CustomTable
            minTableHeight="150px"
            paddingBottom="30px"
            columns={CONTRACT_COLUMNS}
            dataWithId={[activeContract]}
            isLoading={false}
            isRefetching={false}
            customColumnGridPercentages={[25, 19, 19, 19, 18]}
            tableListItems={activeContractSingleRowListItems}
            marginBottom="0"
          />

          <CustomButton
            label="Edit Contract"
            onClick={() => setEditContractModalVisible(true)}
            style={{ marginBottom: '20px' }}
          />

          <ContractServicesTable contract={activeContract} refetchActiveContract={refetchActiveContract} />

          <ContractFilesTable contractId={activeContract.id} />

          <EditContractModal
            organizationId={organizationId}
            contract={activeContract}
            onEditSuccessful={refetchActiveContract}
            isOpen={editContractModalVisible}
            onClose={() => setEditContractModalVisible(false)}
          />
        </VStack>
      )}

      <ContractsTable organizationId={organizationId} />

      <CreateContractModal
        organizationId={organizationId}
        isOpen={isCreateContractModalVisible}
        onClose={() => setIsCreateContractModalVisible(false)}
        onCreationSuccessful={refetchActiveContract}
      />
    </VStack>
  );
};

export default OrganizationContractsTab;
