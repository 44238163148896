import { TTherapistApi, TTherapistFilterOption, TTherapistForm } from '@/types/Therapist.types';
import { api, TCustomAxiosPaginationResponse } from '..';
import { MIN_CHARS_TO_DO_SEARCH } from '@/constants/numberVars';
import { label4BEMapper } from '@/utils/mappers/label';
import { APPOINTMENT_TYPES_DISPLAY_LABELS, DESIGNATIONS_DISPLAY_LABELS } from '@/constants/stringVars';

const root = 'v1/therapists';

const endpoints = {
  therapists: root,
};

export const getTherapists = async (
  page: number,
  filterOption: TTherapistFilterOption,
  filterText: string,
  status: 'active' | 'inactive',
): Promise<TCustomAxiosPaginationResponse<TTherapistApi[]>> => {
  const params: { [key: string]: string | number } = {
    limit: 10,
    page,
  };

  if (filterText && filterText?.length >= MIN_CHARS_TO_DO_SEARCH) {
    const filterOptionBE = label4BEMapper(filterOption);
    const displayLabels =
      filterOption === 'Appointment Type' ? APPOINTMENT_TYPES_DISPLAY_LABELS : DESIGNATIONS_DISPLAY_LABELS;

    const filterTextBE = Object.entries(displayLabels).find((entry) => entry[1] === filterText)![0];
    params[filterOptionBE] = filterTextBE;
  }

  const isActive = status === 'active' ? true : false;
  const endpoint = isActive ? endpoints.therapists : `${endpoints.therapists}/inactive`;

  const { data } = await api.get<TCustomAxiosPaginationResponse<TTherapistApi[]>>(endpoint, {
    params,
  });

  return data;
};

export const postTherapist = async ({ form, photo }: { form: TTherapistForm; photo: File | null }) => {
  const formData = new FormData();

  Object.entries(form).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => formData.append(`${key}[]`, item));
    } else {
      formData.append(key, typeof value === 'boolean' ? value.toString() : value);
    }
  });

  if (photo) {
    formData.append('profilePhoto', new File([photo], photo.name));
  }

  const response = await api.post(endpoints.therapists, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response.data;
};

export const getTherapistById = async (id: string): Promise<TTherapistApi> => {
  const { data } = await api.get(`${endpoints.therapists}/${id}`);

  const therapist = data.data as TTherapistApi;

  return therapist;
};

export const patchTherapist = async ({ form, photo, id }: { form: TTherapistApi; photo: File | null; id: string }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { profile_photo, _id, created_at, updated_at, ...restForm } = form;

  const formData = new FormData();

  Object.entries(restForm).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => formData.append(`${key}[]`, item));
    } else {
      formData.append(key, typeof value === 'boolean' ? value.toString() : value);
    }
  });

  if (photo) {
    formData.append('profilePhoto', new File([photo], photo.name));
  }

  const response = await api.patch(`${endpoints.therapists}/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response.data;
};

export const patchChangeTherapistStatus = async ({ id, isActive }: { id: string; isActive: boolean }) => {
  const response = await api.patch(`${endpoints.therapists}/${id}`, { isActive });
  return response.data;
};
