import logoVariants from '@/assets/img/logo';
import iconsPng from '@/assets/img/png/icons';
import { svgIconPaths } from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import LoadingLogo from '@/components/atoms/LoadingLogo';
import { OBSERVATIONS_NOTES_WIDTH } from '@/constants/dimensions';
import { REFETCH_MILISECONDS } from '@/constants/numberVars';
import {
  useCreateMemberCareMemberNote,
  useDeleteMemberNote,
  useEditMemberNote,
  useMemberCareMemberNotes,
} from '@/hooks/member-care/memberCare';
import colors from '@/theme/colors';
import { TMemberCareMemberNote } from '@/types/MemberCare.types';
import { Center, HStack, Image, Text, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import MemberCareMemberObservationNote from './MemberCareMemberObservationNote';
import ConfirmationModal from '@/components/molecules/ConfirmationModal';
import AddMemberObservationNoteModal from './AddMemberObservationNoteModal';
import EditMemberObservationNoteModal from './EditMemberObservationNoteModal';
import { useAppStore } from '@/store/useAppStore';

interface Props {
  memberId: string;
  memberFirstName: string;
  onNotesUpdate: () => void;
}

const MemberCareMemberNotes = ({ memberId, memberFirstName, onNotesUpdate }: Props) => {
  const { user: loggedInMemberCare } = useAppStore();

  const [addNoteModalOpened, setAddNoteModalOpened] = useState(false);

  const [noteToDelete, setNoteToDelete] = useState<TMemberCareMemberNote | null>(null);
  const [noteToEdit, setNoteToEdit] = useState<TMemberCareMemberNote | null>(null);

  const {
    data: memberNotes,
    isLoading: isLoadingMemberNotes,
    isRefetching,
    refetch,
  } = useMemberCareMemberNotes(memberId);

  const refetchAfterUpdate = () =>
    setTimeout(() => {
      refetch();
      onNotesUpdate();
    }, REFETCH_MILISECONDS);

  const { mutate: deleteMemberNote, isPending: isNoteDeletionPending } = useDeleteMemberNote(refetchAfterUpdate);

  const deleteSelectedNote = async () => {
    if (noteToDelete) {
      await deleteMemberNote(noteToDelete._id);
      setNoteToDelete(null);
    }
  };
  const { mutate: createMemberNoteMutation, isPending: isAddNotePending } =
    useCreateMemberCareMemberNote(refetchAfterUpdate);
  const createMemberNote = ({ content, memberId }: { content: string; memberId: string }) => {
    createMemberNoteMutation({ content, createdFor: memberId, type: 'MEMBERCARE_TO_USER' });
  };

  const { mutate: editMemberNoteMutation, isPending: isEditNotePending } = useEditMemberNote(refetchAfterUpdate);

  const editMemberNote = ({ content, entityId }: { content: string; entityId: string }) => {
    editMemberNoteMutation({ newContent: content, noteId: entityId });
  };

  const isLoading = isLoadingMemberNotes || isRefetching || !memberNotes;

  return (
    <VStack>
      <HStack width={OBSERVATIONS_NOTES_WIDTH} justifyContent={'space-between'}>
        <Text variant={'loraSmallTitle'}>Notes Visible to {memberFirstName}</Text>
        <CustomButton
          isTransparent
          borderHoverColor="transparent"
          label="Add Note"
          onClick={() => setAddNoteModalOpened(true)}
          iconBeforeLabelSvgPath={svgIconPaths.plus}
          iconBeforeLabelSvgSize={16}
          labelColor="text.mediumBlue"
          iconBeforeLabelSvgColor={colors.text.mediumGray}
          labelHoverColor="text.darkBlue"
          style={{ paddingRight: 0 }}
        />
      </HStack>

      <VStack
        width={OBSERVATIONS_NOTES_WIDTH}
        height={OBSERVATIONS_NOTES_WIDTH}
        padding={'20px'}
        backgroundColor={'extra.white'}
        boxShadow={'0px 0px 20px 0px #00417926'}
        borderRadius={'12px'}
        position={'relative'}
      >
        {isLoading && (
          <Center
            height={'100%'}
            width={'100%'}
            position={'absolute'}
            backgroundColor={'rgba(255, 255, 255, 0.7)'}
            top={0}
            borderRadius={'12px'}
          >
            <LoadingLogo logoVariant={logoVariants.whiteNoText160x160} logoHeight="90px" logoWidth="90px" />
          </Center>
        )}

        {memberNotes?.length === 0 && (
          <Center height={'100%'} width={'100%'}>
            <VStack>
              <Image src={iconsPng.personReading} width={120} height={120} alt="no-notes" />
              <Text
                variant={'urbanistSemiBold'}
                fontWeight={500}
                color={'text.mediumGray'}
                marginTop={'20px'}
                width={'425px'}
              >
                These notes are <b style={{ color: colors.text.darkBlue }}>visible</b> to the client and should be used
                when you want to remind them of something or give suggestions.
              </Text>
            </VStack>
          </Center>
        )}

        <VStack overflowY={'scroll'} padding={'20px'} gap={'20px'} width={'100%'}>
          {memberNotes?.map((note) => (
            <MemberCareMemberObservationNote<TMemberCareMemberNote>
              key={note._id}
              entity={note}
              entityName="NOTE"
              createdBy={
                note.createdBy?.firstName ? `${note.createdBy?.firstName} ${note.createdBy?.lastName}` : undefined
              }
              canEdit={note.createdBy.id === loggedInMemberCare?.user_id}
              onClickEdit={setNoteToEdit}
              onClickDelete={setNoteToDelete}
            />
          ))}
        </VStack>

        <ConfirmationModal
          isOpen={!!noteToDelete}
          onCancel={() => setNoteToDelete(null)}
          title="Confirm Deletion"
          confirmButtonText="Delete"
          description={`This will delete the note: \n${noteToDelete?.content}`}
          onConfirm={deleteSelectedNote}
          confirmButtonColor="extra.red"
          confirmLoading={isNoteDeletionPending}
        />
      </VStack>

      <AddMemberObservationNoteModal
        title="Add Note"
        description="Notes are visible to clients."
        inputPlaceholder="Write your note here..."
        addObservationModalOpened={addNoteModalOpened}
        setAddObservationModalOpened={setAddNoteModalOpened}
        memberId={memberId}
        createMutation={createMemberNote}
        isCreationPending={isAddNotePending}
      />

      <EditMemberObservationNoteModal<TMemberCareMemberNote>
        title="Edit Note"
        description="Notes are visible to clients."
        inputPlaceholder="Write your note here..."
        editMutation={editMemberNote}
        isEditPending={isEditNotePending}
        entityToEdit={noteToEdit}
        setEntityToEdit={setNoteToEdit}
      />
    </VStack>
  );
};

export default MemberCareMemberNotes;
