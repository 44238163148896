import { UseMutateFunction, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { usePagination } from '../usePagination';
import { TOrganization, TOrganizationAdminListItem, TOrganizationSuperAdminExtended } from '@/types/Organization.types';
import { TCustomAxiosError } from '@/services/api';
import useToast from '../useToast';
import {
  organizationByIdGet,
  inviteOrganizationAdminPost,
  getAllOrganizations,
  createOrganizationPost,
  getOrganizationRoster,
  reinviteUserPost,
} from '@/services/api/requests/organization';
import queryKeys from '@/constants/queryKeys';
import { TSortingParams } from '@/types';
import { TUser } from '@/types/User.types';

const useCreateOrganization = (
  onSuccess?: (organization: TOrganization) => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<TOrganization, TCustomAxiosError, { name: string }, unknown>;
} => {
  const toast = useToast();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({ name }: { name: string }) => {
      const response = await createOrganizationPost({
        name,
      });
      return response;
    },
    onSuccess: (organization) => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Organization successfully created.',
      });

      onSuccess?.(organization);
    },
    onError: (error: TCustomAxiosError) => {
      toast(
        {
          status: 'error',
          title: 'Creating organization error',
          description: error?.errors[0]?.message,
        },
        error,
      );
    },
  });

  return { mutate, isPending, isError };
};

const useOrganizations = ({ searchText, sortingColumn, sortingDirection }: TSortingParams & { searchText: string }) => {
  return usePagination<TOrganizationAdminListItem[], { searchText: string }>({
    query: getAllOrganizations,
    queryKey: queryKeys.organization.allOrganizations,
    sortingColumn,
    sortingDirection,
    queryParams: {
      searchText,
    },
  });
};

const useOrganizationRoster = ({
  searchText,
  id,
  activeUsers,
}: TSortingParams & { searchText: string; id: string; activeUsers: boolean }) => {
  return usePagination<TUser[], { searchText: string }>({
    query: (page, queryParams) => getOrganizationRoster(id, page, activeUsers, queryParams),
    queryKey: [queryKeys.organization.organizationRoster, id, activeUsers ? 'active' : 'deactivated'],
    queryParams: {
      searchText,
    },
  });
};

const useOrganizationById = (
  organizationId: string,
): {
  isLoading: boolean;
  isSuccess: boolean;
  data: TOrganizationSuperAdminExtended | undefined;
  isError: boolean;
  error: Error | null;
} => {
  const { isLoading, isSuccess, data, isError, error } = useQuery({
    queryKey: [queryKeys.organization.allOrganizations, organizationId],
    queryFn: async () => {
      const response = await organizationByIdGet({
        id: organizationId,
      });
      return response;
    },
  });

  return { isLoading, isSuccess, data, isError, error };
};

const useInviteOrganizationAdmin = (
  organizationId: string,
  onSuccess?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<string, TCustomAxiosError, { firstName: string; lastName: string; email: string }, unknown>;
} => {
  const toast = useToast();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({ firstName, lastName, email }: { firstName: string; lastName: string; email: string }) => {
      const response = await inviteOrganizationAdminPost({
        organizationId,
        firstName,
        lastName,
        email,
      });
      return response;
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Invitation successfully sent!',
      });

      onSuccess?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast(
        {
          status: 'error',
          title: 'Invite error',
          description: error?.errors[0]?.message,
        },
        error,
      );
    },
  });

  return { mutate, isPending, isError };
};

const useReinviteUser = (): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<
    void,
    TCustomAxiosError,
    { userId: string; organizationId: string; queryToInvalidateOnSuccess?: string },
    unknown
  >;
} => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({
      userId,
      organizationId,
    }: {
      userId: string;
      organizationId: string;
      queryToInvalidateOnSuccess?: string;
    }) => {
      await reinviteUserPost(userId, organizationId);
    },
    onSuccess: (_, { queryToInvalidateOnSuccess }) => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'User successfully reinvited.',
      });

      if (queryToInvalidateOnSuccess) {
        queryClient.invalidateQueries({
          queryKey: [queryToInvalidateOnSuccess],
          exact: false,
          stale: true,
          type: 'all',
          refetchType: 'all',
        });
      }
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        status: 'error',
        title: 'User deactivation error',
        description: error?.errors[0]?.message,
      });
    },
  });

  return { mutate, isPending, isError };
};

export {
  useCreateOrganization,
  useOrganizations,
  useOrganizationById,
  useInviteOrganizationAdmin,
  useOrganizationRoster,
  useReinviteUser,
};
