import CustomButton from '@/components/atoms/CustomButton';
import CustomTable from '@/components/molecules/CustomTable';
import useOrganizationContracts from '@/hooks/contracts/useOrganizationContracts';
import { TOrganizationContract } from '@/types/Organization.types';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { addDays, format, isAfter, isBefore } from 'date-fns';
import React, { useMemo, useState } from 'react';
import CreateContractModal from './tabs/contracts/CreateContractModal';

const CONTRACT_STATUSES = ['Active', 'Expiring Soon', 'Expired', 'Pending'] as const;

// Active: Current date is within startDate and endDate.
// Expiring Soon (30 days before end date)
// Expired: endDate is in the past.
// Pending: startDate is in the future.
const getContractStatusFromStartEndDates = (start: string, end: string): (typeof CONTRACT_STATUSES)[number] => {
  const currentDate = new Date();
  const monthFromNow = addDays(new Date(), 30);
  const startDate = new Date(start);
  const endDate = addDays(new Date(end), 1); // need to add one day to endDate, because its hour is only 1am (while the currentDate's hour will usually be later)

  if (isBefore(currentDate, endDate) && isAfter(currentDate, startDate)) {
    return 'Active';
  }

  if (isAfter(monthFromNow, endDate)) {
    return 'Expiring Soon';
  }

  if (isAfter(endDate, currentDate)) {
    return 'Expired';
  }

  return 'Pending';
};

const COLUMNS = ['Contract Name', 'Start Date', 'End Date', 'Status'] as const;
type TSortingColumn = (typeof COLUMNS)[number];

type Props = {
  organizationId: string;
};

const ContractsTable = ({ organizationId }: Props) => {
  const {
    isLoading,
    isRefetching,
    data,
    pagination,
    refetch: refetchContracts,
  } = useOrganizationContracts({
    id: organizationId,
  });

  const [isCreateContractModalVisible, setIsCreateContractModalVisible] = useState(false);

  const contracts = { nodes: data ?? [] };

  const tableListItems = useMemo(
    () =>
      contracts.nodes.map((contract: (typeof contracts.nodes)[number]) => {
        return {
          id: contract.id,
          cells: [
            contract.name ?? 'N/A',
            format(new Date(contract.startDate), 'MMM-dd-yyyy') ?? 'N/A',
            format(new Date(contract.endDate), 'MMM-dd-yyyy') ?? 'N/A',
            getContractStatusFromStartEndDates(contract.startDate, contract.endDate) ?? 'N/A',
          ],
        };
      }),
    [contracts],
  );

  return (
    <VStack width={'100%'}>
      <HStack width={'90%'} justifyContent={'space-between'}>
        <Text variant="loraSmallTitle">Other Contracts</Text>
        <CustomButton
          label="Create a Contract"
          onClick={() => {
            setIsCreateContractModalVisible(true);
          }}
        />
      </HStack>

      <CustomTable<TOrganizationContract[], TSortingColumn>
        isLoading={isLoading}
        dataWithId={contracts.nodes}
        isRefetching={isRefetching}
        pagination={pagination}
        tableListItems={tableListItems}
        isSearching={false}
        noItemsMessage="No Contracts for this Organization"
        columns={COLUMNS}
        marginBottom="20px"
        customColumnGridPercentages={[35, 20, 20, 25]}
        navigateOnRowSelect={false}
      />

      <CreateContractModal
        organizationId={organizationId}
        isOpen={isCreateContractModalVisible}
        onClose={() => setIsCreateContractModalVisible(false)}
        onCreationSuccessful={refetchContracts}
      />
    </VStack>
  );
};

export default ContractsTable;
