import {
  TContractDocument,
  TContractDocumentType,
  TCreateOrganizationContractBE,
  TOrganizationContract,
  TOrganizationContractServiceType,
} from '@/types/Organization.types';
import { api, TCustomAxiosPaginationResponse } from '..';
import { CONTRACTS_PER_PAGE, MIN_CHARS_TO_DO_SEARCH } from '@/constants/numberVars';
import { TContractFilterOption, TContractStatus } from '@/types/Contracts.types';
import { label4BEMapper } from '@/utils/mappers/label';
import { contractStatus4BEMapper } from '@/utils/mappers/contractStatus';
import { TContractAllApiResponse } from '@/types/api/Contract.types';

const root = 'v1/contracts';

const endpoints = {
  contracts: root,
  organizationContracts: `${root}/organization`,
};

export const getContracts = async (
  page: number,
  filterOption: TContractFilterOption,
  filterText: string,
  sortingColumn?: string,
  sortingDirection?: string,
): Promise<TCustomAxiosPaginationResponse<TContractAllApiResponse[]>> => {
  const params: { [key: string]: string | number } = {
    limit: 10,
    page,
  };

  if (filterText && filterText?.length >= MIN_CHARS_TO_DO_SEARCH) {
    const filter = label4BEMapper(filterOption);

    const isStatusFilter = filter === 'status';
    if (isStatusFilter) {
      params[filter] = contractStatus4BEMapper(filterText as TContractStatus);
    }

    if (!isStatusFilter) {
      params[filter] = filterText;
    }
  }

  const sortBy = label4BEMapper(sortingColumn);

  params['sortBy'] = sortBy;
  params['sortOrder'] = sortingDirection || 'asc';

  const { data } = await api.get<TCustomAxiosPaginationResponse<TContractAllApiResponse[]>>(endpoints.contracts, {
    params,
  });

  return data;
};

export const getOrganizationContracts = async (
  orgId: string,
  page: number,
): Promise<TCustomAxiosPaginationResponse<TOrganizationContract[]>> => {
  const params: { limit: number; page: number } = {
    limit: CONTRACTS_PER_PAGE,
    page,
  };

  const { data } = await api.get<TCustomAxiosPaginationResponse<TOrganizationContract[]>>(
    `${endpoints.organizationContracts}/${orgId}`,
    {
      params,
    },
  );

  return data;
};

export const getOrganizationActiveContract = async (orgId: string): Promise<TOrganizationContract> => {
  const { data } = await api.get<{ data: TOrganizationContract }>(`${endpoints.organizationContracts}/${orgId}/active`);

  return data.data;
};

export const createOrganizationContract = async (
  orgId: string,
  newContract: TCreateOrganizationContractBE,
): Promise<TCustomAxiosPaginationResponse<TOrganizationContract>> => {
  const { data } = await api.post<TCustomAxiosPaginationResponse<TOrganizationContract>>(
    `${endpoints.organizationContracts}/${orgId}`,
    newContract,
  );

  return data;
};

export const editContractTotal = async (
  contractId: string,
  editedContractServiceType: TOrganizationContractServiceType,
  changeAmount: number,
): Promise<TCustomAxiosPaginationResponse<TOrganizationContract>> => {
  const { data } = await api.patch<TCustomAxiosPaginationResponse<TOrganizationContract>>(
    `${endpoints.contracts}/${contractId}/total`,
    {
      services: {
        [editedContractServiceType]: changeAmount,
      },
    },
  );

  return data;
};

export const editContractUsed = async (
  contractId: string,
  editedContractServiceType: TOrganizationContractServiceType,
  changeAmount: number,
): Promise<TCustomAxiosPaginationResponse<TOrganizationContract>> => {
  const { data } = await api.patch<TCustomAxiosPaginationResponse<TOrganizationContract>>(
    `${endpoints.contracts}/${contractId}/used`,
    {
      services: {
        [editedContractServiceType]: changeAmount,
      },
    },
  );

  return data;
};

export const updateContract = async (
  contract: TOrganizationContract,
): Promise<TCustomAxiosPaginationResponse<TOrganizationContract>> => {
  const { id, organizationId, history, createdBy, createdAt, updatedBy, updatedAt, ...contractWithoutIds } = contract;
  const { data } = await api.patch<TCustomAxiosPaginationResponse<TOrganizationContract>>(
    `${endpoints.contracts}/${contract.id}`,
    contractWithoutIds,
  );

  return data;
};

export const getContractDocuments = async (contractId: string): Promise<TContractDocument[]> => {
  const { data } = await api.get<{ documents: TContractDocument[] }>(`${endpoints.contracts}/${contractId}/documents`);

  return data.documents;
};

export const uploadContractDocument = async (
  contractId: string,
  documentType: TContractDocumentType,
  newContractDocument: File,
  description?: string,
): Promise<{ success: true }> => {
  const formData = new FormData();
  formData.append('type', documentType);

  formData.append('file', new File([newContractDocument], newContractDocument.name, { type: 'application/pdf' }));

  if (description) {
    formData.append('description', description);
  }

  const { data } = await api.post(`${endpoints.contracts}/${contractId}/documents`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  const resultData = data.data;

  return resultData;
};

export const deleteContractDocument = async (contractId: string, documentKey: string): Promise<{ success: true }> => {
  const { data } = await api.delete(`${endpoints.contracts}/${contractId}/documents`, { data: { key: documentKey } });

  const resultData = data.data;

  return resultData;
};
