import { useMemberCareSpecialistsForOrganizations } from '@/hooks/member-care/memberCare';
import React, { useMemo } from 'react';
import { HStack, Text, VStack } from '@chakra-ui/react';
import CustomTable from '@/components/molecules/CustomTable';
import { TMemberCare } from '@/types/MemberCare.types';
import { useNavigate } from '@tanstack/react-router';
import appRoutes from '@/constants/routes/app';
import CustomButton from '@/components/atoms/CustomButton';
import LoadingLogo from '@/components/atoms/LoadingLogo';

const COLUMNS: string[] = ['First Name', 'Last Name', 'Email', 'Phone'];
type TSortingColumn = (typeof COLUMNS)[number];

type Props = {
  organizationId: string;
};

const OrganizationCoordinatorsTab = ({ organizationId }: Props) => {
  const navigate = useNavigate();

  const {
    isLoading: isLoadingOrgMemberCareSpecialists,
    data: organizationMemberCareSpecialists,
    isRefetching,
    pagination,
  } = useMemberCareSpecialistsForOrganizations(organizationId);

  const organizationMemberCareSpecialistsTableData = organizationMemberCareSpecialists?.map((memberCareSpecialist) => ({
    ...memberCareSpecialist,
    id: memberCareSpecialist.user_id,
  }));

  const data = { nodes: organizationMemberCareSpecialistsTableData ?? [] };

  const tableListItems = useMemo(
    () =>
      data.nodes.map((item: (typeof data.nodes)[number]) => {
        return {
          id: item.user_id,
          cells: [
            item.firstName ?? item.user_metadata?.firstName ?? '',
            item.lastName ?? item.user_metadata?.lastName ?? '',
            item.email ?? item.email ?? '',
            item.phone ?? item.user_metadata?.phone ?? '',
          ],
        };
      }),
    [data.nodes],
  );

  return (
    <VStack flex={1} width={'100%'} paddingTop={'30px'}>
      <HStack width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <HStack flex={1} />
        <Text variant={'loraMediumTitle'}>Member Care</Text>
        <HStack flex={1} justifyContent={'flex-end'}>
          <CustomButton
            labelHoverColor="extra.black"
            label="Manage Coordinators"
            onClick={() => {
              navigate({ to: appRoutes.memberCare });
            }}
          />
        </HStack>
      </HStack>
      {isLoadingOrgMemberCareSpecialists && (
        <VStack flex={1} justifyContent={'center'} alignItems={'center'}>
          <LoadingLogo />
        </VStack>
      )}
      {!isLoadingOrgMemberCareSpecialists && organizationMemberCareSpecialists && data && (
        <CustomTable<TMemberCare[], TSortingColumn>
          isLoading={isLoadingOrgMemberCareSpecialists}
          dataWithId={data.nodes}
          isRefetching={isRefetching}
          pagination={pagination}
          tableListItems={tableListItems}
          isSearching={false}
          noItemsMessage="No Member Care Coordinators"
          columns={COLUMNS}
          tableWidthPercentage="100%"
          marginBottom="20px"
          customBaseUrl={appRoutes.memberCare}
          hasRowSelect
        />
      )}
    </VStack>
  );
};

export default OrganizationCoordinatorsTab;
