import { TCustomAxiosError } from '@/services/api';
import { TOrganizationContract, TOrganizationContractServiceSubService } from '@/types/Organization.types';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import useToast from '../useToast';
import { updateContract } from '@/services/api/requests/contracts';

type TEditContractSubService = {
  contract: TOrganizationContract;
  newSubServices: TOrganizationContractServiceSubService[];
};

const useEditContract = (
  onSuccess?: (newContract: TOrganizationContract) => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<unknown, TCustomAxiosError, TEditContractSubService, unknown>;
} => {
  const toast = useToast();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({ contract, newSubServices }: TEditContractSubService) => {
      const updatedContract = { ...contract };
      const serviceWithSubServicesIndex = contract.services.findIndex(
        (service) => (service.subServices?.length ?? 0) > 0,
      );
      if (serviceWithSubServicesIndex)
        updatedContract.services[serviceWithSubServicesIndex].subServices = newSubServices;

      const response = await updateContract(updatedContract);
      return response.data;
    },
    onSuccess: (editedContract) => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Sub services successfully edited.',
      });

      onSuccess?.(editedContract);
    },
    onError: (error: TCustomAxiosError) => {
      toast(
        {
          status: 'error',
          title: 'Contract editing error',
          description: error?.errors[0]?.message,
        },
        error,
      );
    },
  });

  return { mutate, isPending, isError };
};

export default useEditContract;
