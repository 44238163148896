import { svgIconPaths } from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import LoadingLogo from '@/components/atoms/LoadingLogo';
import ConfirmationModal from '@/components/molecules/ConfirmationModal';
import SidebarItemViewTemplate from '@/components/templates/SidebarItemViewTemplate';
import appRoutes from '@/constants/routes/app';
import useChangeTherapistActivity from '@/hooks/therapists/useChangeTherapistActivity';
import usePatchTherapist from '@/hooks/therapists/usePatchTherapist';
import useTherapistById from '@/hooks/therapists/useTherapistById';
import TherapistForm from '@/modules/therapist/TherapistForm';
import colors from '@/theme/colors';
import { TTherapistApi } from '@/types/Therapist.types';
import { HStack, useDisclosure, VStack } from '@chakra-ui/react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

import React from 'react';

const TherapistProfileView = () => {
  const { therapistId } = Route.useParams();
  const navigate = useNavigate();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { isPending: isSubmitting, mutate } = usePatchTherapist(therapistId);
  const { isLoading, isSuccess, data, isPending, isRefetching } = useTherapistById(therapistId);
  const { isPending: isChangingStatus, mutate: changeStatus } = useChangeTherapistActivity(therapistId);

  const showLoader = isLoading || isPending || isRefetching;

  return (
    <SidebarItemViewTemplate
      title="Therapist Profile"
      buttonLabel={data?.isActive ? 'Deactivate Therapist' : 'Activate Therapist'}
      isButtonDisabled={showLoader}
      buttonColor={data?.isActive ? colors.extra.red : colors.extra.violet}
      onButtonClick={onOpen}
    >
      <HStack justify={'flex-start'} width={'100%'} marginTop={'20px'}>
        <CustomButton
          isTransparent
          iconBeforeLabelSvgPath={svgIconPaths.leftArrow}
          labelColor={colors.background.blueGray}
          labelHoverColor="extra.black"
          label="Back to Therapists"
          hoverBackgroundColor="transparent"
          borderHoverColor="transparent"
          onClick={() => navigate({ to: appRoutes.therapists })}
          labelStyle={{ marginBottom: '5px', fontSize: '16px' }}
        />
      </HStack>
      <VStack flex={1} width={'100%'} justifyContent={'center'} alignItems={'center'}>
        {showLoader && <LoadingLogo />}
        {!showLoader && isSuccess && (
          <TherapistForm
            defaultValues={data}
            submitLabel="Save Changes"
            onSubmit={(form, photo) => mutate({ form: form as TTherapistApi, photo })}
            isPending={isSubmitting}
            isPhotoRequired={false}
          />
        )}
      </VStack>
      <ConfirmationModal
        isOpen={isOpen}
        title={data?.isActive ? 'Deactivate Therapist' : 'Activate Therapist'}
        description={`Are you sure you want to ${data?.isActive ? 'deactivate' : 'activate'} this therapist?`}
        onCancel={onClose}
        onConfirm={() => {
          changeStatus({ isActive: !data?.isActive });
          onClose();
        }}
        confirmLoading={isChangingStatus}
        confirmButtonText={data?.isActive ? 'Deactivate' : 'Activate'}
        confirmButtonColor={data?.isActive ? colors.extra.red : colors.extra.violet}
      />
    </SidebarItemViewTemplate>
  );
};

export default TherapistProfileView;

export const Route = createFileRoute('/_authenticated/_admin/therapists/$therapistId')({
  component: TherapistProfileView,
});
