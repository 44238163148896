import type { CSSProperties, PropsWithChildren } from 'react';

import { HStack, Text, Checkbox } from '@chakra-ui/react';
import { FORM_FIELD_WIDTH } from '@/constants/dimensions';

type TMandatoryCheckboxField = {
  label: string;
};

interface Props<T> {
  checkboxOption: T;
  isChecked: boolean;
  onClick: (_: T) => void;
  isDisabled?: boolean;
  buttonStyle?: CSSProperties;
  noOfLines?: number;
  width?: string;
  maxTextWidth?: string;
  fontSize?: string;
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
  boxShadowStyle?: string;
}

const CheckBoxTile = <T extends TMandatoryCheckboxField>({
  checkboxOption,
  isChecked,
  onClick,
  isDisabled,
  noOfLines,
  children,
  width = FORM_FIELD_WIDTH,
  maxTextWidth = '240px',
  fontSize = '14px',
  backgroundColor = 'extra.white',
  borderColor = 'primary.500',
  textColor = 'primary.500',
  boxShadowStyle = '0px 2px 8px 0px #00417926',
}: PropsWithChildren<Props<T>>) => {
  const noBoxShadow = boxShadowStyle === 'none';

  const onTileClick = (e?: React.MouseEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement>) => {
    if (isDisabled) return;
    e?.preventDefault?.(); // necessary to prevent double-event from clicking the checkbox
    onClick(checkboxOption);
  };

  return (
    <HStack
      background={backgroundColor}
      boxShadow={isChecked ? '' : boxShadowStyle}
      borderRadius={8}
      padding={noBoxShadow ? '0' : '16px'}
      width={width}
      onClick={onTileClick}
      borderWidth={2}
      borderColor={isChecked ? borderColor : 'transparent'}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      _hover={{ boxShadow: isChecked || isDisabled ? '' : noBoxShadow ? '' : '0px 2px 16px 0px #00417926' }}
      gap={0}
    >
      <HStack flex={1} gap={0}>
        <Checkbox
          isChecked={isChecked}
          isDisabled={isDisabled}
          colorScheme="primary"
          backgroundColor={'background.darkGray'}
          borderColor={'background.darkGray'}
          borderRadius={'4px'}
          size={'lg'}
          justifyContent={'center'}
          alignItems={'center'}
        />
        <Text
          fontWeight={600}
          noOfLines={noOfLines}
          maxWidth={maxTextWidth}
          textOverflow={'elipsis'}
          fontSize={fontSize}
          color={isChecked ? textColor : 'text.darkBlue'}
          marginLeft={'20px'}
        >
          {checkboxOption.label}
        </Text>
      </HStack>

      {children}
    </HStack>
  );
};

export default CheckBoxTile;
