import { useState, type CSSProperties } from 'react';

const SvgIcon = ({
  iconPath,
  color,
  hoveredColor,
  boxWidth,
  boxHeight,
  size = '19',
  onClick,
  style,
}: {
  iconPath: string;
  color: string;
  hoveredColor?: string;
  size?: number | string;
  boxWidth?: number | string;
  boxHeight?: number | string;
  onClick?: React.DOMAttributes<HTMLDivElement>['onClick'];
  style?: CSSProperties;
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      style={{ cursor: 'pointer', ...style }}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${boxWidth ?? size} ${boxHeight ?? size}`}
        fill={hovered ? (hoveredColor ?? color) : color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d={iconPath} />
      </svg>
    </div>
  );
};

export default SvgIcon;
