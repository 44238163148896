import { svgIconPaths } from '@/assets/svg/icons';
import SvgIcon from '@/components/atoms/SvgIcon';
import { CONTRACT_SERVICE_WITH_SUB_SERVICES } from '@/constants/stringVars';
import { TContractServiceBE } from '@/types/Organization.types';
import { Popover, PopoverContent, PopoverTrigger, Text, useDisclosure, VStack } from '@chakra-ui/react';
import React from 'react';

type Props = {
  service: TContractServiceBE;
  onEditAmountTotal: () => void;
  onEditAmountUsed: () => void;
  onEditSubServices: () => void;
};

const ContractServicesTableItemPopover = ({
  service,
  onEditAmountTotal,
  onEditAmountUsed,
  onEditSubServices,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const hasSubServices = service.type === CONTRACT_SERVICE_WITH_SUB_SERVICES;

  return (
    <Popover placement={'right'} isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger key={service.type}>
        <VStack cursor={'pointer'}>
          <SvgIcon
            iconPath={svgIconPaths.arrowCircledCheckMark}
            color={'text.mediumBlue'}
            boxHeight={'20'}
            boxWidth={'20'}
          />
        </VStack>
      </PopoverTrigger>

      <PopoverContent
        bg="white"
        border={0}
        paddingY={'14px'}
        borderRadius={'8px'}
        boxShadow={'0px 6px 12px 0px #0E006224'}
        width={hasSubServices ? '150px' : '100px'}
        alignItems={'center'}
        left={5}
      >
        <VStack align={'start'} gap={'14px'}>
          {hasSubServices ? (
            <Text
              variant={'urbanistSemiBold'}
              color={'text.mediumBlue'}
              cursor={'pointer'}
              _hover={{ fontWeight: 700 }}
              onClick={onEditSubServices}
            >
              Edit Sub-Services
            </Text>
          ) : (
            <>
              <Text
                variant={'urbanistSemiBold'}
                color={'text.mediumBlue'}
                cursor={'pointer'}
                _hover={{ fontWeight: 700 }}
                onClick={onEditAmountTotal}
              >
                Edit Total
              </Text>
              <Text
                variant={'urbanistSemiBold'}
                color={'text.mediumBlue'}
                cursor={'pointer'}
                _hover={{ fontWeight: 700 }}
                onClick={onEditAmountUsed}
              >
                Edit Used
              </Text>
            </>
          )}
        </VStack>
      </PopoverContent>
    </Popover>
  );
};

export default ContractServicesTableItemPopover;
