import React, { PropsWithChildren } from 'react';
import CustomButton from '@/components/atoms/CustomButton';
import { HStack, Text, VStack } from '@chakra-ui/react';

interface Props extends PropsWithChildren {
  title: string;
  buttonLabel?: string;
  buttonColor?: string;
  onButtonClick?: () => void;
  customButton?: JSX.Element;
  isButtonDisabled?: boolean;
}

const SidebarItemViewTemplate = ({
  children,
  title,
  buttonLabel,
  buttonColor,
  onButtonClick,
  customButton,
  isButtonDisabled = false,
}: Props) => {
  return (
    <VStack width={'100%'}>
      <HStack justifyContent={'space-between'} width={'100%'}>
        <Text variant="loraTitle">{title}</Text>
        {!!buttonLabel && (
          <CustomButton
            disabled={isButtonDisabled}
            label={buttonLabel}
            onClick={onButtonClick}
            backgroundColor={buttonColor}
          />
        )}
        {customButton}
      </HStack>
      {children}
    </VStack>
  );
};

export default SidebarItemViewTemplate;
