import { TOrganizationContract } from '@/types/Organization.types';
import { usePagination } from '../usePagination';
import { getOrganizationContracts } from '@/services/api/requests/contracts';
import queryKeys from '@/constants/queryKeys';

const useOrganizationContracts = ({ id }: { id: string }) => {
  return usePagination<TOrganizationContract[], { searchText: string }>({
    query: (page) => getOrganizationContracts(id, page),
    queryKey: [queryKeys.organization.organizationContracts, id],
  });
};

export default useOrganizationContracts;
