import { TCustomAxiosError } from '@/services/api';
import { createOrganizationContract } from '@/services/api/requests/contracts';
import { TCreateOrganizationContractBE, TOrganizationContract } from '@/types/Organization.types';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import useToast from '../useToast';

const useCreateOrganizationContract = (
  onSuccess?: (newContract: TOrganizationContract) => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<
    TOrganizationContract,
    TCustomAxiosError,
    { organizationId: string; newContract: TCreateOrganizationContractBE },
    unknown
  >;
  error: TCustomAxiosError | null;
} => {
  const toast = useToast();

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: async ({
      organizationId,
      newContract,
    }: {
      organizationId: string;
      newContract: TCreateOrganizationContractBE;
    }) => {
      const response = await createOrganizationContract(organizationId, newContract);
      return response.data;
    },
    onSuccess: (newContract) => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Organization Contract successfully created.',
      });

      onSuccess?.(newContract);
    },
    onError: (error: TCustomAxiosError) => {
      toast(
        {
          status: 'error',
          title: 'Contract creation error',
          description: error?.errors[0]?.message,
        },
        error,
      );
    },
  });

  return { mutate, isPending, isError, error };
};

export default useCreateOrganizationContract;
