import { TCustomAxiosError } from '@/services/api';
import { TOrganizationContract } from '@/types/Organization.types';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import useToast from '../useToast';
import { updateContract } from '@/services/api/requests/contracts';

const useEditEntireContract = (
  onSuccess?: (newContract: TOrganizationContract) => void,
): {
  mutate: UseMutateFunction<unknown, TCustomAxiosError, { contract: TOrganizationContract }, unknown>;
  isPending: boolean;
  isError: boolean;
  error: TCustomAxiosError | null;
} => {
  const toast = useToast();

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: async ({ contract }: { contract: TOrganizationContract }) => {
      const response = await updateContract(contract);
      return response.data;
    },
    onSuccess: (editedContract) => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Contract successfully edited.',
      });

      onSuccess?.(editedContract);
    },
    onError: (error: TCustomAxiosError) => {
      toast(
        {
          status: 'error',
          title: 'Contract editing error',
          description: error?.errors[0]?.message,
        },
        error,
      );
    },
  });

  return { mutate, isPending, isError, error };
};

export default useEditEntireContract;
