import { TTherapistApi, TTherapistFilterOption } from '@/types/Therapist.types';
import { usePaginatedSearch } from '../usePaginatedSearch';
import { getTherapists } from '@/services/api/requests/therapists';
import queryKeys from '@/constants/queryKeys';

const useTherapists = ({
  filterOption,
  filterText,
  status,
}: {
  filterOption?: TTherapistFilterOption;
  filterText: string;
  status: 'active' | 'inactive';
}) => {
  return usePaginatedSearch<TTherapistApi[], TTherapistFilterOption, string>({
    query: (page, searchFilterType, searchFilterText) =>
      getTherapists(page, searchFilterType, searchFilterText, status),
    queryKey: [queryKeys.therapists.all, status],
    searchFilterType: filterOption ?? 'Name',
    searchFilterText: filterText,
  });
};

export default useTherapists;
