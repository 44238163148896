export const DESIGNATIONS = ['LICENSED_THERAPIST', 'PRACTICE_OWNER', 'ADMIN_STAFF', 'OTHER'];
export const SCHEDULE_TOOLS = ['PAPER_BASED', 'GOOGLE_CALENDAR', 'OUTLOOK_CALENDAR', 'OTHER'];
export const APPOINTMENT_TYPES = ['IN_PERSON', 'TELEHEALTH', 'GROUP_THERAPY', 'OTHER'];

export type TTherapistScheduleTool = (typeof SCHEDULE_TOOLS)[number];
export type TTherapistDesignation = (typeof DESIGNATIONS)[number];
export type TTherapistAppointmentType = (typeof APPOINTMENT_TYPES)[number];

export type TTherapistForm = {
  first_name: string;
  last_name: string;
  email?: string;
  practice_name: string;
  designation: string[];
  custom_designation?: string;
  bio: string;
  schedule_tools: string[];
  custom_schedule_tool?: string;
  ehr_software?: string;
  sync_schedule_interest: boolean;
  appointment_types: string[];
  custom_appointment_type?: string;
  booking_link: string;
  profile_photo?: string;
};

export type TTherapist = {
  first_name: string;
  last_name: string;
  email: string;
  practice_name: string;
  designation: TTherapistDesignation[];
  custom_designation?: string;
  bio: string;
  schedule_tools: TTherapistScheduleTool[];
  custom_schedule_tool?: string;
  ehr_software: string;
  sync_schedule_interest: boolean;
  appointment_types: TTherapistAppointmentType[];
  custom_appointment_type?: string;
  booking_link: string;
  profile_photo: string;
};

export type TTherapistApi = TTherapist & {
  _id: string;
  isActive: boolean;
  created_at: string;
  updated_at: string;
};

export type TTherapistFilterOption = 'Designation' | 'Name' | 'Practice Name' | 'Appointment Type';
