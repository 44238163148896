import { svgIconPaths } from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import SidebarItemViewTemplate from '@/components/templates/SidebarItemViewTemplate';
import appRoutes from '@/constants/routes/app';
import colors from '@/theme/colors';
import { HStack } from '@chakra-ui/react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import useCreateTherapist from '@/hooks/therapists/useCreateTherapist';
import TherapistForm from '@/modules/therapist/TherapistForm';

const TherapistsCreate = () => {
  const navigate = useNavigate();
  const { isPending, mutate } = useCreateTherapist();

  return (
    <SidebarItemViewTemplate title="Create Therapist">
      <HStack justify={'flex-start'} width={'100%'} marginTop={'20px'}>
        <CustomButton
          isTransparent
          iconBeforeLabelSvgPath={svgIconPaths.leftArrow}
          labelColor={colors.background.blueGray}
          labelHoverColor="extra.black"
          label="Back to Therapists"
          hoverBackgroundColor="transparent"
          borderHoverColor="transparent"
          onClick={() => navigate({ to: appRoutes.therapists })}
          labelStyle={{ marginBottom: '5px', fontSize: '16px' }}
        />
      </HStack>
      <TherapistForm isPending={isPending} onSubmit={(form, photo) => mutate({ form, photo })} />
    </SidebarItemViewTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_admin/therapists/create/')({
  component: TherapistsCreate,
});
