import { getHighestUserRoleDisplay, getLastLoginStringDisplay } from '@/utils/stringUtils';
import { TUser, TUserActivationToggle } from '@/types/User.types';

import { TPagination } from '@/services/api';
import LoadingPage from '@/components/templates/LoadingPage';
import CustomTable from '@/components/molecules/CustomTable';
import { useMemo } from 'react';

const COLUMNS = ['First Name', 'Last Name', 'Email', 'Role', 'Organization', 'Last Sign In', 'Actions'] as const;
type TSortingColumn = (typeof COLUMNS)[number];

interface Props {
  users: TUser[] | undefined;
  pagination: TPagination;
  isLoading: boolean;
  isRefetching: boolean;
  ActionElement?: React.FunctionComponent<TUserActivationToggle>;
  invalidatedUserId?: string;
  isSearching?: boolean;
}

const UsersTable = ({
  users,
  pagination,
  isLoading,
  isRefetching,
  ActionElement,
  invalidatedUserId,
  isSearching,
}: Props) => {
  const data = {
    nodes:
      users?.map((user) => ({
        ...user,
        id: user.user_id,
      })) ?? [],
  };

  const tableListItems = useMemo(
    () =>
      data.nodes.map((item: (typeof data.nodes)[number]) => {
        const isInvalidatedUser = item.id === invalidatedUserId;

        return {
          id: item.user_id,
          cells: [
            item.firstName ?? item.user_metadata?.firstName ?? '',
            item.lastName ?? item.user_metadata?.lastName ?? '',
            item.email,
            getHighestUserRoleDisplay(item.roles),
            item.organizationName !== '' ? (item.organizationName ?? 'Org Name') : '/',
            getLastLoginStringDisplay(item.last_login),
            isInvalidatedUser || !ActionElement ? '' : <ActionElement {...item} />,
          ],
        };
      }),
    [data, invalidatedUserId],
  );

  if (isLoading) return <LoadingPage />;

  return (
    <CustomTable<TUser[], TSortingColumn>
      isLoading={isLoading}
      dataWithId={data.nodes}
      isRefetching={isRefetching}
      pagination={pagination}
      tableListItems={tableListItems}
      isSearching={isSearching}
      noItemsMessage="No users"
      columns={COLUMNS}
      invalidatedItemId={invalidatedUserId}
      customColumnGridPercentages={[12, 12, 24, 11, 17, 12, 12]}
      fullWidth
      // hasRowSelect
    />
  );
};

export default UsersTable;
