import iconsPng from '@/assets/img/png/icons';
import { svgIconPaths } from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import LoadingLogo from '@/components/atoms/LoadingLogo';
import ConfirmationModal from '@/components/molecules/ConfirmationModal';
import {
  useCreateMemberCareMemberObservation,
  useDeleteMemberObservation,
  useEditMemberObservation,
  useMemberCareMemberObservations,
} from '@/hooks/member-care/memberCare';
import colors from '@/theme/colors';
import { TMemberCareMemberObservation } from '@/types/MemberCare.types';
import { Center, HStack, Image, Text, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import logoVariants from '@/assets/img/logo';
import { REFETCH_MILISECONDS } from '@/constants/numberVars';
import { OBSERVATIONS_NOTES_WIDTH } from '@/constants/dimensions';
import MemberCareMemberObservationNote from './MemberCareMemberObservationNote';
import AddMemberObservationNoteModal from './AddMemberObservationNoteModal';
import EditMemberObservationNoteModal from './EditMemberObservationNoteModal';
import { useAppStore } from '@/store/useAppStore';

interface Props {
  memberId: string;
  onObservationsUpdate: () => void;
}

const MemberCareMemberObservations = ({ memberId, onObservationsUpdate }: Props) => {
  const { user: loggedInMemberCare } = useAppStore();

  const [addObservationModalOpened, setAddObservationModalOpened] = useState(false);

  const [observationToDelete, setObservationToDelete] = useState<TMemberCareMemberObservation | null>(null);
  const [observationToEdit, setObservationToEdit] = useState<TMemberCareMemberObservation | null>(null);

  const {
    data: memberObservations,
    isLoading: isLoadingMemberObservations,
    isRefetching,
    refetch,
  } = useMemberCareMemberObservations(memberId);

  const refetchAfterUpdate = () =>
    setTimeout(() => {
      refetch();
      onObservationsUpdate();
    }, REFETCH_MILISECONDS);

  const { mutate: deleteMemberObservation, isPending: isObservationDeletionPending } =
    useDeleteMemberObservation(refetchAfterUpdate);

  const deleteSelectedObservation = async () => {
    if (observationToDelete) {
      await deleteMemberObservation(observationToDelete._id);
      setObservationToDelete(null);
    }
  };

  const { mutate: createMemberObservation, isPending: isAddObservationPending } =
    useCreateMemberCareMemberObservation(refetchAfterUpdate);

  const { mutate: editMemberObservationMutation, isPending: isEditObservationPending } =
    useEditMemberObservation(refetchAfterUpdate);

  const editMemberObservation = ({ content, entityId }: { content: string; entityId: string }) => {
    editMemberObservationMutation({ newContent: content, observationId: entityId });
  };

  const isLoading = isLoadingMemberObservations || isRefetching || !memberObservations;

  return (
    <VStack>
      <HStack width={OBSERVATIONS_NOTES_WIDTH} justifyContent={'space-between'}>
        <Text variant={'loraSmallTitle'}>Internal Observations</Text>
        <CustomButton
          isTransparent
          borderHoverColor="transparent"
          label="Add Observation"
          onClick={() => setAddObservationModalOpened(true)}
          iconBeforeLabelSvgPath={svgIconPaths.plus}
          iconBeforeLabelSvgSize={16}
          labelColor="text.mediumBlue"
          iconBeforeLabelSvgColor={colors.text.mediumGray}
          labelHoverColor="text.darkBlue"
          style={{ paddingRight: 0 }}
        />
      </HStack>

      <VStack
        width={OBSERVATIONS_NOTES_WIDTH}
        height={OBSERVATIONS_NOTES_WIDTH}
        padding={'20px'}
        backgroundColor={'extra.white'}
        boxShadow={'0px 0px 20px 0px #00417926'}
        borderRadius={'12px'}
        position={'relative'}
      >
        {isLoading && (
          <Center
            height={'100%'}
            width={'100%'}
            position={'absolute'}
            backgroundColor={'rgba(255, 255, 255, 0.7)'}
            top={0}
            borderRadius={'12px'}
          >
            <LoadingLogo logoVariant={logoVariants.whiteNoText160x160} logoHeight="90px" logoWidth="90px" />
          </Center>
        )}

        {memberObservations?.length === 0 && (
          <Center height={'100%'} width={'100%'}>
            <VStack>
              <Image src={iconsPng.observations} width={120} height={120} alt="no-observations" />
              <Text
                variant={'urbanistSemiBold'}
                fontWeight={500}
                color={'text.mediumGray'}
                marginTop={'20px'}
                width={'425px'}
              >
                Internal observations can <b style={{ color: colors.text.darkBlue }}>only</b> be seen by Member Support
                Staff, here you can add your current thoughts, ideas or recommendations that should be implemented.
              </Text>
            </VStack>
          </Center>
        )}

        <VStack overflowY={'scroll'} padding={'20px'} gap={'20px'} width={'100%'}>
          {memberObservations?.map((observation) => (
            <MemberCareMemberObservationNote<TMemberCareMemberObservation>
              key={observation._id}
              entity={observation}
              entityName="OBSERVATION"
              createdBy={
                observation.memberCare?.firstName
                  ? `${observation.memberCare?.firstName} ${observation.memberCare?.lastName}`
                  : undefined
              }
              canEdit={observation.memberCare?.id ? observation.memberCare?.id === loggedInMemberCare?.user_id : false}
              onClickEdit={setObservationToEdit}
              onClickDelete={setObservationToDelete}
            />
          ))}
        </VStack>

        <ConfirmationModal
          isOpen={!!observationToDelete}
          onCancel={() => setObservationToDelete(null)}
          title="Confirm Deletion"
          confirmButtonText="Delete"
          description={`This will delete the observation: \n${observationToDelete?.content}`}
          onConfirm={deleteSelectedObservation}
          confirmButtonColor="extra.red"
          confirmLoading={isObservationDeletionPending}
        />
      </VStack>

      <AddMemberObservationNoteModal
        title="Add Internal Observation"
        description="Internal observations are only visible to Member Support while clients can’t see them."
        inputPlaceholder="Write your observation here..."
        addObservationModalOpened={addObservationModalOpened}
        setAddObservationModalOpened={setAddObservationModalOpened}
        memberId={memberId}
        createMutation={createMemberObservation}
        isCreationPending={isAddObservationPending}
      />

      <EditMemberObservationNoteModal<TMemberCareMemberObservation>
        title="Edit Internal Observation"
        description="Internal observations are only visible to Member Support while clients can’t see them."
        inputPlaceholder="Write your observation here..."
        editMutation={editMemberObservation}
        isEditPending={isEditObservationPending}
        entityToEdit={observationToEdit}
        setEntityToEdit={setObservationToEdit}
      />
    </VStack>
  );
};

export default MemberCareMemberObservations;
