import ConfirmCancelButtons from '@/components/molecules/ConfirmationModal/ConfirmCancelButtons';
import ModalContainer from '@/components/molecules/ModalContainer/ModalContainer';
import { HStack, Input, Text, VStack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  CONTRACT_SERVICE_WITH_SUB_SERVICES,
  EMAIL_REGEX,
  ORGANIZATION_CONTRACT_SERVICES,
} from '@/constants/stringVars';
import { MAX_CHARS_IN_PHONE, MIN_CHARS_IN_PHONE } from '@/constants/numberVars';
import DateInputRegistered from '@/components/atoms/DateInputRegistered';
import { addDays, isBefore } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import colors from '@/theme/colors';
import {
  TContractServiceBE,
  TCreateOrganizationContract,
  TOrganizationContract,
  TOrganizationContractServiceSubService,
  TOrganizationContractServiceType,
} from '@/types/Organization.types';
import ContractServicesCheckboxes from './ContractServicesCheckboxes';
import useEditEntireContract from '@/hooks/contracts/useEditEntireContract';

interface Props {
  organizationId: string;
  contract: TOrganizationContract;
  isOpen: boolean;
  onClose: () => void;
  onEditSuccessful: () => void;
}

const yupSchema = yup.object().shape({
  contractName: yup.string(),
  startDate: yup.string().required('Start Date is required.'),
  endDate: yup.string().required('End Date is required.'),
  itAdminDetails: yup.object().shape({
    name: yup.string().required('IT Admin Name is required'),
    phoneNumber: yup
      .string()
      .required('Phone Number is required')
      .min(MIN_CHARS_IN_PHONE, `Minimum ${MIN_CHARS_IN_PHONE} digits required`)
      .max(MAX_CHARS_IN_PHONE, `Maximum ${MAX_CHARS_IN_PHONE} digits allowed`),
    email: yup.string().required('Email is required').matches(EMAIL_REGEX, 'Wrong email format.'),
  }),
});

const EditContractModal = ({ contract, organizationId, isOpen, onClose, onEditSuccessful }: Props) => {
  const { mutate, isPending, isError, error } = useEditEntireContract(() => {
    onClose();
    onEditSuccessful();
  });

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      contractName: contract.name,
      itAdminDetails: { email: contract.adminEmail, name: contract.adminName, phoneNumber: contract.phone },
    },
  });

  const { handleSubmit, register, formState, watch, setValue, reset } = methods;

  useEffect(() => {
    setValue('startDate', contract.startDate.split('T')[0]);
    setValue('endDate', contract.endDate.split('T')[0]);
  }, [contract]);

  const { isValid, errors, touchedFields } = formState;

  const adminNameValue = watch('itAdminDetails.name');
  const adminEmailValue = watch('itAdminDetails.email');
  const adminPhoneValue = watch('itAdminDetails.phoneNumber');

  const anyAdminDetailsChanged =
    adminNameValue !== contract.adminName ||
    adminEmailValue !== contract.adminEmail ||
    adminPhoneValue !== contract.phone;

  const startDateValue = watch('startDate');
  const endDateValue = watch('endDate');

  const startDateAfterOneDay = startDateValue ? addDays(new Date(startDateValue), 1) : undefined;

  useEffect(() => {
    if (startDateValue && startDateAfterOneDay) {
      const endDate = new Date(endDateValue);
      if (isBefore(endDate, startDateAfterOneDay)) {
        setValue('endDate', startDateAfterOneDay.toISOString().split('T')[0]);
      }
    }
  }, [startDateAfterOneDay, endDateValue]);

  const initialCheckedServices = contract.services.map((service) => service.type);
  const [checkedServices, setCheckedServices] = useState<TOrganizationContractServiceType[]>(initialCheckedServices);

  const initialCheckedSubServices =
    contract.services.find((service) => (service.subServices?.length ?? 0) > 0)?.subServices ?? [];
  const [checkedSubServices, setCheckedSubServices] =
    useState<TOrganizationContractServiceSubService[]>(initialCheckedSubServices);

  const servicesWithoutSubService = contract.services.filter((service) => (service.subServices?.length ?? 0) === 0);
  const initialServicesAmounts = Object.fromEntries(
    servicesWithoutSubService.map((service) => [service.type, (service.total ?? 0)?.toString()]),
  );
  const [servicesAmounts, setServicesAmounts] = useState<Record<string, string>>(initialServicesAmounts);

  const initialUsedServicesAmounts = Object.fromEntries(
    servicesWithoutSubService.map((service) => [service.type, service.used ?? 0]),
  );

  const [shouldDisplayPeerSupportCheckboxes, setShouldDisplayPeerSupportCheckboxes] = useState(
    !!contract.services.find((service) => service.type.includes('PEER_SUPPORT')),
  );

  const [someNewTotalLessThanCurrentlyUsed, setSomeNewTotalLessThanCurrentlyUsed] = useState(false);

  const atLeastOnePeerSupportServicesChecked = checkedServices.some((checkedService) =>
    checkedService.includes('PEER_SUPPORT'),
  );

  const changesAreMade = useMemo(() => {
    const hasServiceWithSubServices = checkedServices.includes(CONTRACT_SERVICE_WITH_SUB_SERVICES);

    const checkedServicesChanged = initialCheckedServices.sort().toString() !== checkedServices.sort().toString();

    const subServicesEdited = initialCheckedSubServices.sort().toString() !== checkedSubServices.sort().toString();

    const checkedServicesWithoutSubService = checkedServices.filter(
      (checkedService) => checkedService !== CONTRACT_SERVICE_WITH_SUB_SERVICES,
    );
    const initiallyCheckedServicesWithoutSubService = initialCheckedServices.filter(
      (checkedService) => checkedService !== CONTRACT_SERVICE_WITH_SUB_SERVICES,
    );

    const allServicesWithoutSubServiceHaveAmount = checkedServicesWithoutSubService.every(
      (checkedService) => servicesAmounts[checkedService] && parseInt(servicesAmounts[checkedService]) > 0,
    );
    const servicesWithoutSubServiceEdited =
      checkedServicesWithoutSubService.sort().toString() !==
      initiallyCheckedServicesWithoutSubService.sort().toString();

    const servicesAmountsEdited =
      Object.entries(initialServicesAmounts).length === Object.entries(servicesAmounts).length
        ? Object.entries(initialServicesAmounts).some((initialEntry) =>
            Object.entries(servicesAmounts).find(
              (entry) => initialEntry[0] === entry[0] && initialEntry[1] !== entry[1],
            ),
          )
        : true;

    if (shouldDisplayPeerSupportCheckboxes && !atLeastOnePeerSupportServicesChecked) {
      return false;
    }

    if (hasServiceWithSubServices) {
      if (checkedServices.length === 1) return checkedSubServices.length > 0 && subServicesEdited;
      return (
        checkedSubServices.length > 0 &&
        allServicesWithoutSubServiceHaveAmount &&
        (servicesWithoutSubServiceEdited ||
          servicesAmountsEdited ||
          subServicesEdited ||
          checkedServicesChanged ||
          anyAdminDetailsChanged)
      );
    } else {
      return (
        checkedServices.length > 0 &&
        allServicesWithoutSubServiceHaveAmount &&
        (servicesWithoutSubServiceEdited || servicesAmountsEdited || checkedServicesChanged || anyAdminDetailsChanged)
      );
    }
  }, [
    isValid,
    checkedServices,
    initialCheckedServices,
    checkedSubServices,
    initialCheckedSubServices,
    servicesAmounts,
    initialServicesAmounts,
    shouldDisplayPeerSupportCheckboxes,
    atLeastOnePeerSupportServicesChecked,
    anyAdminDetailsChanged,
  ]);

  const onCancelContractEditing = () => {
    onClose();
    reset();
    setCheckedServices([]);
    setCheckedSubServices([]);
    setServicesAmounts({});
  };

  const onSubmit = (data: TCreateOrganizationContract) => {
    const servicesForBE: TContractServiceBE[] = checkedServices.map((checkedService) => ({
      addedAt: new Date().toISOString(),
      isEnabled: true,
      subServices: checkedService === CONTRACT_SERVICE_WITH_SUB_SERVICES ? checkedSubServices : undefined,
      total:
        checkedService !== CONTRACT_SERVICE_WITH_SUB_SERVICES ? parseInt(servicesAmounts[checkedService]) : undefined,
      used: contract.services.find((service) => service.type === checkedService)?.used ?? 0,
      type: checkedService,
      costBasisUnit: ORGANIZATION_CONTRACT_SERVICES.find((service) => service.type === checkedService)!.costBasisUnit,
    }));

    const contractBE: TOrganizationContract = {
      ...contract,
      organizationId,
      name: data.contractName && data.contractName.length > 0 ? data.contractName : `Contract ${data.startDate}`,
      adminEmail: data.itAdminDetails.email,
      adminName: data.itAdminDetails.name,
      phone: data.itAdminDetails.phoneNumber,
      endDate: data.endDate,
      startDate: data.startDate,
      services: servicesForBE,
    };

    mutate({ contract: contractBE });
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={onClose}
      title="Edit Contract"
      titleFontSize="24px"
      backgroundColor={'background.lightBlue'}
      width={'820px'}
      contentStyle={{ padding: '20px', paddingBottom: 0 }}
      modalBodyPadding={0}
    >
      <VStack paddingX={'50px'} align={'center'} maxHeight={'70vh'} overflow={'scroll'}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <VStack>
              {/* Contract name and dates */}
              <VStack gap={0}>
                <Text
                  variant={'urbanistSemiBoldRegular'}
                  marginY={'16px'}
                  textAlign={'center'}
                  width={'100%'}
                  color={'text.mediumBlue'}
                >
                  Contract Dates and (optional) Contract Name
                </Text>
                <HStack gap={'20px'} align={'start'}>
                  <Input
                    width={'220px'}
                    className="custom-input"
                    placeholder={'Contract Name (optional)'}
                    _placeholder={{ color: colors.text.mediumGray, fontSize: '14px' }}
                    {...register('contractName')}
                    disabled
                  />

                  <DateInputRegistered
                    title="Start Date"
                    value={startDateValue}
                    registerFormField={register}
                    registerFormFieldName="startDate"
                    errorMessage={errors.startDate?.message}
                    width={'175px'}
                    disabled
                  />

                  <DateInputRegistered
                    title="End Date"
                    value={endDateValue}
                    registerFormField={register}
                    registerFormFieldName="endDate"
                    errorMessage={errors.endDate?.message}
                    width={'175px'}
                    disabled
                  />
                </HStack>
              </VStack>

              {/* IT Admin */}
              <VStack gap={0}>
                <Text
                  variant={'urbanistSemiBoldRegular'}
                  marginY={'16px'}
                  textAlign={'center'}
                  width={'100%'}
                  color={'text.mediumBlue'}
                >
                  IT Admin Details
                </Text>
                <HStack gap={'20px'} align={'start'}>
                  <VStack>
                    <Input
                      {...register('itAdminDetails.name')}
                      className="custom-input"
                      placeholder={'IT Admin Name'}
                      _placeholder={{ color: colors.text.mediumGray, fontSize: '14px' }}
                      data-state={
                        touchedFields.itAdminDetails?.name
                          ? errors.itAdminDetails?.name?.message
                            ? 'invalid'
                            : 'valid'
                          : ''
                      }
                      width={'220px'}
                    />
                    {errors.itAdminDetails?.name && (
                      <Text variant={'error'}>{errors.itAdminDetails?.name.message}</Text>
                    )}
                  </VStack>
                  <VStack>
                    <Input
                      {...register('itAdminDetails.phoneNumber')}
                      className="custom-input"
                      placeholder={'Phone Number'}
                      _placeholder={{ color: colors.text.mediumGray, fontSize: '14px' }}
                      data-state={
                        touchedFields.itAdminDetails?.phoneNumber
                          ? errors.itAdminDetails?.phoneNumber?.message
                            ? 'invalid'
                            : 'valid'
                          : ''
                      }
                      width={'175px'}
                    />
                    {errors.itAdminDetails?.phoneNumber && (
                      <Text variant={'error'}>{errors.itAdminDetails?.phoneNumber.message}</Text>
                    )}
                  </VStack>
                  <VStack>
                    <Input
                      {...register('itAdminDetails.email')}
                      className="custom-input"
                      placeholder={'Email'}
                      _placeholder={{ color: colors.text.mediumGray, fontSize: '14px' }}
                      data-state={
                        touchedFields.itAdminDetails?.email
                          ? errors.itAdminDetails?.email?.message
                            ? 'invalid'
                            : 'valid'
                          : ''
                      }
                      width={'175px'}
                    />
                    {errors.itAdminDetails?.email && (
                      <Text variant={'error'}>{errors.itAdminDetails?.email.message}</Text>
                    )}
                  </VStack>
                </HStack>
              </VStack>

              {/* Services */}
              <ContractServicesCheckboxes
                checkedServices={checkedServices}
                setCheckedServices={setCheckedServices}
                checkedSubServices={checkedSubServices}
                setCheckedSubServices={setCheckedSubServices}
                servicesAmounts={servicesAmounts}
                setServicesAmounts={setServicesAmounts}
                shouldDisplayPeerSupportCheckboxes={shouldDisplayPeerSupportCheckboxes}
                setShouldDisplayPeerSupportCheckboxes={setShouldDisplayPeerSupportCheckboxes}
                usedServicesAmounts={initialUsedServicesAmounts}
                setSomeNewTotalLessThanCurrentlyUsed={setSomeNewTotalLessThanCurrentlyUsed}
              />

              <VStack marginY={'20px'}>
                <ConfirmCancelButtons
                  confirmButtonText={'Save Edits'}
                  onCancel={onCancelContractEditing}
                  confirmButtonColor="secondary.500"
                  confirmLoading={isPending}
                  confirmButtonType="submit"
                  confirmButtonDisabled={!isValid || !changesAreMade || someNewTotalLessThanCurrentlyUsed}
                  minButtonWidth="200px"
                />
                {isError && (
                  <Text variant={'error'} paddingBottom={'20px'} marginLeft={'30px'} width={'450px'} align={'center'}>
                    {error?.errors[0].message}
                  </Text>
                )}
              </VStack>
            </VStack>
          </form>
        </FormProvider>
      </VStack>
    </ModalContainer>
  );
};

export default EditContractModal;
