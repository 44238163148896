import { MIN_CHARS_TO_DO_SEARCH } from '@/constants/numberVars';
import { useOrganizationRoster, useReinviteUser } from '@/hooks/organization/organization';
import { Center, HStack, Input, InputGroup, InputLeftElement, Text, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import UsersTable from '../user/UsersTable';
import TabButtons from '@/components/atoms/TabButtons';
import SvgIcon from '@/components/atoms/SvgIcon';
import { svgIconPaths } from '@/assets/svg/icons';
import colors from '@/theme/colors';
import { useTableColumnSort } from '@/hooks/useTableColumnSort';
import FilterSelectPopover from '@/components/molecules/FilterSelectPopover';
import ConfirmationModal from '@/components/molecules/ConfirmationModal';
import { TUserActivationToggle } from '@/types/User.types';
import CustomButton from '@/components/atoms/CustomButton';
import { useUserActivation, useUserDeactivation } from '@/hooks/user/users';
import queryKeys from '@/constants/queryKeys';
import RosterActionsPopover from '../user/active/RosterActionsPopover';
import { isUndefined } from 'lodash';

type Props = {
  organizationId: string;
};

export type TAdminOrganizationRosterSortingColumn = 'First Name' | 'Last Name' | 'Email' | 'Last Sign In';
const SEARCH_TEXT_INIT = '';

const OrganizationRoster = ({ organizationId }: Props) => {
  const [activeUsers, setActiveUsers] = useState<'activated' | 'deactivated'>('activated');

  const [isReinviteModalOpen, setIsReinviteModalOpen] = useState(false);
  const [isActivationModalOpen, setIsActivationModalOpen] = useState(false);

  const [selectedUser, setSelectedUser] = useState<TUserActivationToggle>();

  const isActiveUsers = activeUsers === 'activated';

  const { sortingColumn, sortingDirection } = useTableColumnSort<TAdminOrganizationRosterSortingColumn>({
    defaultSortingColumn: 'First Name',
  });

  const { mutate: deactivate, isPending: isPendingDeactivation } = useUserDeactivation();
  const { mutate: activate, isPending: isPendingActivation } = useUserActivation();
  const { mutate: reinvite, isPending: isReinviting } = useReinviteUser();

  const isPending = isPendingDeactivation || isPendingActivation;

  const [localSearchText, setLocalSearchText] = useState(SEARCH_TEXT_INIT);
  const [committedSearchText, setCommittedSearchText] = useState(SEARCH_TEXT_INIT);
  const searchIsActive = committedSearchText.length > 0;
  const searchSubmitButtonDisabled = localSearchText.length < MIN_CHARS_TO_DO_SEARCH;

  const submitLocalSearchForm = () => {
    setCommittedSearchText(localSearchText);
    pagination.getFirstPage();
  };

  const resetSearchForm = () => {
    setLocalSearchText(SEARCH_TEXT_INIT);
    setCommittedSearchText(SEARCH_TEXT_INIT);

    pagination.getFirstPage();
  };

  const onToggleActiveUsers = (activeUsers: 'activated' | 'deactivated') => {
    setActiveUsers(activeUsers);
    pagination.getFirstPage();
  };

  const { isLoading, isRefetching, data, pagination, refetch } = useOrganizationRoster({
    id: organizationId,
    activeUsers: activeUsers === 'activated',
    sortingColumn,
    sortingDirection,
    searchText: committedSearchText,
  });

  const onDeactivateUser = () => {
    if (selectedUser) {
      deactivate(
        { userId: selectedUser.id, hasLastLogin: selectedUser.last_login !== '' },
        {
          onSuccess: () => {
            setIsActivationModalOpen(false);
            refetch();
          },
        },
      );
    }
  };
  const onActivateUser = () => {
    if (selectedUser) {
      activate(
        { userId: selectedUser.id },
        {
          onSuccess: () => {
            refetch();
            setIsActivationModalOpen(false);
          },
        },
      );
    }
  };

  const onConfirmReinvite = () => {
    if (selectedUser) {
      reinvite(
        {
          userId: selectedUser.id,
          organizationId: organizationId,
          queryToInvalidateOnSuccess: queryKeys.users.activatedUsers,
        },
        { onSuccess: () => setIsReinviteModalOpen(false) },
      );
    }
  };

  const onActionClick = (user: TUserActivationToggle, isDeactivating: boolean) => {
    setSelectedUser(user);
    if (isDeactivating) {
      setIsActivationModalOpen(true);
      return;
    }
    setIsReinviteModalOpen(true);
  };

  return (
    <VStack width={'100%'}>
      <HStack width={'100%'} justifyContent={'space-between'}>
        <TabButtons
          options={[
            {
              onClick: () => onToggleActiveUsers('activated'),
              labelValue: 'activated',
              labelDisplay: 'Active',
            },
            {
              onClick: () => onToggleActiveUsers('deactivated'),
              labelValue: 'deactivated',
              labelDisplay: 'Deactivated',
            },
          ]}
          selectedOptionValue={activeUsers}
        />
        <FilterSelectPopover
          filterIconPath={svgIconPaths.magnifyingGlass}
          filterIconText="Search"
          contentWidth="260px"
          onFilterSubmit={submitLocalSearchForm}
          onFilterReset={resetSearchForm}
          submitButtonDisabled={searchSubmitButtonDisabled}
          isFilterActive={searchIsActive}
          popoverContent={
            <VStack marginBottom={'24px'} alignItems={'start'} width={'100%'}>
              <Text variant={'urbanistBold'} marginTop={'10px'}>
                Search By Email, First and Last Name
              </Text>

              <InputGroup marginTop={'10px'} flexDir={'column'}>
                <InputLeftElement>
                  <Center height={'100%'} marginTop={'18px'}>
                    <SvgIcon
                      iconPath={svgIconPaths.magnifyingGlass}
                      color={localSearchText.length > 0 ? colors.text.darkBlue : colors.text.mediumGray}
                    />
                  </Center>
                </InputLeftElement>
                <Input
                  className="custom-input"
                  backgroundColor={'white'}
                  placeholder="Search..."
                  _placeholder={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                  value={localSearchText}
                  onChange={(e) => setLocalSearchText(e.target.value)}
                  _disabled={{ opacity: 0.8, cursor: 'not-allowed' }}
                />
                <Text variant={'urbanistSmallBold'} color={'text.mediumGray'} padding={'5px'}>
                  Minimum 3 characters
                </Text>
              </InputGroup>
            </VStack>
          }
        />
      </HStack>
      <UsersTable
        isLoading={isLoading}
        pagination={pagination}
        isRefetching={isRefetching}
        users={data}
        isSearching={false}
        ActionElement={(userToDeactivate: TUserActivationToggle) => (
          <>
            {!isActiveUsers && (
              <CustomButton
                isTransparent
                label={'Activate'}
                hoverBackgroundColor={'primary.500'}
                labelColor={colors.text.mediumBlue}
                labelHoverColor={colors.extra.white}
                iconBeforeLabelSvgPath={svgIconPaths.userDeactivate}
                onClick={() => {
                  setIsActivationModalOpen(true);
                  setSelectedUser(userToDeactivate);
                }}
                width={'120px'}
                height={'35px'}
                noShadow
              />
            )}
            {isActiveUsers && (
              <RosterActionsPopover
                user={userToDeactivate}
                isReinviteHidden={!isUndefined(userToDeactivate?.user_metadata?.sex)}
                onDeactivate={() => onActionClick(userToDeactivate, true)}
                onReinvite={() => onActionClick(userToDeactivate, false)}
              />
            )}
          </>
        )}
      />
      <ConfirmationModal
        isOpen={isActivationModalOpen}
        onCancel={() => {
          setIsActivationModalOpen(false);
          setSelectedUser(undefined);
        }}
        title={isActiveUsers ? 'Confirm Deactivation' : 'Confirm Activation'}
        confirmButtonText={isActiveUsers ? 'Deactivate' : 'Activate'}
        description={`This will ${isActiveUsers ? 'deactivate ' : 'activate'} user ${selectedUser?.firstName} ${selectedUser?.lastName}`}
        onConfirm={isActiveUsers ? onDeactivateUser : onActivateUser}
        confirmButtonColor={isActiveUsers ? 'extra.red' : 'primary.500'}
        confirmLoading={isPending}
      />
      <ConfirmationModal
        isOpen={isReinviteModalOpen}
        onCancel={() => {
          setIsReinviteModalOpen(false);
          setSelectedUser(undefined);
        }}
        title="Confirm Reinvite"
        confirmButtonText="Reinvite"
        description={`This will send reinvite email to the user ${selectedUser?.firstName} ${selectedUser?.lastName}`}
        onConfirm={onConfirmReinvite}
        confirmButtonColor="primary.500"
        confirmLoading={isReinviting}
      />
    </VStack>
  );
};

export default OrganizationRoster;
