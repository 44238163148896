export default {
  Input: {
    defaultProps: {
      size: 'md',
      height: '54px',
      maxWidth: '265px',
      borderRadius: '8px',
      borderColor: 'rgba(0, 65, 121, 0.15)',
      borderWidth: '2px',
      paddingY: '10px',
      paddingX: '20px',
      gap: '10px',
      fontSize: '14px',
      lineHeight: '17px',
      fontWeight: '400',
    },
  },
};
