import ConfirmCancelButtons from '@/components/molecules/ConfirmationModal/ConfirmCancelButtons';
import ModalContainer from '@/components/molecules/ModalContainer/ModalContainer';
import useCreateOrganizationContract from '@/hooks/contracts/useCreateOrganizationContract';
import { HStack, Input, Text, VStack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  CONTRACT_SERVICE_WITH_SUB_SERVICES,
  EMAIL_REGEX,
  ORGANIZATION_CONTRACT_SERVICES,
} from '@/constants/stringVars';
import { MAX_CHARS_IN_PHONE, MIN_CHARS_IN_PHONE } from '@/constants/numberVars';
import DateInputRegistered from '@/components/atoms/DateInputRegistered';
import { addDays, isBefore } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import colors from '@/theme/colors';
import {
  TContractServiceBE,
  TCreateOrganizationContract,
  TCreateOrganizationContractBE,
  TOrganizationContractServiceSubService,
  TOrganizationContractServiceType,
} from '@/types/Organization.types';
import ContractServicesCheckboxes from './ContractServicesCheckboxes';

interface Props {
  organizationId: string;
  isOpen: boolean;
  onClose: () => void;
  onCreationSuccessful: () => void;
}

const yupSchema = yup.object().shape({
  contractName: yup.string(),
  startDate: yup.string().required('Start Date is required.'),
  endDate: yup.string().required('End Date is required.'),
  itAdminDetails: yup.object().shape({
    name: yup.string().required('IT Admin Name is required'),
    phoneNumber: yup
      .string()
      .required('Phone Number is required')
      .min(MIN_CHARS_IN_PHONE, `Minimum ${MIN_CHARS_IN_PHONE} digits required`)
      .max(MAX_CHARS_IN_PHONE, `Maximum ${MAX_CHARS_IN_PHONE} digits allowed`),
    email: yup.string().required('Email is required').matches(EMAIL_REGEX, 'Wrong email format.'),
  }),
});

const CreateContractModal = ({ organizationId, isOpen, onClose, onCreationSuccessful }: Props) => {
  const { mutate, isPending, isError, error } = useCreateOrganizationContract(() => {
    onClose();
    onCreationSuccessful();
  });

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(yupSchema),
    defaultValues: { startDate: '', endDate: '' },
  });

  const { handleSubmit, register, formState, watch, setValue, reset } = methods;

  const { isValid, errors, touchedFields } = formState;

  const startDateValue = watch('startDate');
  const endDateValue = watch('endDate');

  const startDateAfterOneDay = startDateValue ? addDays(new Date(startDateValue), 1) : undefined;

  useEffect(() => {
    if (startDateValue && startDateAfterOneDay) {
      const endDate = new Date(endDateValue);
      if (isBefore(endDate, startDateAfterOneDay)) {
        setValue('endDate', startDateAfterOneDay.toISOString().split('T')[0]);
      }
    }
  }, [startDateAfterOneDay, endDateValue]);

  const [checkedServices, setCheckedServices] = useState<TOrganizationContractServiceType[]>([]);
  const [checkedSubServices, setCheckedSubServices] = useState<TOrganizationContractServiceSubService[]>([]);
  const [servicesAmounts, setServicesAmounts] = useState<Record<string, string>>({});

  const [shouldDisplayPeerSupportCheckboxes, setShouldDisplayPeerSupportCheckboxes] = useState(false);
  const atLeastOnePeerSupportServicesChecked = checkedServices.some((checkedService) =>
    checkedService.includes('PEER_SUPPORT'),
  );

  const isFormValid = useMemo(() => {
    if (isValid) {
      const hasServiceWithSubServices = checkedServices.includes(CONTRACT_SERVICE_WITH_SUB_SERVICES);
      const checkedServicesWithoutSubService = checkedServices.filter(
        (checkedService) => checkedService !== CONTRACT_SERVICE_WITH_SUB_SERVICES,
      );
      const allServicesWithoutSubServiceHaveAmount = checkedServicesWithoutSubService.every(
        (checkedService) => servicesAmounts[checkedService] && parseInt(servicesAmounts[checkedService]) > 0,
      );

      if (shouldDisplayPeerSupportCheckboxes && !atLeastOnePeerSupportServicesChecked) {
        return false;
      }

      if (hasServiceWithSubServices) {
        if (checkedServices.length === 1) return checkedSubServices.length > 0;
        return checkedSubServices.length > 0 && allServicesWithoutSubServiceHaveAmount;
      } else {
        return checkedServices.length > 0 && allServicesWithoutSubServiceHaveAmount;
      }
    }

    return false;
  }, [
    isValid,
    checkedServices,
    checkedSubServices,
    servicesAmounts,
    shouldDisplayPeerSupportCheckboxes,
    atLeastOnePeerSupportServicesChecked,
  ]);

  const onCancelContractCreation = () => {
    onClose();
    reset();
    setCheckedServices([]);
    setCheckedSubServices([]);
    setServicesAmounts({});
  };

  const onSubmit = (data: TCreateOrganizationContract) => {
    const servicesForBE: TContractServiceBE[] = checkedServices.map((checkedService) => ({
      addedAt: new Date().toISOString(),
      isEnabled: true,
      subServices: checkedService === CONTRACT_SERVICE_WITH_SUB_SERVICES ? checkedSubServices : undefined,
      total:
        checkedService !== CONTRACT_SERVICE_WITH_SUB_SERVICES ? parseInt(servicesAmounts[checkedService]) : undefined,
      type: checkedService,
      costBasisUnit: ORGANIZATION_CONTRACT_SERVICES.find((service) => service.type === checkedService)!.costBasisUnit,
    }));

    const contractBE: TCreateOrganizationContractBE = {
      organizationId,
      name: data.contractName && data.contractName.length > 0 ? data.contractName : `Contract ${data.startDate}`,
      adminEmail: data.itAdminDetails.email,
      adminName: data.itAdminDetails.name,
      phone: data.itAdminDetails.phoneNumber,
      endDate: data.endDate,
      startDate: data.startDate,
      services: servicesForBE,
    };

    mutate({ organizationId, newContract: contractBE });
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={onClose}
      title="Create a Contract"
      titleFontSize="24px"
      backgroundColor={'background.lightBlue'}
      width={'820px'}
      contentStyle={{ padding: '20px', paddingBottom: 0 }}
      modalBodyPadding={0}
    >
      <VStack paddingX={'50px'} align={'center'} maxHeight={'70vh'} overflow={'scroll'}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <VStack>
              {/* Contract name and dates */}
              <VStack gap={0}>
                <Text
                  variant={'urbanistSemiBoldRegular'}
                  marginY={'16px'}
                  textAlign={'center'}
                  width={'100%'}
                  color={'text.mediumBlue'}
                >
                  Contract Dates and (optional) Contract Name
                </Text>
                <HStack gap={'20px'} align={'start'}>
                  <Input
                    width={'220px'}
                    className="custom-input"
                    placeholder={'Contract Name (optional)'}
                    _placeholder={{ color: colors.text.mediumGray, fontSize: '14px' }}
                    {...register('contractName')}
                  />

                  <DateInputRegistered
                    title="Start Date"
                    value={startDateValue}
                    registerFormField={register}
                    registerFormFieldName="startDate"
                    errorMessage={errors.startDate?.message}
                    width={'175px'}
                  />

                  <DateInputRegistered
                    title="End Date"
                    value={endDateValue}
                    registerFormField={register}
                    registerFormFieldName="endDate"
                    minValue={startDateAfterOneDay?.toISOString().split('T')[0]}
                    errorMessage={errors.endDate?.message}
                    width={'175px'}
                  />
                </HStack>
              </VStack>

              {/* IT Admin */}
              <VStack gap={0}>
                <Text
                  variant={'urbanistSemiBoldRegular'}
                  marginY={'16px'}
                  textAlign={'center'}
                  width={'100%'}
                  color={'text.mediumBlue'}
                >
                  IT Admin Details
                </Text>
                <HStack gap={'20px'} align={'start'}>
                  <VStack>
                    <Input
                      {...register('itAdminDetails.name')}
                      className="custom-input"
                      placeholder={'IT Admin Name'}
                      _placeholder={{ color: colors.text.mediumGray, fontSize: '14px' }}
                      data-state={
                        touchedFields.itAdminDetails?.name
                          ? errors.itAdminDetails?.name?.message
                            ? 'invalid'
                            : 'valid'
                          : ''
                      }
                      width={'220px'}
                    />
                    {errors.itAdminDetails?.name && (
                      <Text variant={'error'}>{errors.itAdminDetails?.name.message}</Text>
                    )}
                  </VStack>
                  <VStack>
                    <Input
                      {...register('itAdminDetails.phoneNumber')}
                      className="custom-input"
                      placeholder={'Phone Number'}
                      _placeholder={{ color: colors.text.mediumGray, fontSize: '14px' }}
                      data-state={
                        touchedFields.itAdminDetails?.phoneNumber
                          ? errors.itAdminDetails?.phoneNumber?.message
                            ? 'invalid'
                            : 'valid'
                          : ''
                      }
                      width={'175px'}
                    />
                    {errors.itAdminDetails?.phoneNumber && (
                      <Text variant={'error'}>{errors.itAdminDetails?.phoneNumber.message}</Text>
                    )}
                  </VStack>
                  <VStack>
                    <Input
                      {...register('itAdminDetails.email')}
                      className="custom-input"
                      placeholder={'Email'}
                      _placeholder={{ color: colors.text.mediumGray, fontSize: '14px' }}
                      data-state={
                        touchedFields.itAdminDetails?.email
                          ? errors.itAdminDetails?.email?.message
                            ? 'invalid'
                            : 'valid'
                          : ''
                      }
                      width={'175px'}
                    />
                    {errors.itAdminDetails?.email && (
                      <Text variant={'error'}>{errors.itAdminDetails?.email.message}</Text>
                    )}
                  </VStack>
                </HStack>
              </VStack>

              {/* Services */}
              <ContractServicesCheckboxes
                checkedServices={checkedServices}
                setCheckedServices={setCheckedServices}
                checkedSubServices={checkedSubServices}
                setCheckedSubServices={setCheckedSubServices}
                servicesAmounts={servicesAmounts}
                setServicesAmounts={setServicesAmounts}
                shouldDisplayPeerSupportCheckboxes={shouldDisplayPeerSupportCheckboxes}
                setShouldDisplayPeerSupportCheckboxes={setShouldDisplayPeerSupportCheckboxes}
              />

              <VStack marginY={'20px'}>
                <ConfirmCancelButtons
                  confirmButtonText={'Create'}
                  onCancel={onCancelContractCreation}
                  confirmLoading={isPending}
                  confirmButtonType="submit"
                  confirmButtonDisabled={!isFormValid}
                  minButtonWidth="200px"
                />
                {isError && (
                  <Text variant={'error'} paddingBottom={'20px'} marginLeft={'30px'} width={'450px'} align={'center'}>
                    {error?.errors[0].message}
                  </Text>
                )}
              </VStack>
            </VStack>
          </form>
        </FormProvider>
      </VStack>
    </ModalContainer>
  );
};

export default CreateContractModal;
