import ModalContainer from '@/components/molecules/ModalContainer/ModalContainer';
import {
  ORGANIZATION_CONTRACT_SERVICE_COST_BASIS_UNITS_DISPLAY_LABELS,
  ORGANIZATION_CONTRACT_SERVICE_TYPES_DISPLAY_LABELS,
} from '@/constants/stringVars';
import { TContractServiceBE } from '@/types/Organization.types';
import React, { Dispatch, SetStateAction } from 'react';
import ContractServiceEditModalBody from './ContractServiceEditModalBody';
import useEditContractServiceUsed from '@/hooks/contracts/useEditContractServiceUsed';

interface Props {
  contractId: string;
  amountEditingService: TContractServiceBE | undefined;
  setAmountEditingService: Dispatch<SetStateAction<TContractServiceBE | undefined>>;
  onEditSuccessful?: () => void;
}

const ContractServiceEditUsedModal = ({
  contractId,
  amountEditingService,
  setAmountEditingService,
  onEditSuccessful,
}: Props) => {
  const onClose = () => setAmountEditingService(undefined);

  const { mutate, isPending, isError } = useEditContractServiceUsed(() => {
    onClose();
    onEditSuccessful?.();
  });

  const onSubmit = ({ newAmount }: { newAmount: number }) => {
    if (amountEditingService) {
      mutate({
        contractId,
        changeAmount: newAmount,
        editedContractServiceType: amountEditingService.type,
      });
    }
  };

  return (
    <ModalContainer isOpen={!!amountEditingService} onClose={onClose} title="Edit Used Amount">
      {amountEditingService ? (
        <ContractServiceEditModalBody
          amountEditLabel="used"
          serviceType={ORGANIZATION_CONTRACT_SERVICE_TYPES_DISPLAY_LABELS[amountEditingService!.type]}
          costBasisUnit={
            ORGANIZATION_CONTRACT_SERVICE_COST_BASIS_UNITS_DISPLAY_LABELS[amountEditingService.costBasisUnit]
          }
          total={amountEditingService.total ?? 0}
          used={amountEditingService.used ?? 0}
          onClose={onClose}
          onSubmit={onSubmit}
          isSubmitPending={isPending}
          isSubmitError={isError}
        />
      ) : null}
    </ModalContainer>
  );
};

export default ContractServiceEditUsedModal;
