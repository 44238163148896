import {
  Button,
  Flex,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import colors from '@/theme/colors';
import { cutStringOverflow, displayCapitalizedUnderscoredString } from '@/utils/stringUtils';
import SvgIcon from '../SvgIcon';
import { svgIconPaths } from '@/assets/svg/icons';

interface Props<TOption> {
  title: string;
  options: readonly TOption[];
  selectedOptions?: string[];
  onOptionClick: (option: TOption) => void;
  height?: string;
  width?: string;
  popoverWidth?: string;
}

const DropdownMultiSelect = <TOption,>({
  title,
  options,
  onOptionClick,
  height = '40px',
  width = '',
  popoverWidth = '190px',
  selectedOptions,
}: Props<TOption>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const noValueSelected = !selectedOptions || (selectedOptions && selectedOptions.length === 0);

  const selectedOptionsText = cutStringOverflow({
    str: selectedOptions?.map((option) => displayCapitalizedUnderscoredString(option)).join(', '),
    length: 34,
    overflowSign: '..',
  });

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement={'bottom'}>
      <PopoverTrigger>
        <Button
          minWidth={'170px'}
          minHeight={'40px'}
          height={height}
          width={width}
          bg={isOpen ? colors.background.lightBlue : 'transparent'}
          cursor={'pointer'}
          paddingY={0}
          paddingX={'14px'}
          border={`1px solid ${noValueSelected ? '#E2E8F0' : '#004179'}`}
          backgroundColor={isOpen ? 'E2E8F0' : '#FFF'}
          _hover={{ backgroundColor: colors.background.lightBlue }}
        >
          <HStack flex={1}>
            <VStack flex={1} alignItems={'flex-start'}>
              <Text
                fontSize={'16px'}
                color={noValueSelected ? 'text.mediumGray' : 'text.darkBlue'}
                lineHeight={'20px'}
                fontWeight={600}
                // flex={1}
              >
                {noValueSelected ? title : selectedOptionsText}
              </Text>
            </VStack>
            {isOpen ? (
              <ChevronUpIcon boxSize={'5'} color={noValueSelected ? colors.text.mediumGray : colors.text.darkBlue} />
            ) : (
              <ChevronDownIcon boxSize={'5'} color={noValueSelected ? colors.text.mediumGray : colors.text.darkBlue} />
            )}
          </HStack>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        bg="white"
        width={popoverWidth}
        border={0}
        padding={'10px'}
        borderRadius={'8px'}
        boxShadow={'0px 6px 12px 0px #0E006224'}
      >
        {options.map((option) => {
          const isOptionSelected = selectedOptions?.includes(option as string);

          return (
            <Flex
              key={option as string}
              cursor={'pointer'}
              paddingX={'10px'}
              paddingY={'5px'}
              flexDirection={'row'}
              _hover={{ fontWeight: 800 }}
              fontWeight={isOptionSelected ? 800 : 500}
              onClick={() => {
                onOptionClick(option);
              }}
            >
              {displayCapitalizedUnderscoredString(option as string)}

              <VStack flex={1} justifyContent={'flex-end'} alignItems={'flex-end'}>
                {isOptionSelected && (
                  <SvgIcon
                    iconPath={svgIconPaths.checkWhite}
                    color={colors.primary[500]}
                    hoveredColor={colors.text.mediumGray}
                  />
                )}
              </VStack>
            </Flex>
          );
        })}
      </PopoverContent>
    </Popover>
  );
};

export default DropdownMultiSelect;
