import { TCustomAxiosError } from '@/services/api';
import { TContractDocumentUpload } from '@/types/Organization.types';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import useToast from '../useToast';
import { uploadContractDocument } from '@/services/api/requests/contracts';

type TUploadContractDocumentForm = {
  contractId: string;
  file: File;
} & TContractDocumentUpload;

const useUploadContractDocument = (
  onSuccess?: () => void,
): {
  mutate: UseMutateFunction<unknown, TCustomAxiosError, TUploadContractDocumentForm, unknown>;
  isPending: boolean;
  isError: boolean;
  error: TCustomAxiosError | null;
} => {
  const toast = useToast();

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: async ({ contractId, file, type, description }: TUploadContractDocumentForm) => {
      const response = await uploadContractDocument(contractId, type, file, description);
      return response;
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Contract Document successfully uploaded.',
      });

      onSuccess?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast(
        {
          status: 'error',
          title: 'Contract editing error',
          description: error?.errors[0]?.message,
        },
        error,
      );
    },
  });

  return { mutate, isPending, isError, error };
};

export default useUploadContractDocument;
