import React, { useMemo, useState } from 'react';
import { HStack, Input, Text, VStack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { therapistValidationSchema } from '@/validation/therapist';
import useToast from '@/hooks/useToast';
import { APPOINTMENT_TYPES, DESIGNATIONS, SCHEDULE_TOOLS, TTherapistForm } from '@/types/Therapist.types';
import { FORM_FIELD_WIDTH } from '@/constants/dimensions';
import DropdownMultiSelect from '@/components/atoms/Dropdown/DropdownMultiSelect';
import ProfilePhotoInput from '@/components/molecules/ProfilePhotoInput';
import Dropdown from '@/components/atoms/Dropdown';
import CustomButton from '@/components/atoms/CustomButton';
import { isEqual, isUndefined } from 'lodash';

type Props = {
  isPending?: boolean;
  onSubmit?: (form: TTherapistForm, photo: File | null) => void;
  submitLabel?: string;
  defaultValues?: Record<string, string | string[] | boolean>;
  isPhotoRequired?: boolean;
};

const TherapistForm = ({
  isPending,
  onSubmit,
  defaultValues,
  submitLabel = 'Create Therapist',
  isPhotoRequired = true,
}: Props) => {
  const toast = useToast();

  const [profilePhotoFile, setProfilePhotoFile] = useState<File | null>(null);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(therapistValidationSchema),
    defaultValues: {
      designation: [],
      schedule_tools: [],
      appointment_types: [],
      ...defaultValues,
    },
  });

  const {
    handleSubmit,
    watch,
    register,
    setValue,
    formState: { errors, touchedFields, isValid, isDirty },
  } = methods;

  const scheduleSyncInterestValue = watch('sync_schedule_interest');

  const designationsValue = watch('designation');
  const isDesignationCustom = designationsValue?.includes('OTHER');
  const customDesignationValue = watch('custom_designation');

  const scheduleToolsValue = watch('schedule_tools');
  const isScheduleToolCustom = scheduleToolsValue?.includes('OTHER');
  const customScheduleTool = watch('custom_schedule_tool');

  const appointmentTypesValue = watch('appointment_types');
  const isAppointmentTypeCustom = appointmentTypesValue?.includes('OTHER');
  const customAppointmentType = watch('custom_appointment_type');

  const isSomeOfDropdownsDirty =
    !isEqual(appointmentTypesValue, defaultValues?.appointment_types) ||
    !isEqual(scheduleToolsValue, defaultValues?.schedule_tools) ||
    !isEqual(designationsValue, defaultValues?.designation);

  const isSyncingScheduleDirty = scheduleSyncInterestValue !== defaultValues?.sync_schedule_interest;
  const isProfileImageDirty = profilePhotoFile !== null;

  const isFormDirty = isDirty || isSomeOfDropdownsDirty || isSyncingScheduleDirty || isProfileImageDirty;

  const isSomeDropdownEmpty = !appointmentTypesValue.length || !scheduleToolsValue.length || !designationsValue.length;

  const isSomeOtherValueMistakenlyEmpty = useMemo(() => {
    let someOtherValueEmpty = false;

    if (isScheduleToolCustom && !customScheduleTool) someOtherValueEmpty = true;
    if (isDesignationCustom && !customDesignationValue) someOtherValueEmpty = true;
    if (isAppointmentTypeCustom && !customAppointmentType) someOtherValueEmpty = true;

    return someOtherValueEmpty;
  }, [
    isScheduleToolCustom,
    isDesignationCustom,
    isAppointmentTypeCustom,
    customScheduleTool,
    customDesignationValue,
    customAppointmentType,
  ]);

  const isSubmitDisabled =
    !isValid || isPending || !isFormDirty || isSomeDropdownEmpty || isSomeOtherValueMistakenlyEmpty;

  const onFormSubmit = (data: unknown) => {
    if (profilePhotoFile === null && isPhotoRequired) {
      toast({ status: 'error', title: 'Form error', description: 'Profile photo is required.' });
      return;
    }

    const form = data as TTherapistForm;

    if (onSubmit) {
      onSubmit(form, profilePhotoFile);
    }
  };

  return (
    <VStack flex={1} width={'100%'}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <VStack gap={'20px'} marginBottom={'40px'}>
            <HStack gap={'20px'}>
              <Input
                {...register('first_name', { required: true })}
                className="custom-input"
                placeholder={'First Name'}
                fontSize={'16px'}
                _placeholder={{
                  fontWeight: '600',
                  color: 'background.blueGray',
                }}
                width={FORM_FIELD_WIDTH}
                data-state={touchedFields.first_name ? (errors.first_name?.message ? 'invalid' : 'valid') : ''}
              />
              <Input
                {...register('last_name', { required: true })}
                className="custom-input"
                placeholder={'Last Name'}
                fontSize={'16px'}
                _placeholder={{
                  fontWeight: '600',
                  color: 'background.blueGray',
                }}
                width={FORM_FIELD_WIDTH}
                data-state={touchedFields.last_name ? (errors.last_name?.message ? 'invalid' : 'valid') : ''}
              />
            </HStack>
            <HStack gap={'20px'}>
              <Input
                {...register('practice_name', { required: true })}
                className="custom-input"
                placeholder={'Practice Name'}
                fontSize={'16px'}
                _placeholder={{
                  fontWeight: '600',
                  color: 'background.blueGray',
                }}
                width={FORM_FIELD_WIDTH}
                data-state={touchedFields.practice_name ? (errors.practice_name?.message ? 'invalid' : 'valid') : ''}
              />
              <Input
                {...register('email', { required: true })}
                className="custom-input"
                placeholder={'Email (optional)'}
                fontSize={'16px'}
                _placeholder={{
                  fontWeight: '600',
                  color: 'background.blueGray',
                }}
                width={FORM_FIELD_WIDTH}
                data-state={touchedFields.email ? (errors.email?.message ? 'invalid' : 'valid') : ''}
              />
            </HStack>
            <HStack gap={'20px'} width={'100%'}>
              <DropdownMultiSelect
                title="Designations"
                height="50px"
                width="100%"
                popoverWidth={FORM_FIELD_WIDTH}
                options={DESIGNATIONS}
                selectedOptions={designationsValue?.filter((value): value is string => value !== undefined)}
                onOptionClick={(option) => {
                  const copy = [...designationsValue];
                  if (copy.includes(option)) {
                    copy.splice(copy.indexOf(option), 1);
                  } else {
                    copy.push(option);
                  }
                  setValue('designation', copy);
                }}
              />
              {isDesignationCustom && (
                <Input
                  {...register('custom_designation')}
                  className="custom-input"
                  placeholder={'Other Designation'}
                  fontSize={'16px'}
                  _placeholder={{
                    fontWeight: '600',
                    color: 'background.blueGray',
                  }}
                  data-state={
                    touchedFields.custom_designation ? (errors.custom_designation?.message ? 'invalid' : 'valid') : ''
                  }
                />
              )}
            </HStack>
            <Input
              {...register('bio', { required: true })}
              className="custom-input"
              as={'textarea'}
              placeholder={'Bio'}
              minWidth={FORM_FIELD_WIDTH}
              style={{ minHeight: '200px', maxHeight: '400px' }}
              _placeholder={{ fontWeight: '600', color: 'text.mediumGray' }}
              data-state={touchedFields.bio ? (errors.bio?.message ? 'invalid' : 'valid') : ''}
            />
            <ProfilePhotoInput
              setProfilePhoto={setProfilePhotoFile}
              width="100%"
              initialPreviewUrl={defaultValues?.profile_photo as string}
            />
            <HStack gap={'20px'} width={'100%'}>
              <DropdownMultiSelect
                title="Scheduling Tools"
                height="50px"
                width="100%"
                popoverWidth={FORM_FIELD_WIDTH}
                options={SCHEDULE_TOOLS}
                selectedOptions={scheduleToolsValue?.filter((value): value is string => value !== undefined)}
                onOptionClick={(option) => {
                  const copy = [...scheduleToolsValue];
                  if (copy.includes(option)) {
                    copy.splice(copy.indexOf(option), 1);
                  } else {
                    copy.push(option);
                  }
                  setValue('schedule_tools', copy);
                }}
              />
              {isScheduleToolCustom && (
                <Input
                  {...register('custom_schedule_tool')}
                  className="custom-input"
                  placeholder={'Other Schedule Tool'}
                  fontSize={'16px'}
                  _placeholder={{
                    fontWeight: '600',
                    color: 'background.blueGray',
                  }}
                  data-state={
                    touchedFields.custom_schedule_tool
                      ? errors.custom_schedule_tool?.message
                        ? 'invalid'
                        : 'valid'
                      : ''
                  }
                />
              )}
            </HStack>
            <HStack gap={'20px'}>
              <Input
                {...register('ehr_software')}
                className="custom-input"
                placeholder={'EHR Software (optional)'}
                fontSize={'16px'}
                _placeholder={{
                  fontWeight: '600',
                  color: 'background.blueGray',
                }}
                width={FORM_FIELD_WIDTH}
                data-state={touchedFields.ehr_software ? (errors.ehr_software?.message ? 'invalid' : 'valid') : ''}
              />
              <Dropdown
                title="Interested In Syncing Schedule"
                height="50px"
                width={FORM_FIELD_WIDTH}
                popoverWidth={FORM_FIELD_WIDTH}
                options={['YES', 'NO']}
                selectedOption={
                  isUndefined(scheduleSyncInterestValue) ? undefined : scheduleSyncInterestValue ? 'YES' : 'NO'
                }
                onOptionClick={(option) => {
                  const newValue = option === 'YES' ? true : false;
                  setValue('sync_schedule_interest', newValue);
                }}
              />
            </HStack>
            <HStack gap={'20px'} width={'100%'}>
              <DropdownMultiSelect
                title="Appointment Types"
                height="50px"
                width="100%"
                popoverWidth={FORM_FIELD_WIDTH}
                options={APPOINTMENT_TYPES}
                selectedOptions={appointmentTypesValue?.filter((value): value is string => value !== undefined)}
                onOptionClick={(option) => {
                  const copy = [...appointmentTypesValue];
                  if (copy.includes(option)) {
                    copy.splice(copy.indexOf(option), 1);
                  } else {
                    copy.push(option);
                  }
                  setValue('appointment_types', copy);
                }}
              />
              {isAppointmentTypeCustom && (
                <Input
                  {...register('custom_appointment_type')}
                  className="custom-input"
                  placeholder={'Other Appointment Type'}
                  fontSize={'16px'}
                  _placeholder={{
                    fontWeight: '600',
                    color: 'background.blueGray',
                  }}
                  data-state={
                    touchedFields.custom_appointment_type
                      ? errors.custom_appointment_type?.message
                        ? 'invalid'
                        : 'valid'
                      : ''
                  }
                />
              )}
            </HStack>

            <Input
              {...register('booking_link', { required: true })}
              className="custom-input"
              placeholder={'Booking Link'}
              fontSize={'16px'}
              _placeholder={{
                fontWeight: '600',
                color: 'background.blueGray',
              }}
              data-state={touchedFields.booking_link ? (errors.booking_link?.message ? 'invalid' : 'valid') : ''}
            />

            <Text color={'extra.red'} marginBottom="10px" fontWeight={600}>
              {errors.first_name?.message ||
                errors.last_name?.message ||
                errors.practice_name?.message ||
                errors.email?.message ||
                errors.designation?.message ||
                errors.bio?.message ||
                errors.schedule_tools?.message ||
                errors.ehr_software?.message ||
                errors.sync_schedule_interest?.message ||
                errors.appointment_types?.message ||
                errors.booking_link?.message}
            </Text>

            <CustomButton
              backgroundColor="secondary.500"
              label={submitLabel}
              width={FORM_FIELD_WIDTH}
              disabled={isSubmitDisabled}
              // isLoading={isPending}
              buttonType={isSubmitDisabled ? 'button' : 'submit'}
            />
          </VStack>
        </form>
      </FormProvider>
    </VStack>
  );
};

export default TherapistForm;
