import { svgIconPaths } from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import SvgIcon from '@/components/atoms/SvgIcon';
import colors from '@/theme/colors';
import { TUserActivationToggle } from '@/types/User.types';
import { HStack, Popover, PopoverAnchor, PopoverContent, PopoverTrigger, useDisclosure } from '@chakra-ui/react';
import React from 'react';

type Props = {
  user: TUserActivationToggle;
  isReinviteHidden?: boolean;
  onDeactivate: (user: TUserActivationToggle) => void;
  onReinvite: (user: TUserActivationToggle) => void;
};

const RosterActionsPopover = ({ user, onDeactivate, onReinvite, isReinviteHidden = false }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Popover placement={'auto'} isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <HStack padding={'8px'}>
          <PopoverAnchor>
            <HStack width={'4px'} height={'15px'} />
          </PopoverAnchor>
          <PopoverTrigger key={user.id}>
            <SvgIcon
              iconPath={svgIconPaths.arrowCircledCheckMark}
              color={'text.mediumBlue'}
              boxHeight={'20'}
              boxWidth={'20'}
            />
          </PopoverTrigger>
        </HStack>

        <PopoverContent
          bg="white"
          border={0}
          padding={'10px'}
          borderRadius={'8px'}
          boxShadow={'0px 6px 12px 0px #0E006224'}
          width={'136px'}
          alignItems={'center'}
        >
          <CustomButton
            isTransparent
            label={'Deactivate'}
            hoverBackgroundColor="extra.red"
            labelColor={colors.text.mediumBlue}
            labelHoverColor={colors.extra.white}
            iconBeforeLabelSvgPath={svgIconPaths.userDeactivate}
            onClick={() => onDeactivate(user)}
            width={'120px'}
            height={'35px'}
            noShadow
          />
          {!isReinviteHidden && (
            <CustomButton
              isTransparent
              label={'Reinvite'}
              hoverBackgroundColor="primary.500"
              labelColor={colors.text.mediumBlue}
              labelHoverColor={colors.extra.white}
              iconBeforeLabelSvgPath={svgIconPaths.email}
              onClick={() => onReinvite(user)}
              width={'120px'}
              height={'35px'}
              noShadow
            />
          )}
        </PopoverContent>
      </Popover>
    </>
  );
};

export default RosterActionsPopover;
