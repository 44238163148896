import colors from '../colors';

const loraTitleCommonStyles = {
  fontFamily: 'Lora',
  fontStyle: 'oblique',
  fontSize: '36px',
  color: 'text.darkBlue',
  lineHeight: '48px',
  textAlign: 'center',
};

const loraParagraphCommonStyles = {
  fontFamily: 'Lora',
  fontStyle: 'oblique',
  fontSize: '16px',
  lineHeight: '20px',
  color: 'text.mediumBlue',
};

const urbanistCommonStyles = {
  fontFamily: 'Urbanist',
  fontSize: '14px',
  lineHeight: '16.8px',
};

export default {
  Text: {
    defaultProps: {
      size: 'md',
      fontFamily: 'Urbanist',
      color: 'text.mediumGray',
    },
    baseStyle: {
      lineHeight: '26px',
      fontWeight: '400',
    },
    variants: {
      lora: {
        fontFamily: 'Lora',
      },
      loraParagraph: {
        ...loraParagraphCommonStyles,
      },
      loraSectionTitle: {
        ...loraTitleCommonStyles,
        fontSize: '30px',
        fontWeight: 500,
        fontStyle: 'italic',
        lineHeight: '38px',
      },
      loraTitle: {
        ...loraTitleCommonStyles,
        fontSize: '32px',
        lineHeight: '40px',
        fontWeight: '500',
      },
      loraMedium: {
        ...loraTitleCommonStyles,
        fontSize: '20px',
        lineHeight: '25px',
        fontWeight: '500',
      },
      loraMediumTitle: {
        ...loraTitleCommonStyles,
        fontSize: '26px',
        lineHeight: '34px',
        fontWeight: '500',
      },
      loraSmallTitle: {
        ...loraTitleCommonStyles,
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: '400',
      },
      loraItalicTitle: {
        ...loraTitleCommonStyles,
        fontWeight: '400',
      },
      loraMediumItalicTitle: {
        ...loraTitleCommonStyles,
        fontWeight: '500',
      },
      loraSemiBoldItalicTitle: {
        ...loraTitleCommonStyles,
        fontWeight: '600',
      },
      loraBoldItalicTitle: {
        ...loraTitleCommonStyles,
        fontWeight: '700',
      },
      urbanistSmall: {
        ...urbanistCommonStyles,
        fontSize: '12px',
        lineHeight: '14px',
      },
      urbanistSmallBold: {
        ...urbanistCommonStyles,
        fontSize: '12px',
        lineHeight: '14px',
        fontWeight: 700,
      },
      urbanistRegular: {
        ...urbanistCommonStyles,
      },
      urbanistRegular16: {
        ...urbanistCommonStyles,
        fontSize: '16px',
      },
      urbanistMedium16: {
        ...urbanistCommonStyles,
        fontSize: '16px',
        fontWeight: 600,
        color: 'text.mediumBlue',
      },
      urbanistMedium18: {
        ...urbanistCommonStyles,
        fontSize: '18px',
        fontWeight: 600,
        color: 'text.mediumBlue',
      },
      urbanistExtraBold16: {
        ...urbanistCommonStyles,
        fontSize: '16px',
        fontWeight: 900,
      },
      urbanistMedium: {
        ...urbanistCommonStyles,
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '24px',
      },
      urbanistMediumRegular: {
        fontWeight: '500',
        ...urbanistCommonStyles,
      },
      urbanistBoldRegular: {
        fontWeight: '700',
        ...urbanistCommonStyles,
      },
      urbanistSemiBold: {
        ...urbanistCommonStyles,
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '19px',
        textAlign: 'center',
      },
      urbanistSemiBoldRegular: {
        ...urbanistCommonStyles,
        fontWeight: '600',
      },
      urbanistMediumSemiBoldTitle: {
        ...urbanistCommonStyles,
        fontWeight: '600',
        fontSize: '22px',
        lineHeight: '24px',
      },
      urbanistSemiBoldTitle: {
        ...urbanistCommonStyles,
        fontWeight: '600',
        fontSize: '26px',
        lineHeight: '31px',
      },
      urbanistSemiBoldSmallTitle: {
        ...urbanistCommonStyles,
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '24px',
      },
      urbanistBold: {
        ...urbanistCommonStyles,
        fontWeight: '700',
      },
      urbanistExtraBold: {
        ...urbanistCommonStyles,
        fontWeight: '800',
      },
      urbanistExtraBoldSmall: {
        ...urbanistCommonStyles,
        fontWeight: '800',
        fontSize: '10px',
        lineHeight: '12px',
      },
      urbanistTitleMedium: {
        ...urbanistCommonStyles,
        fontSize: '30px',
        lineHeight: '36px',
        fontWeight: '700',
      },
      urbanistTitleLarge: {
        fontSize: '36px',
        lineHeight: '43px',
        textAlign: 'center',
        color: 'text.veryLightBlue',
        opacity: '0.7',
      },
      error: {
        ...urbanistCommonStyles,
        color: 'extra.red',
        fontWeight: 500,
      },
      errorLightText: {
        ...urbanistCommonStyles,
        color: 'extra.white',
        fontWeight: 500,
      },
      finePrint: {
        fontSize: '12px',
        textAlign: 'center',
        color: colors.background.blueGray,
      },
      assessmentQuestionTitle: {
        ...urbanistCommonStyles,
        fontSize: '18px',
        lineHeight: '22px',
        fontWeight: 500,
        textAlign: 'center',
        color: '#004179',
        marginTop: '40px',
      },
      assessmentQuestionText: {
        fontFamily: 'lora',
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 400,
        textAlign: 'center',
        color: '#004179',
        marginBottom: '20px',
        fontStyle: 'italic',
        marginTop: '20px',
      },
    },
  },
};
