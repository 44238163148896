import queryKeys from '@/constants/queryKeys';
import appRoutes from '@/constants/routes/app';
import { patchTherapist } from '@/services/api/requests/therapists';
import { TTherapistApi } from '@/types/Therapist.types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import useToast from '../useToast';

const usePatchTherapist = (id: string) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { isPending, isSuccess, mutate } = useMutation({
    mutationFn: async ({ form, photo }: { form: TTherapistApi; photo: File | null }) => {
      const response = await patchTherapist({ form, photo, id });
      return response;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [queryKeys.therapists.all, queryKeys.therapists.byId, id] });
      toast({ status: 'success', title: 'Success', description: 'Therapist successfully updated.' });
      navigate({ to: appRoutes.therapists });
    },
    onError: (error) => {
      toast({ status: 'error', title: 'Updating therapist error', description: error?.message });
    },
  });

  return { isPending, isSuccess, mutate };
};

export default usePatchTherapist;
