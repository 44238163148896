import CheckBoxTile from '@/components/atoms/CheckBoxTile';
import {
  PEER_SUPPORT_IMPLEMENTATION_PROGRAM_SUB_SERVICES,
  PEER_SUPPORT_IMPLEMENTATION_PROGRAM_SUB_SERVICES_DISPLAY_LABELS,
} from '@/constants/stringVars';
import { TOrganizationContractServiceSubService } from '@/types/Organization.types';
import { Text, VStack } from '@chakra-ui/react';
import React, { useCallback } from 'react';

interface Props {
  checkedSubServices: TOrganizationContractServiceSubService[];
  setCheckedSubServices: (newValue: TOrganizationContractServiceSubService[]) => void;
  checkBoxTileWidth?: string;
  checkBoxTileTextWidth?: string;
}

const ContractServiceSubServicesCheckboxes = ({
  checkedSubServices,
  setCheckedSubServices,
  checkBoxTileWidth = '610px',
  checkBoxTileTextWidth = '550px',
}: Props) => {
  const updateCheckedSubServices = useCallback(
    (newlyCheckedSubService: TOrganizationContractServiceSubService) => {
      let newCheckedSubServices = [...checkedSubServices];

      if (newCheckedSubServices.includes(newlyCheckedSubService)) {
        newCheckedSubServices = newCheckedSubServices.filter(
          (previouslyCheckedConcern) => previouslyCheckedConcern !== newlyCheckedSubService,
        );
      } else {
        newCheckedSubServices = [...newCheckedSubServices, newlyCheckedSubService];
      }

      setCheckedSubServices(newCheckedSubServices);
    },
    [checkedSubServices],
  );

  return (
    <VStack gap={'14px'} marginTop={'10px'} paddingLeft={'20px'}>
      <Text variant={'urbanistSemiBold'} fontSize={'14px'} color={'text.mediumGray'} width={'100%'} align={'start'}>
        Select which services of Peer Support Development have been completed.
      </Text>
      {PEER_SUPPORT_IMPLEMENTATION_PROGRAM_SUB_SERVICES.map((subService) => (
        <VStack key={subService} gap={'0'}>
          <CheckBoxTile
            width={checkBoxTileWidth}
            maxTextWidth={checkBoxTileTextWidth}
            isChecked={checkedSubServices.includes(subService)}
            backgroundColor="transparent"
            borderColor="transparent"
            boxShadowStyle="none"
            checkboxOption={{
              label: PEER_SUPPORT_IMPLEMENTATION_PROGRAM_SUB_SERVICES_DISPLAY_LABELS[subService],
            }}
            onClick={() => updateCheckedSubServices(subService)}
          />
        </VStack>
      ))}
      {checkedSubServices.length === 0 && (
        <Text variant={'error'} width={'100%'} align={'start'}>
          Please select at least one service
        </Text>
      )}
    </VStack>
  );
};

export default ContractServiceSubServicesCheckboxes;
