import { TOrganizationSuperAdminExtended } from '@/types/Organization.types';
import { Text, VStack } from '@chakra-ui/react';
import React from 'react';
import HistoricalDataChart from '../HistoricalDataChart';
import LoadingLogo from '@/components/atoms/LoadingLogo';

type Props = {
  organizationData: TOrganizationSuperAdminExtended | undefined;
  isLoading: boolean;
};

const OrganizationDataTab = ({ organizationData, isLoading }: Props) => {
  return (
    <VStack flex={1} width={'100%'} paddingTop={'30px'}>
      <Text variant={'loraMediumTitle'}>Historical Data Chart</Text>
      {isLoading && (
        <VStack flex={1} justifyContent={'center'} alignItems={'center'}>
          <LoadingLogo />
        </VStack>
      )}
      {!isLoading && organizationData && (
        <VStack
          width={'100%'}
          padding={'40px'}
          boxShadow="0px 10px 80px 0px #00417926"
          background="white"
          borderRadius={'10px'}
          justifyContent={'center'}
          alignItems={'center'}
          marginTop={'15px'}
        >
          <HistoricalDataChart data={organizationData?.historical.monthly} />
        </VStack>
      )}
    </VStack>
  );
};

export default OrganizationDataTab;
