import iconsPng from '@/assets/img/png/icons';
import { svgIconPaths } from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import ConfirmationModal from '@/components/molecules/ConfirmationModal';
import CustomTable from '@/components/molecules/CustomTable';
import LoadingPage from '@/components/templates/LoadingPage';
import SidebarItemViewTemplate from '@/components/templates/SidebarItemViewTemplate';
import queryKeys from '@/constants/queryKeys';
import {
  useDeactivateMemberCare,
  useMemberCareProfile,
  useReactivateMemberCare,
  useUnassignOrgFromMemberCare,
} from '@/hooks/member-care/memberCare';
import MemberCareAssignOrgModal from '@/modules/memberCare/MemberCareAssignOrgModal';
import colors from '@/theme/colors';
import { TMemberCareAssignedOrg } from '@/types/MemberCare.types';
import { getLastLoginStringDisplay } from '@/utils/stringUtils';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Center, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useCallback, useMemo, useState } from 'react';

const IMAGE_SIZE_PX = '200px';
const CHARS_IN_CALENDLY_LINK = 30;

const COLUMNS: string[] = ['Organization Name', 'Unassign from Organization'];
type TSortingColumn = (typeof COLUMNS)[number];

const MemberCareProfile = () => {
  const queryClient = useQueryClient();
  const { memberCareId } = Route.useParams();

  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [orgToUnassignFrom, setOrgToUnassignFrom] = useState<TMemberCareAssignedOrg>();
  const [isOrgUnassignConfirmationOpened, setIsOrgUnassignConfirmationOpened] = useState(false);

  const { data: memberCare, isLoading } = useMemberCareProfile(memberCareId);

  const { isPending, mutate: unassignOrgFromMC } = useUnassignOrgFromMemberCare(() => {
    setIsOrgUnassignConfirmationOpened(false);

    queryClient.invalidateQueries({
      queryKey: [queryKeys.memberCare.memberCareSpecialistById, memberCareId],
    });

    queryClient.invalidateQueries({
      queryKey: [queryKeys.memberCare.unassignedOrgs],
    });
  });

  const memberCareIsDeactivated = useMemo(() => memberCare?.status === 'DEACTIVATED', [memberCare?.status]);

  const [isActivateMemberCareModalOpen, setIsActivateMemberCareModalOpen] = useState(false);
  const [isDeactivateMemberCareModalOpen, setIsDeactivateMemberCareModalOpen] = useState(false);

  const onActivateToggleClick = useCallback(() => {
    if (memberCareIsDeactivated) {
      setIsActivateMemberCareModalOpen(true);
    } else {
      setIsDeactivateMemberCareModalOpen(true);
    }
  }, [memberCareIsDeactivated]);

  const { isPending: isMemberCareReactivationPending, mutate: reactivateMemberCare } = useReactivateMemberCare(() =>
    setIsActivateMemberCareModalOpen(false),
  );
  const { isPending: isMemberCareDeactivationPending, mutate: deactivateMemberCare } = useDeactivateMemberCare(() =>
    setIsDeactivateMemberCareModalOpen(false),
  );

  const assignedOrgsTableItems = useMemo(
    () =>
      memberCare
        ? memberCare.organizations.map((memberCareOrg: TMemberCareAssignedOrg) => {
            return {
              id: memberCareOrg.id,
              cells: [
                memberCareOrg.name,
                <CustomButton
                  isTransparent
                  label={'Unassign'}
                  hoverBackgroundColor="extra.red"
                  labelColor={colors.text.mediumBlue}
                  labelHoverColor={colors.extra.white}
                  iconBeforeLabelSvgPath={svgIconPaths.userDeactivate}
                  onClick={() => {
                    setIsOrgUnassignConfirmationOpened(true);
                    setOrgToUnassignFrom(memberCareOrg);
                  }}
                  width={'120px'}
                  height={'35px'}
                  noShadow
                />,
              ],
            };
          })
        : [],
    [memberCare?.organizations],
  );

  if (!memberCare || isLoading) return <LoadingPage />;

  return (
    <SidebarItemViewTemplate
      title={'Member Support Profile'}
      buttonLabel={memberCareIsDeactivated ? 'Reactivate Account' : 'Deactivate Account'}
      buttonColor={memberCareIsDeactivated ? 'primary.500' : 'extra.red'}
      onButtonClick={onActivateToggleClick}
    >
      <VStack width={'100%'} alignItems={'flex-start'} gap={'70px'} marginTop={'20px'}>
        <HStack gap={'30px'}>
          <VStack marginRight={'30px'}>
            <Image
              src={memberCare.user_metadata?.profilePicture || memberCare.user_metadata?.profile_photo || iconsPng.lock}
              width={IMAGE_SIZE_PX}
              height={IMAGE_SIZE_PX}
              alt="mc-profile"
              borderRadius={'10px'}
              objectFit={'cover'}
            />
          </VStack>

          <VStack minWidth={'350px'} alignItems={'flex-start'} gap={'20px'}>
            <Text variant={'urbanistMedium'}>
              Name: <br />
              <b>{`${memberCare.user_metadata?.firstName ?? memberCare.firstName} ${memberCare.user_metadata?.lastName ?? memberCare.lastName}`}</b>
            </Text>
            <Text variant={'urbanistMedium'}>
              Sex: <br />
              <b>{memberCare.sex}</b>
            </Text>
            <Text variant={'urbanistMedium'}>
              Calendly:
              <br />
              <a
                target="_blank"
                href={memberCare.user_metadata?.calendly_link}
                style={{ fontSize: '18px', textDecoration: 'underline' }}
              >
                {memberCare.user_metadata?.calendly_link?.substring(0, CHARS_IN_CALENDLY_LINK)}...
                <ExternalLinkIcon margin={'4px'} marginTop={'0'} />
              </a>
            </Text>
          </VStack>

          <VStack alignItems={'flex-start'} gap={'20px'}>
            <Text variant={'urbanistMedium'}>
              Email: <br />
              <b>{memberCare.email}</b>
            </Text>
            <Text variant={'urbanistMedium'}>
              Phone: <br />
              <b>{memberCare.user_metadata?.phone}</b>
            </Text>
            <Text variant={'urbanistMedium'}>
              Last login: <br />
              <b>{getLastLoginStringDisplay(memberCare.last_login)}</b>
            </Text>
          </VStack>
        </HStack>

        {/* Assigned Organizations */}
        <VStack width={'70%'} alignItems={'flex-start'} gap={'20px'} marginTop={'-10px'}>
          <HStack width={'100%'} justifyContent={'space-between'}>
            <Text variant={'loraSmallTitle'}>Assigned Organizations:</Text>
            <CustomButton label={'Assign Organization'} onClick={() => setIsAssignModalOpen(true)} />
          </HStack>
          <Center width={'100%'}>
            <CustomTable<TMemberCareAssignedOrg[], TSortingColumn>
              dataWithId={memberCare.organizations}
              tableListItems={assignedOrgsTableItems}
              isSearching={false}
              columns={COLUMNS}
              noItemsMessage="No organizations assigned to this Member Support Specialist."
              sortingColumn=""
              isLoading={false}
              isRefetching={false}
              fullWidth
            />
          </Center>
        </VStack>
      </VStack>

      <ConfirmationModal
        isOpen={isOrgUnassignConfirmationOpened}
        onCancel={() => {
          setIsOrgUnassignConfirmationOpened(false);
          setOrgToUnassignFrom(undefined);
        }}
        title="Unassign Organization"
        confirmButtonText="Unassign"
        description={`Are you sure you want to unassign member care specialist ${memberCare.firstName ?? memberCare.user_metadata?.firstName} from ${orgToUnassignFrom?.name}?`}
        onConfirm={() => unassignOrgFromMC({ memberCareId, orgId: orgToUnassignFrom!.id })}
        confirmButtonColor="extra.red"
        confirmLoading={isPending}
      />

      <ConfirmationModal
        isOpen={isActivateMemberCareModalOpen}
        onCancel={() => setIsActivateMemberCareModalOpen(false)}
        title="Reactivate Account"
        confirmButtonText="Reactivate"
        description={
          'Are you sure you want to reactivate this account? The user will regain access to the platform, and they will be notified via email.'
        }
        onConfirm={() => reactivateMemberCare(memberCareId)}
        confirmButtonColor="primary.500"
        confirmLoading={isMemberCareReactivationPending}
      />

      <ConfirmationModal
        isOpen={isDeactivateMemberCareModalOpen}
        onCancel={() => setIsDeactivateMemberCareModalOpen(false)}
        title="Deactivate Account"
        confirmButtonText="Deactivate"
        description={
          'Are you sure you want to deactivate this account? The user will no longer have access to the platform, but their data will be preserved.'
        }
        onConfirm={() => deactivateMemberCare(memberCareId)}
        confirmButtonColor="extra.red"
        confirmLoading={isMemberCareDeactivationPending}
      />

      <MemberCareAssignOrgModal
        isOpen={isAssignModalOpen}
        setIsOpen={setIsAssignModalOpen}
        memberCareName={`${memberCare.firstName ?? memberCare.user_metadata?.firstName} ${memberCare.lastName ?? memberCare.user_metadata?.lastName}`}
        memberCareId={memberCare.user_id}
      />
    </SidebarItemViewTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_admin/member-care/$memberCareId')({
  component: MemberCareProfile,
});
