import ModalContainer from '@/components/molecules/ModalContainer/ModalContainer';
import {
  TContractServiceBE,
  TOrganizationContract,
  TOrganizationContractServiceSubService,
} from '@/types/Organization.types';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useEditContract from '@/hooks/contracts/useEditContractServiceSubservices';
import ContractServiceSubServicesCheckboxes from './ContractServiceSubServicesCheckboxes';
import { Text, VStack } from '@chakra-ui/react';
import ConfirmCancelButtons from '@/components/molecules/ConfirmationModal/ConfirmCancelButtons';

interface Props {
  contract: TOrganizationContract;
  editingService: TContractServiceBE | undefined;
  setEditingService: Dispatch<SetStateAction<TContractServiceBE | undefined>>;
  onEditSuccessful?: () => void;
}

const ContractServiceEditSubServicesModal = ({
  contract,
  editingService,
  setEditingService,
  onEditSuccessful,
}: Props) => {
  const onClose = () => {
    setEditingService(undefined);
  };

  const {
    mutate,
    isPending,
    isError: isSubmitError,
  } = useEditContract(() => {
    onClose();
    onEditSuccessful?.();
  });

  const [checkedSubServices, setCheckedSubServices] = useState<TOrganizationContractServiceSubService[]>(
    editingService?.subServices ?? [],
  );

  useEffect(() => {
    setCheckedSubServices(editingService?.subServices ?? []);
  }, [editingService?.subServices]);

  const onConfirm = () => {
    if (editingService) {
      mutate({
        contract,
        newSubServices: checkedSubServices,
      });
    }
  };

  const valuesSameAsInitialOrEmpty =
    checkedSubServices.sort().toString() === editingService?.subServices?.sort()?.toString() ||
    checkedSubServices.length === 0;

  return (
    <ModalContainer
      isOpen={!!editingService}
      onClose={onClose}
      title="Edit Services"
      width="60vw"
      titleBottomMargin="60px"
    >
      <VStack gap={0}>
        <Text variant={'urbanistMediumSemiBoldTitle'} color={'primary.500'}>
          Peer Support Program Implementation Support
        </Text>
        <VStack
          backgroundColor={'extra.white'}
          borderRadius={'10px'}
          boxShadow="0px 10px 80px 0px #00417926"
          padding={'20px'}
          paddingBottom={'40px'}
          marginTop={'40px'}
          marginBottom={'20px'}
        >
          <ContractServiceSubServicesCheckboxes
            checkedSubServices={checkedSubServices}
            setCheckedSubServices={setCheckedSubServices}
            checkBoxTileWidth="480px"
            checkBoxTileTextWidth="430px"
          />
        </VStack>

        <ConfirmCancelButtons
          confirmButtonText={'Save Changes'}
          onCancel={onClose}
          onConfirm={onConfirm}
          confirmLoading={isPending}
          confirmButtonType="button"
          confirmButtonColor="secondary.500"
          confirmButtonDisabled={valuesSameAsInitialOrEmpty}
          minButtonWidth="200px"
          marginBottom="0"
        />
        {isSubmitError && <Text variant={'error'}>Something went wrong.</Text>}
      </VStack>
    </ModalContainer>
  );
};

export default ContractServiceEditSubServicesModal;
