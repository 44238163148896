import CustomButton from '@/components/atoms/CustomButton';
import ModalContainer from '@/components/molecules/ModalContainer/ModalContainer';
import { useToggleActivateLegalDocument } from '@/hooks/legal/legal';
import { TLegalDocument } from '@/types/Legal.types';
import { Text, VStack } from '@chakra-ui/react';
import React from 'react';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  document: TLegalDocument;
};

const EulaActivationConfirmModal = ({ isOpen, onClose, document }: Props) => {
  const { isPending, mutate } = useToggleActivateLegalDocument(() => {
    window.location.reload();
  });

  const isActive = document.isActive;

  const onConfirm = () => {
    mutate({
      id: document._id,
      isActive,
      content: document.content,
      type: document.type,
      version: document.version,
      s3Key: document.s3Key,
    });
  };

  return (
    <ModalContainer title="EULA Status Update" onClose={onClose} isOpen={isOpen}>
      <VStack spacing={'20px'}>
        <Text>{`Are you sure you want to ${isActive ? 'deactivate' : 'activate'} the document?`}</Text>
        <CustomButton
          backgroundColor={isActive ? 'red' : 'secondary.500'}
          label={isActive ? 'Deactivate' : 'Activate'}
          onClick={onConfirm}
          isLoading={isPending}
          width={'100%'}
        />
      </VStack>
    </ModalContainer>
  );
};

export default EulaActivationConfirmModal;
