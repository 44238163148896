import CustomTable from '@/components/molecules/CustomTable';
import {
  CONTRACT_SERVICE_WITH_SUB_SERVICES,
  ORGANIZATION_CONTRACT_SERVICE_COST_BASIS_UNITS_DISPLAY_LABELS,
  ORGANIZATION_CONTRACT_SERVICE_TYPES_DISPLAY_LABELS,
  PEER_SUPPORT_IMPLEMENTATION_PROGRAM_SUB_SERVICES,
  PEER_SUPPORT_IMPLEMENTATION_PROGRAM_SUB_SERVICES_DISPLAY_LABELS,
} from '@/constants/stringVars';
import { TContractServiceBE, TOrganizationContract } from '@/types/Organization.types';
import { HStack, Text, VStack } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import ContractServicesTableItemPopover from './ContractServicesTableItemPopover';
import ContractServiceEditTotalModal from './ContractServiceEditTotalModal';
import ContractServiceEditUsedModal from './ContractServiceEditUsedModal';
import ContractServiceEditSubServicesModal from './ContractServiceEditSubServicesModal';
import SvgIcon from '@/components/atoms/SvgIcon';
import { svgIconPaths } from '@/assets/svg/icons';
import colors from '@/theme/colors';

const COLUMNS = ['ACTIVE SERVICES', 'COST UNIT BASIS', 'TOTAL', 'CURRENTLY USED', 'ACTIONS'] as const;

interface Props {
  contract: TOrganizationContract;
  refetchActiveContract: () => void;
}

const ContractServicesTable = ({ contract, refetchActiveContract }: Props) => {
  const [totalAmountEditingService, setTotalAmountEditingService] = useState<TContractServiceBE>();
  const [usedAmountEditingService, setUsedAmountEditingService] = useState<TContractServiceBE>();
  const [subServicesEditingService, setSubServicesEditingService] = useState<TContractServiceBE>();

  const tableListItems = useMemo(
    () =>
      contract.services.map((service: (typeof contract.services)[number]) => {
        const hasSubServices = service.type === CONTRACT_SERVICE_WITH_SUB_SERVICES;

        return {
          id: service.type,
          cells: [
            hasSubServices ? (
              <VStack align={'start'}>
                <Text variant={'urbanistMedium16'} marginY={'6px'}>
                  {ORGANIZATION_CONTRACT_SERVICE_TYPES_DISPLAY_LABELS[service.type]}
                </Text>
                {PEER_SUPPORT_IMPLEMENTATION_PROGRAM_SUB_SERVICES.map((subService) => {
                  const isChecked = service.subServices?.includes(subService);

                  return (
                    <HStack key={subService}>
                      {isChecked ? (
                        <SvgIcon
                          iconPath={svgIconPaths.checkWhite}
                          color={colors.primary[500]}
                          size={14}
                          style={{ marginTop: '4px' }}
                        />
                      ) : (
                        <VStack
                          minWidth={'6px'}
                          minHeight={'6px'}
                          backgroundColor={'text.mediumGray'}
                          borderRadius={'50%'}
                          marginRight={'5px'}
                          marginLeft={'3px'}
                        />
                      )}
                      <Text
                        variant={'urbanistSmall'}
                        fontWeight={600}
                        color={isChecked ? 'primary.500' : 'text.mediumGray'}
                      >
                        {PEER_SUPPORT_IMPLEMENTATION_PROGRAM_SUB_SERVICES_DISPLAY_LABELS[subService]}
                      </Text>
                    </HStack>
                  );
                })}
              </VStack>
            ) : (
              ORGANIZATION_CONTRACT_SERVICE_TYPES_DISPLAY_LABELS[service.type]
            ),
            <HStack align={'start'} height={'100%'} marginTop={'12px'}>
              <Text variant={'urbanistMedium16'}>
                {ORGANIZATION_CONTRACT_SERVICE_COST_BASIS_UNITS_DISPLAY_LABELS[service.costBasisUnit]}
              </Text>
            </HStack>,
            <HStack align={'start'} height={'100%'} marginTop={'12px'}>
              <Text variant={'urbanistMedium16'}>{(hasSubServices ? 5 : (service.total ?? 0)).toString()}</Text>
            </HStack>,
            <HStack align={'start'} height={'100%'} marginTop={'12px'}>
              <Text variant={'urbanistSemiBold'} color={'text.mediumBlue'}>
                {hasSubServices ? service.subServices?.length : (service.used ?? 0)}
              </Text>
              <Text variant={'urbanistSemiBold'} color={'text.mediumGray'}>
                / {hasSubServices ? PEER_SUPPORT_IMPLEMENTATION_PROGRAM_SUB_SERVICES.length : service.total}
              </Text>
            </HStack>,
            <HStack align={'start'} height={'100%'} marginTop={'12px'}>
              <ContractServicesTableItemPopover
                service={service}
                onEditAmountTotal={() => setTotalAmountEditingService(service)}
                onEditAmountUsed={() => setUsedAmountEditingService(service)}
                onEditSubServices={() => setSubServicesEditingService(service)}
              />
            </HStack>,
          ],
        };
      }),
    [contract.services],
  );

  const dataWithId = useMemo(
    () => contract.services.map((service) => ({ id: service.type, ...service })),
    [contract.services],
  );

  return (
    <VStack width={'100%'}>
      <Text variant={'urbanistMedium18'}>Services</Text>
      <CustomTable<TContractServiceBE[], string>
        dataWithId={dataWithId}
        tableListItems={tableListItems}
        noItemsMessage="No Services for this Contract"
        columns={COLUMNS}
        marginBottom="20px"
        customColumnGridPercentages={[36, 17, 16, 16, 15]}
        navigateOnRowSelect={false}
        isSearching={false}
        isLoading={false}
        isRefetching={false}
      />

      <ContractServiceEditTotalModal
        contractId={contract.id}
        amountEditingService={totalAmountEditingService}
        setAmountEditingService={setTotalAmountEditingService}
        onEditSuccessful={refetchActiveContract}
      />

      <ContractServiceEditUsedModal
        contractId={contract.id}
        amountEditingService={usedAmountEditingService}
        setAmountEditingService={setUsedAmountEditingService}
        onEditSuccessful={refetchActiveContract}
      />

      <ContractServiceEditSubServicesModal
        contract={contract}
        editingService={subServicesEditingService}
        setEditingService={setSubServicesEditingService}
        onEditSuccessful={refetchActiveContract}
      />
    </VStack>
  );
};

export default ContractServicesTable;
