import { TCustomAxiosError } from '@/services/api';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import useToast from '../useToast';
import { deleteContractDocument } from '@/services/api/requests/contracts';

type TUploadContractDocumentDelete = {
  contractId: string;
  documentKey: string;
};

const useDeleteContractDocument = (
  onSuccess?: () => void,
): {
  mutate: UseMutateFunction<unknown, TCustomAxiosError, TUploadContractDocumentDelete, unknown>;
  isPending: boolean;
  isError: boolean;
  error: TCustomAxiosError | null;
} => {
  const toast = useToast();

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: async ({ contractId, documentKey }: TUploadContractDocumentDelete) => {
      const response = await deleteContractDocument(contractId, documentKey);
      return response;
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Contract Document successfully deleted.',
      });

      onSuccess?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast(
        {
          status: 'error',
          title: 'Contract Document deleting error',
          description: error?.errors[0]?.message,
        },
        error,
      );
    },
  });

  return { mutate, isPending, isError, error };
};

export default useDeleteContractDocument;
