import { svgIconPaths } from '@/assets/svg/icons';
import SvgIcon from '@/components/atoms/SvgIcon';
import { TPagination } from '@/services/api';
import { Box, Button, HStack, Text } from '@chakra-ui/react';
import React from 'react';

interface ITablePaginationProps {
  pagination: TPagination;
  isFetchingNextPage?: boolean;
}

const CompactTablePagination = ({ pagination, isFetchingNextPage }: ITablePaginationProps) => {
  const totalPages = pagination.totalPages;

  return (
    <HStack style={{ justifyContent: 'space-between', marginTop: '20px' }}>
      <Text marginLeft={'20px'} fontWeight={'bold'}>
        Total Items: {pagination.totalItems}
      </Text>

      <HStack gap={'10px'} width={'30%'} justifyContent={'flex-end'}>
        <Text fontWeight={'bold'} minWidth={'100px'}>
          Page: {pagination.currentPage} / {totalPages}
        </Text>

        <Button
          isDisabled={pagination.currentPage === 1}
          onClick={() => pagination.getFirstPage()}
          _hover={{
            backgroundColor: pagination.currentPage === 1 ? '' : 'primary.gray',
          }}
        >
          {'first'}
        </Button>
        <Button
          isDisabled={pagination.currentPage === 1}
          onClick={() => pagination.getPreviousPage()}
          _hover={{
            backgroundColor: pagination.currentPage === 1 ? '' : 'primary.gray',
          }}
        >
          {/* right arrow, flipped horizontally */}
          <Box transform={'scale(-1,1)'}>
            <SvgIcon iconPath={svgIconPaths.rightArrow} color={'primary.black'} size="14" />
          </Box>
        </Button>
        <Button
          isDisabled={pagination.currentPage === totalPages || isFetchingNextPage}
          disabled={pagination.currentPage === totalPages || isFetchingNextPage}
          onClick={() => pagination.getNextPage()}
          _hover={{
            backgroundColor: pagination.currentPage === totalPages ? '' : 'primary.gray',
          }}
          isLoading={isFetchingNextPage}
        >
          <SvgIcon iconPath={svgIconPaths.rightArrow} color={'primary.black'} size="14" />
        </Button>
        <Button
          isDisabled={pagination.currentPage === totalPages}
          onClick={() => pagination.getLastPage()}
          _hover={{
            color: pagination.currentPage === totalPages ? '' : 'primary.gray',
          }}
        >
          {'last'}
        </Button>
      </HStack>
    </HStack>
  );
};

export default CompactTablePagination;
