import ConfirmCancelButtons from '@/components/molecules/ConfirmationModal/ConfirmCancelButtons';
import { HStack, Input, Text, VStack } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { capitalize } from '@/utils/stringUtils';

interface Props {
  serviceType: string;
  costBasisUnit: string;
  total: number;
  used: number;
  onClose: () => void;
  onSubmit: ({ newAmount }: { newAmount: number }) => void;
  isSubmitPending: boolean;
  isSubmitError: boolean;
  amountEditLabel: 'total' | 'used';
}
const integerValidation = (value: number) => {
  if (!Number.isInteger(value) || String(value).includes('.')) {
    return false;
  }
  return true;
};

const yupSchema = yup.object().shape({
  newAmount: yup
    .number()
    .transform((val, orig) => (orig == '' ? undefined : val))
    .transform((val, orig) => (isNaN(orig) ? undefined : val))
    .required('Amount is required.')
    .test('integer', 'Please enter a whole number.', (value) => integerValidation(value)),
});

const ContractServiceEditModalBody = ({
  serviceType,
  costBasisUnit,
  total,
  used,
  onClose,
  onSubmit,
  isSubmitPending,
  isSubmitError,
  amountEditLabel,
}: Props) => {
  const isEditingUsed = amountEditLabel === 'used';

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(yupSchema),
  });

  const { handleSubmit, register, formState, watch } = methods;

  const newAmountValue = watch('newAmount');

  // New Total Amount guards
  const newTotalAmountSmallerThanUsed = useMemo(() => {
    if (isEditingUsed) return false;

    return total + newAmountValue < used;
  }, [isEditingUsed, total, used, newAmountValue]);

  // New Used Amount guards
  const newUsedAmountDoesNotExceedTotal = useMemo(() => {
    if (!isEditingUsed) return true;

    return newAmountValue <= total - used;
  }, [isEditingUsed, total, used, newAmountValue]);

  const newUsedAmountSmallerThanZero = useMemo(() => {
    if (!isEditingUsed) return false;

    return used + newAmountValue < 0;
  }, [isEditingUsed, total, used, newAmountValue]);

  const { isValid, errors, touchedFields } = formState;

  const newAmountCalculation = useMemo(() => {
    if (isNaN(newAmountValue)) return isEditingUsed ? used : total;

    return (isEditingUsed ? used : total) + newAmountValue;
  }, [newAmountValue, total, used]);

  const isFormInvalid =
    !isValid || !newUsedAmountDoesNotExceedTotal || newUsedAmountSmallerThanZero || newTotalAmountSmallerThanUsed;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <VStack gap={'40px'}>
          <Text variant={'urbanistMediumSemiBoldTitle'} color={'primary.500'}>
            {serviceType}
          </Text>
          <HStack gap={'40px'} width={'100%'} justifyContent={'center'}>
            <VStack align={'start'} gap={'26px'}>
              <Text variant={'urbanistSmallBold'} color={'text.mediumGray'}>
                COST UNIT BASIS
              </Text>
              <Text variant={'urbanistMedium16'}>{costBasisUnit}</Text>
            </VStack>
            <VStack align={'start'} gap={'26px'}>
              <Text variant={'urbanistSmallBold'} color={'text.mediumGray'}>
                CURRENT TOTAL
              </Text>
              <Text variant={'urbanistMedium16'}>{total}</Text>
            </VStack>

            <VStack align={'start'} gap={'26px'}>
              <Text variant={'urbanistSmallBold'} color={'text.mediumGray'}>
                USED
              </Text>
              <HStack>
                <Text variant={'urbanistSemiBold'} color={'text.mediumBlue'}>
                  {used}
                </Text>
                <Text variant={'urbanistSemiBold'} color={'text.mediumGray'}>
                  / {total}
                </Text>
              </HStack>
            </VStack>
          </HStack>

          {/* Input */}
          <VStack gap={'14px'} position={'relative'}>
            <Text variant={'urbanistMedium16'}>Update {capitalize(amountEditLabel)} Amount</Text>
            <VStack position={'relative'}>
              <VStack position={'absolute'} top={'-5px'} right={'-140px'} padding={'20px'}>
                <Text fontWeight={700} fontSize={'16px'} width={'100px'}>
                  = {newAmountCalculation.toFixed()}
                </Text>
              </VStack>
              <Input
                type="number"
                step={1}
                {...register('newAmount', { valueAsNumber: true })}
                className="custom-input"
                placeholder={isEditingUsed ? `Max ${total} (Total)` : 'Enter Amount'}
                width={'165px'}
                data-state={touchedFields.newAmount ? (isFormInvalid ? 'invalid' : 'valid') : ''}
              />
            </VStack>
            {touchedFields.newAmount && errors.newAmount && <Text variant={'error'}>{errors.newAmount.message}</Text>}

            {/* Errors Total */}
            {!errors.newAmount && newTotalAmountSmallerThanUsed && (
              <Text variant={'error'}>New Total Amount can not be less than the Used Amount.</Text>
            )}

            {/* Errors Used */}
            {!errors.newAmount && !newUsedAmountDoesNotExceedTotal && (
              <Text variant={'error'}>New Used Amount exceeds Total Amount.</Text>
            )}
            {!errors.newAmount && newUsedAmountSmallerThanZero && (
              <Text variant={'error'}>New Used Amount can not be less than 0.</Text>
            )}

            <Text variant={'urbanistMediumRegular'} color={'text.mediumGray'}>
              Enter a negative number to decrease the {capitalize(amountEditLabel)} Amount.
            </Text>
          </VStack>

          {/* Buttons */}
          <ConfirmCancelButtons
            confirmButtonText="Save Changes"
            onCancel={onClose}
            confirmButtonType="submit"
            confirmButtonColor="secondary.500"
            confirmButtonDisabled={isFormInvalid}
            marginBottom="0"
            confirmLoading={isSubmitPending}
          />
          {isSubmitError && <Text variant={'error'}>Something went wrong.</Text>}
        </VStack>
      </form>
    </FormProvider>
  );
};

export default ContractServiceEditModalBody;
