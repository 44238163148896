import queryKeys from '@/constants/queryKeys';
import appRoutes from '@/constants/routes/app';
import { patchChangeTherapistStatus } from '@/services/api/requests/therapists';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import useToast from '../useToast';

const useChangeTherapistActivity = (id: string) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { isPending, isSuccess, mutate } = useMutation({
    mutationFn: async ({ isActive }: { isActive: boolean }) => {
      const response = await patchChangeTherapistStatus({ isActive, id });
      return response;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [queryKeys.therapists.all, queryKeys.therapists.byId, id] });
      toast({ status: 'success', title: 'Success', description: 'Therapist status successfully changed.' });
      navigate({ to: appRoutes.therapists });
    },
    onError: (error) => {
      toast({ status: 'error', title: 'Error on changing therapists status', description: error?.message });
    },
  });

  return { isPending, isSuccess, mutate };
};

export default useChangeTherapistActivity;
