import { useActivatedUsers, useUserDeactivation } from '@/hooks/user/users';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import UsersTable from '../UsersTable';
import ConfirmationModal from '@/components/molecules/ConfirmationModal';
import { TUserActivationToggle, TUserFilterOption } from '@/types/User.types';
import { MIN_CHARS_TO_DO_SEARCH } from '@/constants/numberVars';
import RosterActionsPopover from './RosterActionsPopover';
import { useReinviteUser } from '@/hooks/organization/organization';
import queryKeys from '@/constants/queryKeys';
import { isUndefined } from 'lodash';

interface Props {
  filterOption?: TUserFilterOption;
  filterText: string;
  setIsLoadingSearch: Dispatch<SetStateAction<boolean>>;
}

const ActivatedUsersTable = ({ filterOption, filterText, setIsLoadingSearch }: Props) => {
  const { isLoading, data, pagination, refetch, isRefetching } = useActivatedUsers({ filterOption, filterText });

  const [userBeingInteractedWith, setUserBeingInteractedWith] = useState<TUserActivationToggle>();

  const [isReinviteModalOpen, setIsReinviteModalOpen] = useState(false);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);

  const { mutate: deactivate, isPending: isDeactivating } = useUserDeactivation();
  const { mutate: reinvite, isPending: isReinviting } = useReinviteUser();

  const isSearching = useMemo(
    () => filterOption !== undefined && filterText.length >= MIN_CHARS_TO_DO_SEARCH && isRefetching,
    [filterOption, filterText, isRefetching],
  );

  useEffect(() => {
    setIsLoadingSearch(isSearching);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching]);

  const onActionClick = (user: TUserActivationToggle, isDeactivating: boolean) => {
    setUserBeingInteractedWith(user);
    if (isDeactivating) {
      setIsDeactivateModalOpen(true);
      return;
    }
    setIsReinviteModalOpen(true);
  };

  const onConfirmDeactivate = () => {
    if (userBeingInteractedWith) {
      deactivate(
        { userId: userBeingInteractedWith.id, hasLastLogin: userBeingInteractedWith.last_login !== '' },
        {
          onSuccess: () => {
            setIsDeactivateModalOpen(false);
            refetch();
            setUserBeingInteractedWith(undefined);
          },
        },
      );
    }
  };

  const onConfirmReinvite = () => {
    if (userBeingInteractedWith && userBeingInteractedWith.organizationId) {
      reinvite(
        {
          userId: userBeingInteractedWith.id,
          organizationId: userBeingInteractedWith.organizationId,
          queryToInvalidateOnSuccess: queryKeys.users.activatedUsers,
        },
        {
          onSuccess: () => {
            setIsReinviteModalOpen(false);
            setUserBeingInteractedWith(undefined);
          },
        },
      );
    }
  };

  return (
    <>
      <UsersTable
        users={data}
        pagination={pagination}
        isLoading={isLoading}
        isRefetching={isRefetching}
        invalidatedUserId={userBeingInteractedWith?.id}
        isSearching={isSearching}
        ActionElement={(userToDeactivate: TUserActivationToggle) => (
          <RosterActionsPopover
            user={userToDeactivate}
            isReinviteHidden={!isUndefined(userToDeactivate?.user_metadata?.sex)}
            onDeactivate={() => onActionClick(userToDeactivate, true)}
            onReinvite={() => onActionClick(userToDeactivate, false)}
          />
        )}
      />
      <ConfirmationModal
        isOpen={isDeactivateModalOpen}
        onCancel={() => {
          setIsDeactivateModalOpen(false);
          setUserBeingInteractedWith(undefined);
        }}
        title="Confirm Deactivation"
        confirmButtonText="Deactivate"
        description={`This will deactivate user ${userBeingInteractedWith?.firstName} ${userBeingInteractedWith?.lastName}`}
        onConfirm={onConfirmDeactivate}
        confirmButtonColor="extra.red"
        confirmLoading={isDeactivating}
      />
      <ConfirmationModal
        isOpen={isReinviteModalOpen}
        onCancel={() => {
          setIsReinviteModalOpen(false);
          setUserBeingInteractedWith(undefined);
        }}
        title="Confirm Reinvite"
        confirmButtonText="Reinvite"
        description={`This will send reinvite email to the user ${userBeingInteractedWith?.firstName} ${userBeingInteractedWith?.lastName}`}
        onConfirm={onConfirmReinvite}
        confirmButtonColor="primary.500"
        confirmLoading={isReinviting}
      />
    </>
  );
};

export default ActivatedUsersTable;
