import React, { CSSProperties, PropsWithChildren } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalFooter, HStack, Flex, Text, VStack } from '@chakra-ui/react';
import { svgIconPaths } from '@/assets/svg/icons';
import SvgIcon from '@/components/atoms/SvgIcon';

export type TModalContainerProps = {
  isOpen: boolean;
  title?: string;
  footerContent?: React.ReactNode;
  onClose?: () => void;
  isCentered?: boolean;
  width?: string;
  height?: string;
  contentStyle?: CSSProperties;
  titleFontSize?: string;
  backgroundColor?: string;
  modalBodyPadding?: string | number;
  titleBottomMargin?: string;
};

const ModalContainer = ({
  isOpen = false,
  children,
  title,
  footerContent,
  onClose = () => {},
  isCentered = true,
  height,
  contentStyle,
  backgroundColor = '#F8FCFF',
  width = '614px',
  modalBodyPadding = '8px 24px',
  titleBottomMargin = '20px',
}: PropsWithChildren<TModalContainerProps>) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={isCentered}>
      <ModalOverlay>
        <ModalContent
          backgroundColor={backgroundColor}
          minWidth={width}
          minHeight={height}
          maxHeight={'90vh'}
          overflowY={'scroll'}
          paddingX={'35px'}
          paddingY={'40px'}
          style={contentStyle}
        >
          <HStack flex={1} marginBottom={titleBottomMargin} alignItems={'center'}>
            <Flex flex={1} />
            <Flex flex={3} justifyContent={'center'} alignItems={'center'}>
              <Text variant={'loraSmallTitle'} fontWeight={500}>
                {title}
              </Text>
            </Flex>
            <Flex flex={1} cursor={'pointer'} onClick={onClose} justifyContent={'flex-end'} alignItems={'center'}>
              <VStack
                _hover={{ backgroundColor: 'background.cloudGray' }}
                width={'24px'}
                height={'24px'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <SvgIcon iconPath={svgIconPaths.close} color={'primary.black'} size={'18'} />
              </VStack>
            </Flex>
          </HStack>
          <VStack padding={modalBodyPadding} spacing={4}>
            {children}
          </VStack>
          {footerContent && <ModalFooter>{footerContent}</ModalFooter>}
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default ModalContainer;
